
/** * Looks up, sniffs and returns information around centra markets. getAllMarkets
 * is dispatched in nuxtServerInit to make this information readily available
 * to the client when it selects which market to display.
 *
 * A market is an aggregated representation of catalog and inventory. Language
 * and currency is secondary to this.
 */
export default {
  namespaced: true,
  state () {
    return {
      markets: [] // Should contain all markets
    }
  },
  mutations: {
    markets (state, markets) {
      state.markets = markets
    }
  },
}
