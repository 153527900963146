<template>
  <div class="country-selector">
    <client-only>
      <div class="selected-country" @click="showCountrySelector">
        <div class="country">
          <img
            :src="'/flags/market/' + countryCodeLower + '.svg'"
            :alt="countryName"
            loading="lazy"
          >
        </div>
        <div class="name">
          {{ countryName }}
        </div>
        <ChevronIcon class="chevron" />
      </div>
    </client-only>
  </div>
</template>

<script>
import ISOCountries from '~/lib/iso-countries'
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'CountrySelector',
  components: {
    ChevronIcon
  },
  props: {
    countryCode: {
      type: String,
      required: true
    }
  },
  computed: {
    countryCodeLower () {
      return this.countryCode.toLowerCase()
    },
    countryName () {
      return ISOCountries[this.countryCode?.toUpperCase()]
    }
  },
  methods: {
    showCountrySelector () {
      this.$store.dispatch('ui/showCountrySelector')
    }
  }
}
</script>

<style lang="scss" scoped>
.country-selector {
  .selected-country {
    display: flex;
    align-items: center;
    cursor: pointer;
    .country {
      margin-right: .8rem;
      img {
        width: 1.6rem;
        height: auto;
        display: block;
      }
    }
    .chevron {
      margin-left: .8rem;
      width: .8rem;
      height: .8rem;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      fill: var(--foreground-color);
    }
  }
}
</style>
