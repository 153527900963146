const MAX_AGE_IN_MILLISECONDS = 10 * 60 * 1000

const productRegister = {}

export const state = () => {
  return {
    allProducts: {}
  }
}

export const mutations = {
  addProducts (state, payload) {
    state.allProducts = {
      ...state.allProducts,
      ...payload,
    }
  }
}

function isExpired (key) {
  if (!productRegister[key]) {
    return true
  }

  if (productRegister.age < Date.now() - MAX_AGE_IN_MILLISECONDS) {
    return true
  }

  return false
}

export const actions = {
  async load ({ commit, getters }, productSkus) {
    const marketId = getters['frontend/currentMarketId']
    const languageId = getters['frontend/currentLanguageCode']
    const priceListId = getters['frontend/currentPricelistId']

    const getProductKey = product => `${product.productSku}${marketId}${languageId}${priceListId}`

    const toLoad = productSkus.filter(product => isExpired(getProductKey(product)))
    const products = await this.$backendApi.get(`/products/by-skus/${toLoad.join(',')}`)
    if (!products.data.length) {
      return
    }

    const payload = {}
    for (const product of products.data) {
      if (!product) {
        continue
      }
      try {
        productRegister[getProductKey(product)] = { loaded: true, age: Date.now() }
        payload[product.productSku] = this.$transformCentraProduct(product)
      } catch (e) {
        console.error(e, 'store', 'centra-product-repository', { product })
        delete productRegister[getProductKey(product)]
      }
    }

    commit('addProducts', payload)
  }
}

export const getters = {
  getProductsBySkus: state => (...skus) => {
    const result = []
    for (const sku of skus) {
      const item = state.allProducts[sku.trim()]
      if (item) {
        result.push(item)
      }
    }
    return result
  }
}
