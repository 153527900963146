import { each } from 'underscore'
import klaviyoService from '../../services/made-people-backend/klaviyo'

export default ({ $backendApi, store }, inject) => {
  const context = {
    getCurrentCountryCode: () => store.getters['frontend/currentCountryCode'],
    getCurrentLanguageCode: () => store.getters['frontend/currentLanguageCode'],
  }

  const methods = {
    ...klaviyoService($backendApi, context)
  }
  const injector = method => inject(method, methods[method])
  each(Object.keys(methods), injector)
}
