/* eslint-disable vue/component-definition-name-casing */
import Vue from 'vue'

// We lazyload these possibly huge components because they
// represent different types of pages, ie they will never
// display at the same time anyway so it doesn't make sense
// to get them on initial download

Vue.component('storyblok', () => import('~/views/Storyblok.vue'))
Vue.component('product', () => import('~/views/Product.vue'))
Vue.component('search', () => import('~/views/Search.vue'))
