<template>
  <span v-if="to === null || $route.query._storyblok">
    <slot />
  </span>
  <a
    v-else-if="/^(http[s]?|mailto):/.test(to)"
    :href="to"
    data-type="a"
  >
    <slot />
  </a>
  <nuxt-link v-else v-bind="$attrs">
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  name: 'MpLink',
  computed: {
    to () {
      return this.$attrs.to
    }
  }
}
</script>
