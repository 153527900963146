import serverLogger from '~/server/server-logger'

export const campaignStorageKey = 'campaign'

export default {
  namespaced: true,
  state () {
    return {
      uri: '',
      market: '',
    }
  },
  mutations: {
    setActiveCampaign (state, campaign) {
      state.uri = campaign.uri
      state.market = campaign.market
    },
    removeActiveCampaign (state) {
      state.activeCampaign = ''
      state.market = undefined
    }
  },
  actions: {
    initialize ({ commit }) {
      if (this.app.$cookies.get(campaignStorageKey)) {
        commit('setActiveCampaign', this.app.$cookies.get(campaignStorageKey))
      }
    },
    setActiveCampaign ({ commit }, campaign) {
      commit('setActiveCampaign', campaign)
    },
    async initializeCampaignByUri ({ dispatch, state }, campaignUri) {
      let campaignData
      try {
        campaignData = await this.$backendApi.get(`/campaign-site/${campaignUri}`)
      } catch (e) {
        serverLogger.debug('Error when trying to fetch campaign ', campaignUri)
        serverLogger.debug(e.response?.data ?? e)
        return await dispatch('removeActiveCampaign')
      }
      if (!campaignData.data?.campaignSite) {
        return await dispatch('removeActiveCampaign')
      }

      await dispatch('setActiveCampaign', {
        uri: campaignData.data?.campaignSite,
        market: campaignData.data?.market,
      })
    },
    removeActiveCampaign ({ commit }, campaign) {
      commit('removeActiveCampaign', campaign)
    }
  },
  getters: {
    activeCampaign: state => state.uri,
    campaignMarket: state => state.market,
  }
}
