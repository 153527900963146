
<template>
  <div
    class="video-block"
    :class="[
      mobileVideo ? 'm--video' : '',
      desktopVideo ? 'd--video' : '',
      videoIsPlaying ? 'playing' : ''
    ]"
  >
    <template v-if="mobileVideo || desktopVideo">
      <div v-if="mobileVideo" class="mobile-video">
        <video
          v-if="showMobile || !desktopVideo"
          ref="mobileVideo"
          :onplaying="videoControls ? 'this.controls=true' : ''"
          preload
          playsinline
          :autoplay="videoAutoplay"
          :muted="videoAutoplay"
          :loop="videoLoop"
          :poster="mobilePosterOut"
        >
          <source :src="mobileVideo">
        </video>
        <div
          v-if="!videoAutoplay"
          ref="playBtn"
          class="play-btn play--mobile"
          @click="startVideo($refs.mobileVideo, mobileVideo)"
        >
          <div class="icon">
            <PlayIcon />
          </div>
        </div>
      </div>
      <div v-if="desktopVideo" class="desktop-video">
        <video
          v-if="showDesktop"
          ref="desktopVideo"
          class="desktop-video"
          :onplaying="videoControls ? 'this.controls=true' : ''"
          preload
          playsinline
          :autoplay="videoAutoplay"
          :muted="videoAutoplay"
          :loop="videoLoop"
          :poster="desktopPosterOut"
        >
          <source :src="desktopVideo">
        </video>
        <div
          v-if="!videoAutoplay"
          ref="playBtn"
          class="play-btn play--desktop"
          @click="startVideo($refs.desktopVideo, desktopVideo)"
        >
          <div class="icon">
            <PlayIcon />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no-video">
        <div class="ratio">
          <p>Add a video source or poster image and hit save.</p>
        </div>
      </div>
    </template>
    <mp-link
      v-if="link.id || link.url"
      :to="$u(link)"
      class="link"
    />
  </div>
</template>

<script>
import PlayIcon from '~/assets/icons/play.svg'
export default {
  name: 'VideoBlock',
  components: {
    PlayIcon
  },
  props: {
    link: {
      type: Object,
      required: false,
      default: () => ({})
    },
    videoAutoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    videoLoop: {
      type: Boolean,
      required: false,
      default: false
    },
    videoControls: {
      type: Boolean,
      required: false,
      default: false
    },
    mobilePoster: {
      type: Object,
      required: false,
      default: () => ({})
    },
    desktopPoster: {
      type: Object,
      required: false,
      default: () => ({})
    },
    mobileVideo: {
      type: String,
      required: false,
      default: ''
    },
    desktopVideo: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      videoIsPlaying: false,
      showMobile: false,
      showDesktop: false
    }
  },
  computed: {
    mobilePosterOut () {
      if (this.mobilePoster.filename) {
        if (this.videoAutoplay) {
          // autoplay video compresses poster harder
          this.$imgproxy.transform(this.mobilePoster.filename, 'preset:sharp/rs:fit:500/g:nowe/q:40')
        } else {
          this.$imgproxy.transform(this.mobilePoster.filename, 'preset:sharp/rs:fit:500/g:nowe/q:70')
        }
      }
      return null
    },
    desktopPosterOut () {
      if (this.desktopPoster.filename) {
        if (this.videoAutoplay) {
          this.$imgproxy.transform(this.desktopPoster.filename, 'preset:sharp/rs:fit:500/g:nowe/q:40')
        } else {
          this.$imgproxy.transform(this.desktopPoster.filename, 'preset:sharp/rs:fit:500/g:nowe/q:70')
        }
      }
      return null
    }
  },
  watch: {
    /**
     * Watch the props in order to restart the
     * video for Storyblok preview
     */
    videoAutoplay: {
      handler () {
        if (this.mobileVideo) {
          if (this.videoAutoplay) {
            this.startVideo(this.$refs.mobileVideo, this.mobileVideo)
          } else {
            this.$refs.mobileVideo.pause()
            this.$refs.mobileVideo.load()
            this.videoIsPlaying = false
          }
        }
        if (this.desktopVideo) {
          if (this.videoAutoplay) {
            this.startVideo(this.$refs.desktopVideo, this.desktopVideo)
          } else {
            this.$refs.desktopVideo.pause()
            this.$refs.desktopVideo.load()
            this.videoIsPlaying = false
          }
        }
      }
    }
  },
  mounted () {
    this.determineSize()
    window.addEventListener('resize', this.determineSize)
    if (this.mobileVideo && this.videoAutoplay) {
      this.startVideo(this.$refs.mobileVideo, this.mobileVideo)
    }
    if (this.desktopVideo && this.videoAutoplay) {
      this.startVideo(this.$refs.desktopVideo, this.desktopVideo)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.determineSize)
  },
  methods: {
    determineSize () {
      if (window.innerWidth < 1024) {
        this.showDesktop = false
        this.showMobile = true
      } else {
        this.showDesktop = true
        this.showMobile = false
      }
    },
    startVideo (ref, src) {
      const video = ref
      const m3u8 = /\.(m3u8)\?/i
      if (video) {
        // Check if source is HLS
        if (window.Hls && m3u8.test(src) && window.Hls.isSupported()) {
          const hls = new window.Hls()
          hls.loadSource(src)
          hls.attachMedia(video)
          hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
            this.playVideo(video)
          })
          // When the browser has built-in HLS support (check using `canPlayType`),
          // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
        } else if (m3u8.test(src) && video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = src
          video.addEventListener('loadedmetadata', () => {
            this.playVideo(video)
          })
        } else {
          // If the source is something other than .m3u8, just play the source that was supplied
          this.playVideo(video)
        }
        this.videoIsPlaying = true
      }
    },
    playVideo (video) {
      const isPlaying = video.currentTime > 0 && !video.paused && !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA

      if (!isPlaying) {
        video.play()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-block {
  position: relative;
  video {
    width: 100%;
    display: block;
    object-fit: cover;
  }
  .play-btn {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(black,0.25);
    transition: background-color .2s ease-out;
    cursor: pointer;
    .icon {
      width: 10%;
      max-width: 100px;
      svg {
        display: block;
        width: 100%;
        height: 100%;
        path {
          fill: white;
          transition: fill .2s ease-out;
        }
      }
    }
    @media (hover: hover) {
      &:hover {
        background: rgba(black,0.5);
        .icon svg path {
          fill: $mint-100;
        }
      }
    }
  }
  .link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  /**
   * If both mobile and desktop video exists,
   * only show the one relevant for current breakpoint
   */
  &.d--video.m--video {
    .mobile-video {
      display: block;
    }
    .desktop-video {
      display: none;
    }
  }
  &.playing {
    .play-btn {
      display: none !important;
    }
  }
  .no-video {
    @include aspect-ratio(16,9);
    .ratio {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        @include p--medium;
        opacity: 0.5;
      }
    }
  }
}
@media screen and (min-width: $tablet-landscape) {
  .video-block {
    /**
     * If both mobile and desktop video exists,
     * only show the one relevant for current breakpoint
     */
    &.d--video.m--video {
      .mobile-video {
        display: none;
      }
      .desktop-video {
        display: block;
      }
    }
  }
}
</style>
