/**
 * Because we have a differently structured routes from different external
 * systems and priorities between them together with a wildcard route, i found
 * it a good idea to create this store whose purpose is to have a mapping of
 * all externally defined routes and the main component to render with them.
 *
 * This store can then be mapped in pages/_.vue to conditionally render plain
 * content, products and categories.
 *
 * Special pages such as checkout are best placed in separate pages/checkout.vue
 * and similar, which have precedence over pages/_.vue.
 */
export default {
  state () {
    return {
      /**
       * An array of { route: 'something/foo/bar', component: 'storyblok' } that
       * can be used as a local lookup cache when finding what should be rendered
       *
       * The component key simply references a registered vue component
       */
      routes: [
        {
          route: '/route/search',
          component: {
            component: 'search',
            id: null,
            url: 'search',
            data: null,
          },
        },
      ],
      currentRoute: null,
      previousRoute: null,
    }
  },

  mutations: {
    addRoute (state, payload) {
      state.routes = [payload].concat(
        state.routes.filter(route => route.route !== payload.route)
      )
      state.currentRoute = payload
    },

    addPreviousRoute (state, payload) {
      state.previousRoute = payload
    },
  },
  actions: {
    /**
     * Call the frackend to get a component name which can be renderered depending
     * on an external route table (or simply 404)
     */
    async lookupRoute (
      { dispatch, commit, rootGetters, state, rootState },
      routePath
    ) {
      if (rootState.route.query._storyblok) {
        const story = await dispatch(
          'storyblok/fetchStory',
          {
            id: rootState.route.query._storyblok,
            language: rootState.route.query._storyblok_lang,
          },
          { root: true }
        )
        const data = {
          route: routePath,
          component: {
            component: 'storyblok',
            id: parseInt(rootState.route.query._storyblok),
            url: routePath,
          },
        }
        commit('addRoute', data)
        return Promise.resolve({
          ...data.component,
          data: story,
        })
      }

      routePath = routePath.replace(/\/[\w-]+\/?/, '/')
      const route = '/route' + routePath

      // If we have the route in state already we don't need a new lookup
      const found = state.routes.find(x => x.route === route)
      if (found) {
        commit('addRoute', found)

        let data
        if (found.component.component === 'storyblok') {
          data = rootGetters['storyblok/getStoryById'](found.component.id)
        } else if (found.component.component === 'product') {
          data = rootGetters['centra-product/getProductById'](
            found.component.id
          )
        }
        return Promise.resolve({
          ...found.component,
          data,
        })
      }

      return this.$backendApi
        .get(route, {
          params: {
            language: rootState.frontend.currentLanguageCode,
            country: rootState.frontend.currentCountryCode,
            pricelist: rootGetters['frontend/currentPricelistId'],
            market: rootGetters['frontend/currentMarketId']
          }
        })
        .then((res) => {
          commit('addRoute', { route, component: { ...res.data, data: undefined } })
          if (res.data.component === 'storyblok') {
            commit('storyblok/story', {
              id: parseInt(res.data.id),
              data: res.data.data
            }, { root: true })
          } else if (res.data.component === 'product') {
            commit('centra-product/products', [res.data.data], { root: true })
          }
          return res.data
        })
        .catch((err) => {
          // If 404 it means we should not render anything here but are fine
          // to resolve an undefined result up
          if (err.response && err.response.status === 404) {
            return undefined
          }

          console.error(err, 'store', 'routes', { routePath, method: 'lookupRoute' })
        })
    },

    /**
     * Stores the previous route here for easy access.
     * Used to optimize the navigation functionality in the layout for the Account Pages.
     * We don't want to track the redirect to the current site (e.g. from '/' to '/se'),
     * hence the '/' check below.
     */
    trackPreviousRoute ({ commit }, from) {
      if (from !== '/') {
        commit('addPreviousRoute', from)
      }
    },

    getComponentByUrl: state => url =>
      state.routes.find(route => route.route === url),
  },
}
