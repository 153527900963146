export const state = () => {
  return {
    isCartChangesDisabled: false,
    isNewsletterSubscription: false,
    isTermsAgreed: false,
  }
}

export const mutations = {
  disableCartChanges (state) {
    state.isCartChangesDisabled = true
  },

  enableCartChanges (state) {
    state.isCartChangesDisabled = false
  },

  wantToSubscribe (state) {
    state.isNewsletterSubscription = true
  },

  unsubscribe (state) {
    state.isNewsletterSubscription = false
  },

  agreeToTerms (state) {
    state.isTermsAgreed = true
  },

  disagreeToTerms (state) {
    state.isTermsAgreed = false
  }
}

export const getters = {
  isNewsletterSubscription: state => state.isNewsletterSubscription,
  isTermsAgreed: state => state.isTermsAgreed,
}
