import Vue from 'vue'
import gtm from '@/mixins/stronger-gtm'

if (!Vue._strongerGlobalMixinAdded) {
  Vue._strongerGlobalMixinAdded = true
  Vue.mixin(gtm)
  Vue.mixin({
    mounted () {
    },
    methods: {
      $activateGoogleOptimize () {
        // Activate google optimize, needs to run in every page
        //
        // @see https://stackoverflow.com/questions/55695816/run-function-after-route-fully-rendered-in-nuxt-js
        // @see https://support.google.com/optimize/answer/7008840?hl=en
        this.$nextTick(function () {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: 'optimize.activate' })
        })
      },
      /**
       * Hot replacement for vue-i18n method which happens to use translated elements
       * from storyblok
       */
      $t (name, format, rootGetters) {
        const getters = rootGetters || this.$nuxt.$store.getters
        format = format || {}
        const re = /{([^{]+)}/g
        const translation = getters['storyblok/getElementTranslation'](name)
        return translation.replace(re, (ignore, key) => {
          const term = format[key]
          return term === undefined ? `{${key}}` : term
        })
      },

      /**
       * If we always use this method we can actually
       * switch markets in SPA mode
       *
       * @param {string|Object} link
       */
      $u (link) {
        let slug
        if (typeof link === 'object') {
          // We guess it's an SB link and check if it's internal, external or whatever
          let linkObject
          switch (link.linktype) {
            case 'url':
              slug = link.url
              break
            case 'story':
              linkObject = this.$store.getters['storyblok/getLinkForUuid'](link.id)
              if (linkObject) {
                slug = linkObject.slug
              } else {
                // It's simply not set up in SB, so we cannot link it
                return null
              }
              break
            // Default means nothing so let's not do anythings
          }
        } else {
          slug = link
        }

        if (slug.match(/^https?:\/\//)) {
          return slug
        }

        slug = slug.replace(/^site\/?/, '')
        const siteLangSlug = this.$store.getters['frontend/currentSiteLangSlug']
        return `/${siteLangSlug}/${slug}`
      },

      $openAda () {
        window.adaEmbed.toggle()
      },
      transformProduct (product) {
        return product ? {
          ...product,
          sizeOptions: product.sizeOptions.map((option) => {
            return {
              ...option,
              addToCart: () => {
                // Is this a cool pattern?
                return this.$nuxt.$store.dispatch('centra-cart/addItem', { item: option.value, productUrl: product.url }).then((res) => {
                  const item = res.items.find(item => item.line === res.line)
                  if (this.gtm_trackAddToCart) {
                    this.gtm_trackAddToCart(item, 1)
                  }
                  return item
                }).catch((error) => {
                  console.error(error, 'globalPlugin', 'transformProduct', { option, product })
                  this.$notify({
                    title: this.$t('Dialog_Add_To_Cart_Error_Title'),
                    text: this.$t('Dialog_Add_To_Cart_Error_Message'),
                    type: 'error'
                  })
                  return false
                })
              }
            }
          })
        } : null
      },

      $debounce (func, wait) {
        let timeout
        return function executedFunction (...args) {
          const later = () => {
            timeout = null
            func(...args)
          }
          clearTimeout(timeout)
          timeout = setTimeout(later, wait)
        }
      },

      $evaluateScriptsInRef (ref) {
        ;[].slice.call(ref.getElementsByTagName('script'))
          .filter(script => script.type === '' && script.src === '')
          // eslint-disable-next-line no-eval
          .forEach(script => eval(script.innerHTML))
      }

    }
  })
}
