import axios from 'axios'

function HitCounter (config) {
  this.sourceId = config.sourceId
  this.mock = !config.baseURL
  this.client = axios.create(config)
}

HitCounter.prototype = {
  hit (productSku) {
    if (this.mock) {
      return Promise.resolve()
    }
    return this.client.post('/hit', {
      hitIdentifier: productSku,
      source: `${this.sourceId}`
    })
  },

  count (productSku) {
    if (this.mock) {
      return Promise.resolve()
    }

    return this.client.get(`/hit/${productSku}/count`)
  }
}

export { HitCounter }
