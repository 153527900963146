<template>
  <div class="cart-item" :class="{ 'loading': loading || isCartChangesDisabled }">
    <mp-link
      class="image"
      :to="$u(selectedItem._product.url)"
      @click.native="$emit('clickLink', $event)"
    >
      <img
        :src="$imgproxy.transform(selectedItem._product.mainImage, 'preset:sharp/rs:fit:160/g:nowe/q:70')"
        :alt="selectedItem._product.name"
        loading="lazy"
      >
    </mp-link>
    <div class="top-row">
      <mp-link
        class="info"
        :to="$u(selectedItem._product.url)"
        @click.native="$emit('clickLink', $event)"
      >
        <div class="name">
          {{ selectedItem._product.name }}
          <ProductBadge
            v-if="isGift"
            :label="$t('Cart_Gift_Badge')"
            type="gift"
          />
        </div>
        <div class="price">
          <span
            v-if="selectedItem.priceEach !== selectedItem.priceEachBeforeDiscount"
            class="sale"
          >
            {{ selectedItem.priceEach }}
          </span>
          <span
            v-if="selectedItem.priceEach !== selectedItem.priceEachBeforeDiscount"
            class="old-price"
          >
            {{ selectedItem.priceEachBeforeDiscount }}
          </span>
          <span v-else>
            {{ selectedItem.priceEach }}
          </span>
        </div>
      </mp-link>
      <div class="type-quantity-selector">
        {{ selectedItem._product.product_type }}
        <div v-if="!sizeMenuOpen && !colorSelectorOpen && !isGift && !reachedMaxPurchase" class="change-quantity">
          <div class="minus" @click="decreaseQty">
            <MinusIcon />
          </div>
          <div class="quantity">
            <span>{{ selectedItem.quantity }}</span>
          </div>
          <div class="plus" @click="increaseQty">
            <PlusIcon />
          </div>
        </div>
      </div>
      <div class="remove" @click="removeItem">
        <CrossIcon />
      </div>
    </div>
    <div class="bottom-row" v-if="!allowChangeSize && !isBundle">
      <div class="size">
        <span class="size-menu-toggle click-disabled" v-if="selectedItem.size !== ''">
          {{ $t('Global_Size') }}: {{ selectedItem.size }}
        </span>
        <span v-else>
          {{ $t('Product_Size_Onesize') }}
        </span>
      </div>
    </div>
    <div class="bottom-row" v-else-if="!isBundle">
      <div class="size" @click="toggleSizeMenu">
        <button class="size-menu-toggle" v-if="selectedItem.size !== ''">
          {{ $t('Global_Size') }}: {{ selectedItem.size }}
          <ChevronDownIcon class="size-menu-chevron" :class="sizeMenuOpen && 'open'" />
        </button>
        <span v-else>
          {{ $t('Product_Size_Onesize') }}
        </span>
      </div>
      <div v-if="!isBundle && selectedProductColorSwatch && filteredColorSwatches && filteredColorSwatches.length > 1" class="change-color-menu">
        <button class="color-menu-toggle" @click="toggleColorSelector">
          <span> {{ $t('Global_Color') }} </span>
          <div :style="[getActiveSwatchColor()]" class="color-circle" />
          <span v-if="showColorSwatchDescription">{{ selectedProductColorSwatch.description }}</span>
          <ChevronDownIcon class="change-color-menu-chevron" :class="colorSelectorOpen && 'open'" />
        </button>
      </div>
    </div>
    <div v-if="sizeMenuOpen && !isBundle" class="change-size">
      <span class="change-size-title"> {{ $t('Change_Size') }}</span>
      <div class="sizes">
        <button
          v-for="size in selectedItem._product.sizeOptions"
          :key="size.id"
          class="size-option"
          @click="handleSizeChange(size)"
          :class="[
            size.label === selectedSize.label && 'selected',
            size.quantity === 0 && 'out-of-stock',
          ]"
          :disabled="isSizeOptionDisabled(size)"
        >
          {{ size.label }}
        </button>
      </div>
    </div>
    <ColorSwatches
      v-if="filteredColorSwatches && !isBundle && colorSelectorOpen"
      :color-swatches="filteredColorSwatches"
      :show-text="true"
      @handleColorChange="handleColorChange"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import ProductBadge from '../product/product-badge/ProductBadge.vue'
import CrossIcon from '~/assets/icons/cross.svg'
import MinusIcon from '~/assets/icons/minus.svg'
import PlusIcon from '~/assets/icons/plus.svg'
import ChevronDownIcon from '~/assets/icons/chevron-down-small.svg'
import ColorSwatches from '~/components/color-swatches/ColorSwatches.vue'
import Depict from '~/mixins/depict.js'

export default {
  name: 'CartItem',
  components: {
    ProductBadge,
    CrossIcon,
    MinusIcon,
    PlusIcon,
    ChevronDownIcon,
    ColorSwatches
  },
  mixins: [Depict],
  props: {
    item: {
      type: Object,
      required: true
    },
    // Can be true it this item is part of a complete cart rendering
    allowChangeSize: {
      type: Boolean,
      default: true
    },
    showColorSwatchDescription: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      qty: 1,
      loading: false,
      stock: null,
      sizeMenuOpen: false,
      colorSelectorOpen: false,
      // Since we can change what item is selected through the inline item-selector,
      // this has to be changeable - independent of the passed props.
      selectedItem: null,
    }
  },
  computed: {
    ...mapGetters({
      productReachedMaxQtyPerOrder: 'centra-cart/productReachedMaxQtyPerOrder',
      cart: 'centra-cart/cart',
      currentMarket: 'frontend/market',
    }),
    ...mapState({
      isCartChangesDisabled: state => state.checkout.isCartChangesDisabled
    }),
    reachedMaxPurchase () {
      if (this.item._product?.restrictedQtyPerOrderByGroup) {
        return this.productReachedMaxQtyPerOrder(this.item._product.restrictedQtyPerOrderByGroup)
      }
      return false
    },
    isBundle () {
      return this.item?._product?.isBundle ?? false
    },
    isGift () {
      return this.item.totalPriceAsNumber === 0
    },
    selectedSize () {
      return this.item._product.sizeOptions.find(size => this.item.item === size.value)
    },
    filteredColorSwatches () {
      const swatches = this.selectedItem?.colorSwatches
        ?.map((swatch) => {
          return {
            uri: swatch?.uri,
            label: swatch?.label,
            image: swatch?.colorSwatch?.image,
            item: Object.values(swatch?.items)?.find(
              item => item?.name === this.item?.size
            ),
            isActive: false,
          }
        })
        ?.filter(swatch => swatch?.image && this.variantInStock(swatch))

      if (typeof swatches === 'undefined') {
        return undefined
      }

      const currentSwatch = {
        uri: this.selectedItem?._product?.url,
        label: this.selectedItem?._product?.color_swatch?.description,
        image: this.selectedItem?._product?.color_swatch?.image,
        isActive: true,
      }

      const finalSwatches = [currentSwatch, ...swatches]
      finalSwatches.sort((a, b) => a.uri.localeCompare(b.uri))

      return finalSwatches
    },
    selectedProductColorSwatch () {
      return this.selectedItem?._product?.color_swatch
    }

  },
  watch: {
    'item' () {
      this.selectedItem = this.item
    },
  },
  created () {
    this.selectedItem = this.item
  },
  methods: {
    ...mapActions({
      updateItem: 'centra-cart/updateItem',
      addItem: 'centra-cart/addItem',
      changeSize: 'centra-cart/changeSize',
      changeColor: 'centra-cart/changeColor'
    }),
    getActiveSwatchColor () {
      return { 'background-image': `url(${this.selectedProductColorSwatch?.image?.url})` }
    },
    variantInStock (variant) {
      return variant.item?.stockByMarket[`${this.currentMarket.market}`] > 0
    },
    updateQty (quantity) {
      const qtyDiff = quantity - this.item.quantity

      if (this.canAddQuantityToCart(qtyDiff)) {
        this.loading = true

        const payload = {
          item: this.item.item,
          quantity,
        }

        return this.updateItem(payload)
          .then(cart => this.itemQuantityUpdateSuccess(cart, qtyDiff))
          .catch(this.handleUpdateQuantityError)
          .finally(this.itemQuantityUpdateFinally)
      }

      this.$notify({
        title: this.$t('Dialog_Add_To_Cart_Error_Title'),
        text: this.$t('Dialog_Add_To_Cart_No_More_Items_Available'),
        type: 'error'
      })
    },
    canAddQuantityToCart (qtyDiff) {
      return this.selectedSize.quantity > this.item.quantity || (qtyDiff < 0)
    },
    itemQuantityUpdateSuccess (cart, qtyDiff) {
      this.trackQuantityChange(this.item, qtyDiff)
      this.$sysend.broadcast('cartupdate', { page: document.title, method: 'CartItem.vue updateQty' })
      return cart
    },
    trackQuantityChange (item, qtyDiff) {
      if (qtyDiff < 0) {
        return this.gtm_trackRemoveFromCart(item, Math.abs(qtyDiff))
      }

      this.gtm_trackAddToCart(item, qtyDiff, false, 'cart')
      this.depict_trackAddToCart({ sku: item._product.productSku, sizeLabel: item.size })
    },
    itemQuantityUpdateFinally () {
      this.loading = false
      this.sizeMenuOpen = false
    },
    toggleSizeMenu () {
      this.sizeMenuOpen = !this.sizeMenuOpen
      this.colorSelectorOpen = false
    },
    toggleColorSelector () {
      this.colorSelectorOpen = !this.colorSelectorOpen
      this.sizeMenuOpen = false
    },
    async removeItem (event) {
      if (this.isBundle) {
        this.loading = true
        const cart = await this.$removeBundleFromCart(this.item.line)
        await this.$store.dispatch('centra-cart/setCart', cart)
        this.gtm_trackRemoveFromCart(this.item, this.item.quantity)
        this.loading = false
      } else {
        await this.updateQty(0)
      }
      this.$emit('removeItem', event)
    },
    async decreaseQty () {
      if (this.isBundle) {
        this.loading = true
        const cart = await this.$decreaseQuantity(this.item.line, 1)
        await this.$store.dispatch('centra-cart/setCart', cart)
        this.gtm_trackRemoveFromCart(this.item, 1)
        this.loading = false
        return
      }

      return this.updateQty(this.item.quantity - 1)
    },
    async increaseQty () {
      if (this.isBundle) {
        this.loading = true
        const cart = await this.$increaseQuantity(this.item.line, 1)
        await this.$store.dispatch('centra-cart/setCart', cart)
        this.gtm_trackAddToCart(this.item, 1)
        this.loading = false
        return
      }

      return this.updateQty(this.item.quantity + 1)
    },
    handleSizeChange (size) {
      this.loading = true

      return this.changeSize({
        oldVariant: this.item.item,
        newVariant: size.value,
      })
        .then(cart => this.itemQuantityUpdateSuccess(cart, -this.item.quantity))
        .then((cart) => {
          const newItem = cart.items.find(item => item.item === size.value)
          this.trackQuantityChange(newItem, this.item.quantity)
          this.selectedItem = newItem
        })
        .catch(this.handleUpdateQuantityError)
        .finally(this.itemQuantityUpdateFinally)
    },
    handleColorChange (colorSwatch) {
      this.loading = true

      return this.changeColor({
        oldVariant: this.item?.item,
        newVariant: colorSwatch?.item?.item
      })
        .then(cart => this.itemQuantityUpdateSuccess(cart, -this.item.quantity))
        .then((cart) => {
          const newItem = cart.items.find(item => item.item === colorSwatch?.item.item)
          this.trackQuantityChange(newItem, this.item.quantity)
          this.selectedItem = newItem
        })
        .catch(this.handleUpdateQuantityError)
        .finally(this.itemQuantityUpdateFinally)
    },
    handleUpdateQuantityError (error) {
      this.$notify({
        title: this.$t('Dialog_Add_To_Cart_Error_Title'),
        text: error.message,
        type: 'error'
      })
    },
    isSizeOptionDisabled (size) {
      return size.label === this.selectedSize.label || size.quantity === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-item {
  display: grid;
  grid-column-gap: .8rem;
  grid-template-columns: 1fr 4fr;
  grid-template-areas:
    "image details"
    "image swatches";
  padding: .8rem;
  margin-bottom: .8rem;
  background-color: $white;
  .image {
    grid-area: image;
  }
  .top-row,
  .bottom-row {
    p {
      margin: 0;
      line-height: 140%;
    }
  }
  .top-row {
    grid-area: details;
    display: flex;
    flex-direction: column;
    position: relative;
    .info {
      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 1rem;
        .badge {
          margin-right: .8rem;
        }
      }
      .name,
      .price {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
      }
      .price {
        .sale,
        .old-price {
          display: inline-block;
        }
        .sale {
          color: $badge-red;
          padding-right: 0.4rem;
        }
        .old-price {
          font-size: 1rem;
          font-weight: 700;
          text-decoration: line-through;
        }
      }
    }
    .remove {
      position: absolute;
      top: 0;
      right: 0;
      width: 1.6rem;
      height: 1.6rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .type-quantity-selector {
        @include p--small;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3.2rem;
        .change-quantity {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .quantity {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 3.2rem;
            span {
              @include p--small;
              line-height: 100%;
              font-weight: bold;
            }
          }
          .minus,
          .plus {
            cursor: pointer;
            border: 1px solid $nude-25;
            width: 3.2rem;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              width: 1.2rem;
              height: 1.2rem;
            }
            @media (hover:hover) {
              &:hover {
                background: $nude-25;
              }
            }
          }
        }
      }
  }

  .bottom-row {
    grid-area: swatches;
    display: flex;
    column-gap: 1.6rem;
    align-items: flex-end;
    .size {
      @include p--small;

      line-height: 1.4rem;

      font-weight: 500;

      .size-menu-toggle {
        @include button-reset;

        .size-menu-chevron {
          display: inline-block;
          height: .75rem;
          margin-left: .5rem;
          transition: transform .2s;

          &.open {
            transform: scaleY(-1);
          }
        }
        &.click-disabled {
          cursor: default;
        }
      }
    }

    .change-color-menu {
      @include p--small;

      line-height: 1.4rem;
      .color-menu-toggle {
        @include button-reset;
        display: flex;
        align-items: center;
        grid-column-gap: 0.5rem;
        .color-circle {
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
        }
        .change-color-menu-chevron {
          display: inline-block;
          height: .75rem;
          transition: transform .2s;

          &.open {
            transform: scaleY(-1);
          }
        }
      }
    }
  }
  .change-size {
    width: 100%;
    grid-column: span 2;
    padding-top: 0.8rem;

    .change-size-title {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 140%;
      margin-bottom: 1.2rem;
      display: block;
    }

    .sizes {
      display: flex;
      justify-content: space-between;

      .size-option {
        @include button-reset;
        padding: 1rem 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        transition: background-color ease 150ms;
        will-change: background-color;

        &.out-of-stock {
          color: $grey-50;
          text-decoration: line-through;
          cursor: default;

          &:before,
          &:after {
            content: "\00a0\00a0";
          }
        }

        &.selected {
          background-color: $black;
          color: $white;
        }

        &:hover:not(.out-of-stock) {
          background-color: $nude-25;
          color: $black;
        }
      }
    }
  }
  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .cart-item {
    grid-template-columns: 1fr 6fr;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .cart-item {
    grid-template-columns: 1fr 4fr;
    .top-row {
      .info .name .badge {
        margin-right: 1.6rem;
      }
    }
  }
}
</style>
