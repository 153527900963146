<template>
  <div
    class="topbar-wrapper"
    :class="'bg-color--' + backgroundColor"
    :style="[foregroundColorCss, backgroundColorCss]"
  >
    <transition name="slide-in-top">
      <TopbarPanel
        v-if="topbarPanelOpen"
        :topbar-text-items="topbarTextItems"
        @close="hideTopbarPanel"
      />
    </transition>
    <div class="layout-placeholder" />
    <div class="instagram">
      <span v-if="instagramText.html" class="icon">
        <InstagramIcon />
      </span>
      <span class="text">
        <client-only>
          <mp-link
            v-if="instagramText.html"
            :to="$u('https://www.instagram.com/stronger')"
            target="_blank"
            rel="noreferrer nofollow"
            v-html="instagramText.html"
          />
        </client-only>
      </span>
    </div>
    <div v-if="topbarTextItems.length" class="topbar-text">
      <transition name="fade" mode="out-in">
        <div
          class="title-container"
          :key="currentIndex"
          @click="showTopbarPanel"
        >
          <img
            v-if="topbarIcon.filename && topbarIconPosition === 'left'"
            :src="getImageUrl(topbarIcon.filename)"
            class="icon left"
          >
          <div class="title">
            {{ currentTitle }}
          </div>
          <ChevronDownSmallIcon v-if="currentTitle" class="chevron" />
        </div>
      </transition>
      <img
        v-if="topbarIcon.filename && topbarIconPosition === 'right'"
        :src="getImageUrl(topbarIcon.filename)"
        class="icon right"
      >
    </div>
    <div
      class="next-shipment"
      :style="foregroundColorCss"
      :class="displayShipmentTimer ? 'counter-visible' : ''"
    >
      <client-only>
        <div class="shopping-from">{{ $t('Global_Shopping_From') }}:</div>
        <slot name="country-selector" />
        <div v-if="displayShipmentTimer" class="counter">
          <span class="icon">
            <AirplaneIcon />
          </span>
          <span class="next-shipment-text">
            {{ $t('Topbar_Next_Shipment') }}
          </span>
          <CountdownTimer
            :shipment-time="shipmentTime"
            :enable-three-hour-countdown="enableThreeHourCountdown"
          />
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ColorHexCode from '~/mixins/ColorHexCode.js'
import InstagramIcon from '~/assets/icons/instagram-black-and-white.svg'
import AirplaneIcon from '~/assets/icons/airplane.svg'
import CountdownTimer from '~/components/header/topbar/CountdownTimer'
import { nl2br } from '~/lib/helpers'
import ChevronDownSmallIcon from '~/assets/icons/chevron-down-small.svg'
import TopbarPanel from '~/components/header/topbar/TopbarPanel.vue'

export default {
  name: 'Topbar',
  components: {
    CountdownTimer,
    InstagramIcon,
    AirplaneIcon,
    ChevronDownSmallIcon,
    TopbarPanel,
  },
  mixins: [ColorHexCode],
  props: {
    foregroundColor: {
      type: String,
      required: false,
      default: '#ffffff',
    },
    foregroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'night-100',
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    topbarTextItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    instagramText: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    shipmentTime: {
      type: String,
      required: false,
      default: '15',
    },
    displayShipmentTimer: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableThreeHourCountdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    topbarIcon: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    topbarIconPosition: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      currentIndex: 0,
      isDisplayed: true,
    }
  },
  computed: {
    ...mapState({
      topbarPanelOpen: state => state.ui.topbarPanelOpen,
    }),
    foregroundColorCss () {
      return {
        '--foreground-color': this.color(
          this.foregroundColor,
          this.foregroundColorHex
        ),
      }
    },
    backgroundColorCss () {
      return {
        backgroundColor: this.color(
          this.backgroundColor,
          this.backgroundColorHex
        ),
      }
    },
    topBarTitles () {
      return this.topbarTextItems
        ?.filter(item => item.title !== '')
        .map(item => item.title)
    },
    currentTitle () {
      return this.topBarTitles?.[this.currentIndex] ?? null
    },
  },
  mounted () {
    if (this.topBarTitles.length > 1) {
      setInterval(this.changeTitle, 5000)
    }
  },
  methods: {
    nl2br,
    getImageUrl (filename) {
      return this.$imgproxy.transform(
        filename,
        'preset:sharp/rs:fit/h:24/g:nowe/q:70'
      )
    },
    showTopbarPanel () {
      this.$store.dispatch('ui/showTopbarPanel')
    },
    hideTopbarPanel () {
      this.$store.dispatch('ui/hideTopbarPanel')
    },
    changeTitle () {
      this.currentIndex = (this.currentIndex + 1) % this.topBarTitles.length
    },
  },
}
</script>

<style lang="scss" scoped>
.topbar-wrapper {
  @include p--mini;
  width: 100%;
  min-height: $topbar-wrapper-mobile;
  display: flex;
  justify-content: space-between;
  color: var(--foreground-color);
  gap: 1.6rem;
  .instagram {
    display: none;
  }
  .topbar-text,
  .next-shipment {
    text-align: center;
  }
  .topbar-text {
    padding-right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    .icon {
      display: inline-flex;
      max-height: 1.2rem;
      &.right {
        margin-left: 0.8rem;
      }
      &.left {
        margin-right: 0.8rem;
      }
    }
  }
  .next-shipment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.8rem 1.6rem 0.8rem 0;
    .shopping-from {
      display: none;
    }
    .countdown {
      color: $mint-100;
      font-weight: 700;
      display: block;
    }
    &::v-deep {
      .country-selector {
        display: inline-flex;
        svg,
        .name {
          display: none;
        }
        .selected-country .country {
          margin-right: 0;
          flex-shrink: 0;
        }
      }
    }
    .counter {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // keeping the .icon-styling in case they want this icon on mobile again
      .icon {
        display: none;
        width: 1.2rem;
        height: 1.2rem;
        position: relative;
        margin-right: 0.8rem;
        svg {
          width: 100%;
          * {
            fill: var(--foreground-color);
          }
        }
      }
    }
  }
}

.layout-placeholder {
  width: 3.2rem;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.4rem;
}

.title {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 140%;
  letter-spacing: 0.011rem;
  text-transform: uppercase;
}

.chevron {
  width: 1rem;
  height: 0.6rem;
  flex-shrink: 0;
  fill: $white;
  opacity: 1;
  margin-left: 0.4rem;
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .topbar-wrapper {
    font-size: 1.2rem;
    position: relative;
    height: $topbar-wrapper-desktop;
    padding: 0 2.4rem;
    border-bottom: 1px solid $night-50;
    color: var(--foreground-color);
    .instagram {
      display: flex;
      width: 25%;
      align-items: center;
      .icon {
        width: 1.6rem;
        height: 1.6rem;
        position: relative;
        display: inline-block;
        margin-right: 0.8rem;
        svg {
          width: 100%;
          fill: var(--foreground-color);
        }
      }
      .text {
        @include p--small;
        margin: 0;
        p {
          margin: 0;
        }
      }
    }
    .topbar-text,
    .next-shipment {
      width: auto;
      padding: 0;
    }
    .topbar-text {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        align-self: center;
        margin-bottom: 0.2rem;
        &.right {
          margin-left: 0.8rem;
        }
        &.left {
          margin-right: 0.8rem;
        }
      }
    }
    .next-shipment {
      width: auto;
      min-width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .counter {
        margin-left: 2.4rem;
        .next-shipment-text,
        .icon {
          display: inline-block;
        }
      }
      .shipping-from {
        display: block;
      }
      &::v-deep .country-selector {
        display: block;
        height: 100%;
        svg {
          display: flex;
        }
        .name {
          display: block;
        }
        @media (hover: hover) {
          &:hover {
            box-shadow: inset 0 -1px 0 $mint-100;
          }
        }
        .selected-country {
          height: 100%;
          .country {
            margin-right: 0.8rem;
          }
        }
      }
      &:not(.counter-visible) {
        .shopping-from {
          display: inline;
          padding: 0 0.8rem;
        }
      }
    }
  }

  .layout-placeholder {
    display: none;
  }

  .title {
    font-size: 1.2rem;
    letter-spacing: 0.024rem;
  }

  .chevron {
    width: 1.33rem;
    height: 0.8rem;
    margin-left: 0.8rem;
  }
}
// Laptop
@media screen and (min-width: $laptop) {
  .topbar-wrapper {
    padding: 0 8rem;
    .next-shipment {
      .shopping-from {
        display: inline;
        padding: 0 0.8rem;
      }
    }
  }
}
</style>
