<template>
  <div class="quick-add" :class="{ 'added': added }">
    <button
      v-show="expanded && !added"
      class="size button-reset"
      :disabled="!size.quantity"
      v-for="(size, index) in items"
      :key="'btn-size-selector' + index"
      @click="quickAdd(size)"
    >
      {{ size.label }}
    </button>
    <button
      v-if="!added && oneSize"
      class="size button-reset"
      :disabled="!items[0].quantity"
      @click="quickAdd(items[0])"
    >
      {{ $t('Global_Add_To_Cart') }}
    </button>
    <button
      v-else-if="!added"
      class="size select button-reset"
      @click="$emit('toggleQuickAdd')"
    >
      {{ $t('Global_Choose_Size') }}
      <CrossIcon v-show="expanded && !added" />
      <img v-show="!expanded" src="/icons/chevron-down-small.svg">
    </button>
    <div v-else class="size added">
      <img src="/icons/added.svg">
      {{ $t('Product_Upsell_Added') }}
    </div>
  </div>
</template>

<script>
import CrossIcon from '~/static/icons/cross-fat.svg'
export default {
  name: 'QuickAdd',
  components: { CrossIcon },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    added: {
      type: Boolean,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    },
    oneSize: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    quickAdd (size) {
      this.$emit('quickAddToCart', size)
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-add {
  background-color: $white;
  width: 100%;
  border: 1px solid $black;
  .size {
    width: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-family: $font-base;
    font-size: 1rem;
    line-height: 140%;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    border-bottom: 1px solid $grey-25;
    &.select {
      justify-content: space-between;
      position: relative;
      svg {
        stroke-width: 0px;
        stroke: black;
        height: 10px;
      }
      img {
        height: 6px;
      }
      svg,
      img {
        position: absolute;
        top: 12px;
        right: 8px;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    &:disabled {
      background-color: #F3F3F3;
      text-decoration-line: line-through;
    }
  }
  @media (hover: hover) {
    .size:hover:not(:disabled):not(.added) {
      cursor: pointer;
    }
  }
  &.added {
    border: none;
    .size {
      justify-content: center;
      border: none;
      img {
        height: 12px;
        margin-right: 4px;
      }
    }
  }
}
</style>
