/**
 * Important:
 * The Flowbox API will respond with posts from arbitrary flows
 * when productId and/or tags are used to the query.
 */

export default {
  actions: {
    /**
     * Use Flowbox product API to get product related data,
     * in order to map a product key to a sku.
     */
    loadProducts ({ state, commit, rootGetters }, productIDs) {
      if (!productIDs) {
        return []
      }
      // We don't care about the locale, but the API does
      const feedUrl = `https://experience.getflowbox.com/products/${productIDs}?locale=en-gb`
      return fetch(feedUrl).then((response) => {
        return response.json().then((json) => {
          return json
        })
      }).catch((error) => {
        console.error(error, 'store', 'flowbox', { productIDs })
        return { feed: [], cursor: null }
      })
    },

    lookupFeed ({ state, commit, getters, rootGetters }, { feed = null, sku = null, filter = null, cursor = null }) {
      const filterString = filter?.length ? filter.join(',') : null
      const feedKey = feed || rootGetters['storyblok/settings'].flowbox_feed_key
      if (!feedKey) {
        console.warn('Tried to load a Flowbox feed without a key.')
      }
      let feedUrl = `https://gateway.getflowbox.com/public/feed/${feedKey}`
      if (sku) {
        feedUrl += `?productId=${sku}&cursor=null&postsPerPage=30`
      } else if (filterString && cursor) {
        feedUrl += `?tags=${filterString}&cursor=${encodeURIComponent(JSON.stringify(cursor))}&postsPerPage=30`
      } else if (filterString) {
        feedUrl += `?tags=${filterString}&cursor=null&postsPerPage=30`
      } else {
        feedUrl += `?cursor=${encodeURIComponent(JSON.stringify(cursor))}&postsPerPage=30`
      }
      return fetch(feedUrl).then((response) => {
        return response.json().then((json) => {
          return { feed: json?.posts || [], cursor: json?.cursor || null }
        }).catch((error) => {
          console.warn(error)
          return { feed: [], cursor: null }
        })
      })
    }
  }
}
