import backendClientInit from './backend-client'
/**
 * This registers the centra API client and dispatches the initialize action
 * to set up market, cart and so on
 *
 * We should definitely explode if the config doesn't exist but the plugin is registered.
 *
 * @param context
 * @param inject
 */
export default async (context, inject) => {
  const { app, store } = context

  const backendApi = backendClientInit(
    app,
    store,
    () => store.getters['frontend/currentMarketId'],
    () => store.getters['frontend/currentCountryCode'],
    () => store.getters['frontend/currentLanguageCode'],
    () => store.getters['campaign/activeCampaign']
  )
  inject('backendApi', backendApi)

  if (!process.client) {
    // Server side initialization
    const response = await backendApi.get('contexts')
    store.commit('centra-market/markets', response.data.markets, { root: true })
    store.commit('centra-pricelist/pricelists', response.data.pricelists, { root: true })
    store.commit('frontend/countries', response.data.countries, { root: true })
    store.commit('frontend/languages', response.data.languages, { root: true })
  }
}
