<template>
  <div v-if="topbarTextItems.length > 0" class="panel-wrapper">
    <div class="close-icon" @click="$emit('close')">
      <CloseIcon />
    </div>
    <div
      v-for="item in topbarTextItems"
      :key="item.id"
      class="panel-content"
    >
      <div class="title" v-if="item.title">
        {{ item.title }}
      </div>
      <div class="text" v-html="nl2br(sanitizePath(item.text.html))" />
    </div>
  </div>
</template>

<script>
import CloseIcon from '~/assets/icons/cross.svg'
import { nl2br } from '~/lib/helpers'

export default {
  name: 'TopbarPanel',
  components: {
    CloseIcon,
  },
  props: {
    topbarTextItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    nl2br,
    sanitizePath (str) {
      if (typeof str !== 'string') { return }

      // Add target _blank to all external links, and remove /site from all internal links
      return str
        .replace(/href="http>*/g, 'target="_blank" href="http')
        .replace(/href="\/?site\/(.*?)"/g, 'href="/$1"')
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-wrapper {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  overflow: hidden;
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 5.6rem 2.6rem 1.6rem 2.6rem;
  height: 100vh;
}

.panel-content {
  @include p--large;
  letter-spacing: 0.04rem;
  color: $black;
  max-height: 25.6rem;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 4.8rem;
  }
}

.title {
  font-weight: 600;
}

.text {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.03rem;
  ::v-deep a {
    text-decoration: underline;
    font-weight: 600;
  }
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  position: absolute;
  top: 1.6rem;
  right: 2rem;

  svg {
    height: 1.8rem;
    width: 1.8rem;
    fill: $black;
  }
}

@media (min-width: $tablet) {
  .panel-wrapper {
    flex-direction: row;
    gap: 8rem;
    align-items: flex-start;
    height: fit-content;
    padding: 4rem 4.2rem;
    justify-content: center;
  }

  .panel-content {
    width: 36rem;
    &:not(:last-child) {
      margin-bottom: revert;
    }
  }

  .text {
    font-size: 1.4rem;
    letter-spacing: 0.035rem;
  }

  .close-icon {
    top: 4rem;
    right: 4.2rem;
  }
}
</style>
