import { strongerScrollRestorationFix } from '../utils/scroll-restoration-fix'
import { getDepictConfig } from '~/components/depict/utils/getDepictConfig'

let depictSearch = null

export const depictSearchProvider = async ({ router, store, getDisplays, merchant }) => {
  if (depictSearch) {
    // If the DepictSearch instance already exists, return it
    return depictSearch
  }

  // Wait for both modules to load
  const depictUIModule = await import('@depict-ai/ui')
  const [depictUI] = await Promise.all([
    depictUIModule
  ])
  // Extract the necessary exports from the modules
  const { DepictSearch, SearchModalV2 } = depictUI
  const { MERCHANT, LOCALIZATION, getMarket } = await getDepictConfig(store, merchant)
  const MARKET = await getMarket()
  const searchPathName = `/${MARKET}/search`

  // Apply the scroll restoration fix, we got this from Depict who used
  // it in their old implementation of Stronger Depict
  strongerScrollRestorationFix()

  // Create a new DepictSearch instance if it doesn't exist
  depictSearch = new DepictSearch({
    searchModalComponent: SearchModalV2,
    merchant: MERCHANT,
    market: MARKET,
    localization: LOCALIZATION,
    display_transformers: {
      categories: ({ data, market }) =>
        data.map((categoryDisplay) => {
          return { ...categoryDisplay, page_url: `/${market}/${(categoryDisplay.slug)}` }
        }),
      products: async ({ displays }) => {
        return await getDisplays({ displays })
      }
    },
    enable_content_search: true,
    url_transformer (url) {
      url.pathname = searchPathName
    },
    // eslint-disable-next-line camelcase
    on_navigation ({ is_replace, scroll, new_url }) {
      const relativePath = new_url.pathname + new_url.search
      // eslint-disable-next-line camelcase
      if (is_replace) {
        return router.replace({ path: relativePath })
      }
      return router.push({ path: relativePath })
    },
  })
  return depictSearch
}
