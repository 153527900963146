import { each } from 'underscore'
import cartService from '../../services/made-people-backend/cart'

export default ({ $backendApi, store }, inject) => {
  const context = {
    getSelectionId: () => store.getters['centra-cart/selectionId'],
    getCountryCode: () => store.getters['frontend/currentCountryCode']
  }

  const methods = {
    ...cartService($backendApi, context)
  }

  const injector = method => inject(method, methods[method])
  each(Object.keys(methods), injector)
}
