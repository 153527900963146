export function getBadges (badges) {
  if (!badges || typeof badges !== 'object') {
    return []
  }
  return Object.values(badges).map((badge) => {
    if (badge === 'string') {
      return { text: badge, text_hex: '#000000', bg_hex: '#FFFFFF' }
    } else if (typeof badge === 'object') {
      return { text: badge.text, text_hex: badge.text_hex, bg_hex: badge.bg_hex }
    }
    return undefined
  }).filter(badge => typeof badge !== 'undefined')
}
