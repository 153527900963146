export default ($backendApi, context) => {
  const authenticationHeaders = () => ({
    headers: {
      token: context.getCustomerToken()
    }
  })

  const countryHeaders = () => ({
    headers: {
      country: context.getCountryCode()
    }
  })

  const captchaTokenHeaders = () => ({
    headers: {
      recaptchaToken: context.getCurrentCaptchaToken()
    }
  })

  const buildHeaders = (...callbacks) => {
    return callbacks.reduce((headers, headerGetter) => {
      return { headers: Object.assign(headers.headers, headerGetter().headers) }
    }, { headers: {} })
  }

  return {
    async login (email, password) {
      const data = {
        email,
        password
      }

      const response = await $backendApi.post('/account/login', data, countryHeaders())
      return response.data
    },

    async register (payload) {
      const response = await $backendApi.post('/account/register', payload, buildHeaders(countryHeaders, captchaTokenHeaders))
      return response.data
    },

    async getAccount () {
      const response = await $backendApi.get('/account', authenticationHeaders())
      return response.data
    },

    async getOrders () {
      const response = await $backendApi.get('/account/orders/list', authenticationHeaders())
      return response.data
    },

    async getOrder (id) {
      const response = await $backendApi.get(`/account/orders/${id}`, authenticationHeaders())
      return response.data
    }
  }
}
