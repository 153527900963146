<template>
  <div class="free-gift-popup">
    <div
      class="close"
      @click="close"
    >
      <CrossIcon />
    </div>
    <div class="content">
      <div class="headline">
        <span>{{ $t('Free_Gift_Popup_Headline') }}</span>
      </div>
      <div class="text">
        <span>{{ $t('Free_Gift_Popup_Text') }}</span>
      </div>
      <div class="product">
        <div class="left">
          <div class="image">
            <ResponsiveImage
              :mobile-src="gift._product.mainImage"
              :alt="gift.productName"
              mobile-display-size="50vw"
              desktop-display-size="25vw"
            />
          </div>
        </div>
        <div class="right">
          <div class="name">
            <span>{{ gift.productName }}</span>
          </div>
          <div
            v-if="gift._product.product_type"
            class="type"
          >
            <span>{{ gift._product.product_type }}</span>
          </div>
          <div class="price">
            <span>{{ gift.totalPriceBeforeDiscount }}</span>
          </div>
          <div class="free">
            <span>{{ $t('Free_Gift_Popup_Free') }}</span>
          </div>
        </div>
      </div>
      <div class="checkout">
        <div
          class="button outline medium white"
          @click="goToCheckout"
        >
          <span class="label">
            {{ $t('Global_Checkout') }}
          </span>
        </div>
      </div>
      <div class="continue">
        <div
          class="button outline medium white"
          @click="close"
        >
          <span class="label">
            {{ $t('Global_Continue_Shopping') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'FreeGiftPopup',
  components: {
    CrossIcon
  },
  props: {
    gift: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      confettiColors: [
        '#EB4F4A',
        '#53DD82',
        '#72A4C3',
        '#11F0EB',
        '#7AB4AC',
        '#F8D43C',
        '#B07FC0',
        '#83D5C9'
      ]
    }
  },
  created () {
    this.confettiStart()
  },
  methods: {
    confettiStart () {
      this.$confetti.start({
        particlesPerFrame: 0.25,
        particles: [
          {
            type: 'rect'
          },
          {
            type: 'circle'
          },
          {
            type: 'heart'
          }
        ],
        defaultColors: this.confettiColors
      })
    },
    confettiStop () {
      this.$confetti.stop()
    },
    goToCheckout () {
      this.confettiStop()
      this.$router.push(this.$u('checkout'))
    },
    close () {
      this.confettiStop()
      this.$store.dispatch('ui/hideFreeGiftPopup')
    }
  }
}
</script>

<style lang="scss" scoped>
.free-gift-popup {
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black, 0.8);
  .close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    cursor: pointer;
    svg {
      width: 1.6rem;
      path {
        fill: $white
      }
    }
  }
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .headline {
      @include h--x-large;
      color: $peach-100;
    }
    .headline,
    .text {
      text-align: center;
    }
    .headline,
    .text,
    .product {
      padding-bottom: 3.2rem;
    }
    .headline {
      margin: 0;
    }
    .text {
      @include p--large;
      color: $white;
    }
    .product {
      display: flex;
      justify-content: center;
      .left {
        padding-right: 0.8rem;
        .image {
          &::v-deep .responsive-img {
            picture {
              img {
                border-radius: 0.8rem;
                width: 6.4rem;
              }
            }
          }
        }
      }
      .right {
        > * {
          color: $white;
        }
        .name,
        .price,
        .free {
          font-family: $font-base;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 140%;
        }
        .type {
          @include p--small;
        }
        .price {
          text-decoration: line-through;
        }
        .free {
          text-transform: uppercase;
        }
      }
    }
    .checkout,
    .continue {
      text-align: center;
    }
    .checkout {
      margin-bottom: 1.6rem;
    }
  }
}
</style>
