export const state = () => {
  return {
    currentPageName: ''
  }
}

export const mutations = {
  setCurrentPageName (state, value) {
    state.currentPageName = value
  }
}

export const getters = {
  currentPageName: state => state.currentPageName
}
