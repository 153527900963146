<template>
  <div
    class="toggle-search depict-search"
    @click="showSearch"
  >
    <div :title="$t('Toggle_Search_Open')">
      <SearchIcon />
    </div>
  </div>
</template>

<script>
import SearchIcon from '~/assets/icons/search.svg'

export default {
  name: 'ToggleSearch',
  components: { SearchIcon },
  inject: ['depictSearchProvider'],
  methods: {
    async showSearch () {
      const awaitedProvider = await this.depictSearchProvider
      awaitedProvider.modal_open = true
      this.$store.dispatch('ui/closeAll')
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-search {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease-out;
  @media (hover: hover) {
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  div {
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      fill: white;
    }
  }
}
</style>
