<template>
  <div id="ingrid-to-free-shipping" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IngridUpsell',
  props: {
    cart: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    validLocales: [
      'cs-CZ',
      'da-DK',
      'de-DE',
      'el-GR',
      'es-ES',
      'en-AU',
      'en-GB',
      'en-IE',
      'en-US',
      'et-EE',
      'fi-FI',
      'fr-FR',
      'hr-HR',
      'is-IS',
      'it-IT',
      'ja-JP',
      'ko-KR',
      'lt-LT',
      'lv-LV',
      'nb-NO',
      'nl-NL',
      'no-NO',
      'nn-NO',
      'pl-PL',
      'ru-RU',
      'sl-SI',
      'sk-SK',
      'sv-SE',
      'sv-FI',
      'zh-CN',
    ],
    isIngridUpsellLoaded: false,
  }),
  computed: {
    ...mapGetters({
      languageCode: 'frontend/currentLanguageCode',
      countryCode: 'frontend/currentCountryCode',
    }),
    validLocale () {
      return this.validLocales.includes(`${this.languageCode}-${this.countryCode}`)
    },
  },
  watch: {
    isIngridUpsellLoaded (loaded) {
      if (loaded) {
        this.initIngridUpsell(this.cart)
      }
    },
    cart (newCart) {
      this.updateIngridUpsell(newCart)
    },
  },
  methods: {
    getIngridUpsellCartData () {
      const items = this.cart.items.map(item => ({
        name: item.productName,
        price: Math.floor(item.totalPriceAsNumber),
        quantity: item.quantity,
        sku: item.sku,
      }))
      // Ingrid requires the total value in cents therefore we multiply by 100
      const totalValue = Math.floor(this.cart.totals.itemsTotalPriceAsNumber * 100)

      return {
        auth_token: `ingrid-http-auth-token:${this.$config.ingridUpsellToken}`,
        cart: { items, currency: this.cart.currency, total_value: totalValue },
        locales: [`${this.languageCode}-${this.countryCode}`],
        country: this.countryCode,
      }
    },
    initIngridUpsell (cart) {
      if (!this.validLocale) {
        return
      }

      window._ingridUpsellWidgetApi.render('ingrid-to-free-shipping', this.getIngridUpsellCartData(cart))
    },
    updateIngridUpsell (cart) {
      if (window._ingridUpsellWidgetApi === undefined || !this.validLocale) {
        return
      }
      window._ingridUpsellWidgetApi.updateCart(this.getIngridUpsellCartData(cart))
    },
  },
  head () {
    return {
      script: [
        {
          hid: 'ingrid_upsell',
          src: this.$config.ingridUpsellSrc,
          defer: true,
          callback: () => {
            this.isIngridUpsellLoaded = true
          },
        },
      ],
    }
  },

}
</script>
