<template>
  <mp-link
    :to="$u(buttonLink)"
    class="button"
    :class="[
      buttonStyle,
      icon ? 'with-icon' : '',
      buttonLink.cached_url ? 'linked' : '',
    ]"
    :style="styleProps"
    :target="targetBlank ? '_blank' : '_self'"
    :rel="targetBlank ? 'noreferrer nofollow' : false"
  >
    <div v-if="icon" class="icon">
      <img :src="icon" :alt="label">
      <slot name="icon" />
    </div>
    <span class="label">
      {{ label }}
      <slot name="label" />
    </span>
  </mp-link>
</template>

<script>
import ColorHexCode from '~/mixins/ColorHexCode'

export default {
  name: 'HeroButton',
  mixins: [ColorHexCode],
  props: {
    label: {
      type: String,
      default: '',
    },
    buttonStyle: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'black',
    },
    bgColorHex: {
      type: String,
      default: '#000000',
    },
    bgHoverColor: {
      type: String,
      default: '',
    },
    bgHoverColorHex: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'white',
    },
    textColorHex: {
      type: String,
      default: '',
    },
    heightMobile: {
      type: String,
      default: '',
    },
    heightDesktop: {
      type: String,
      default: '',
    },
    labelFontSizeMobile: {
      type: String,
      default: '',
    },
    labelFontSizeDesktop: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: '',
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
    /**
     * Makes it possible to force use a link from a parent,
     * i.e. a Hero.
     */
    inheritedLink: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    buttonLink () {
      return this.inheritedLink?.cached_url ? this.inheritedLink : this.link
    },
    styleProps () {
      const buttonSizes = {
        small: {
          height: '3rem',
        },
        medium: {
          height: '4rem',
        },
        large: {
          height: '4.8rem',
        },
      }

      const labelFontSizes = {
        xsmall: '0.8rem',
        small: '0.9rem',
        medium: '1rem',
        large: '1.1rem',
        default: '1rem',
      }

      const labelLetterSpacing = {
        xsmall: '0.12rem',
        small: '0.135rem',
        medium: '0.15rem',
        large: '0.18rem',
        default: '0.15rem',
      }

      return {
        '--bg-color': this.color(this.bgColor, this.bgColorHex),
        '--text-color': this.color(this.textColor, this.textColorHex),
        '--bg-hover-color': this.color(this.bgHoverColor, this.bgHoverColorHex),
        '--button-height-mobile': buttonSizes[this.heightMobile]?.height,
        '--button-height-desktop': buttonSizes[this.heightDesktop]?.height || buttonSizes[this.heightMobile]?.height,
        '--label-font-size-mobile': this.labelFontSizeMobile !== '' ? labelFontSizes[this.labelFontSizeMobile] : labelFontSizes.default,
        '--label-font-size-desktop': this.labelFontSizeDesktop !== '' ? labelFontSizes[this.labelFontSizeDesktop] : labelFontSizes.default,
        '--label-letter-spacing-mobile': labelLetterSpacing[this.labelFontSizeMobile] || labelLetterSpacing.default,
        '--label-letter-spacing-desktop': labelLetterSpacing[this.labelFontSizeDesktop] || labelLetterSpacing.default,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.button {
  color: var(--text-color);
  background: var(--bg-color);
  align-items: center;

  .label {
    font-family: $font-base-new;
    font-weight: 700;
    line-height: 11.96px;
  }

  &.linked {
    pointer-events: all;
  }
}

// overriding default hover styles...
.button {
  &.hero-btn {
    &:before {
      background: none;
    }
  }
}

.outline {
  color: var(--text-color);
  background: transparent;
  border: 0.2rem solid var(--bg-color);
}

.outline:hover,
.solid:hover,
.simple:hover,
.outline-neon:hover {
  &:hover:not(:disabled) {
    background: var(--bg-hover-color);
  }
}

.simple {
  .label {
    color: var(--text-color);
  }
}
</style>
