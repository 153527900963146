<template>
  <div class="usp-logos-and-text">
    <div class="logos">
      <div
        v-for="(logo, index) in logos"
        :key="index"
        class="logo"
      >
        <img
          :src="logo.logo.filename"
          :alt="logo.alt"
          loading="lazy"
        >
      </div>
    </div>
    <div class="alt-texts">
      <div
        v-for="(logo, index) in logos"
        :key="index + logo.alt"
      >
        <span class="alt-text">{{ logo.alt }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UspLogos',
  props: {
    logos: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss" scoped>
.usp-logos-and-text {
    padding: 1.6rem 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
      .logos {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 2rem;
        .logo {
          height: 4rem;
          display: inline-block;
          img {
            border-radius: 0.5rem;
            border: solid 1px $grey-50;
            height: 100%;
          }
        }
      }
      .alt-texts {
        display: flex;
        flex-direction: column;
        .alt-text {
          font-weight: 500;
        }
      }
    }
</style>
