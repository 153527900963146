<template>
  <div
    class="footer-story"
    :style="backgroundImageCss"
  >
    <div class="content">
      <div class="headline-wrap">
        <div
          class="headline h--x-large"
          v-html="nl2spanbr(content[0].headline.html)"
        />
        <div
          v-if="content[0].subtext"
          class="subtext"
          v-html="content[0].subtext"
        />
      </div>
      <div class="text">
        <div
          v-if="content[0].lead.html"
          class="lead"
          v-html="content[0].lead.html"
        />
        <div
          v-if="content[0].paragraph.html"
          class="paragraph"
          v-html="content[0].paragraph.html"
        />
      </div>
      <Button
        v-for="(button, index) in content[0].ctas"
        :key="index"
        v-bind="button"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterStory',
  props: {
    content: {
      type: Array,
      required: false,
      default () {}
    }
  },
  computed: {
    backgroundImageCss () {
      return this.content[0].backgroundImage.filename
        ? { backgroundImage: `linear-gradient(180deg, rgba(40, 41, 45, 0) 0%, #27282C 50%), url(${this.$imgproxy.transform(this.content[0].backgroundImage.filename, 'preset:sharp/rs:fit:1000/g:nowe/q:70')})` }
        : ''
    }
  },
  methods: {
    nl2spanbr (str) {
      const output = '<span>' + str + '</span>'
      return output.replace(/(?:\r\n|\r|\n)/g, '</span><br /><span>')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-story {
  padding: 4.4rem 2.4rem;
  background-size: cover;
  color: white;
  background-color: $night-100;
  .content {
    position: relative;
    text-align: center;
    max-width: 60rem;
    margin: 0 auto;
    .headline-wrap {
      position: relative;
      display: inline-block;
      .headline {
        margin: 0;
        text-align: center;
        color: $white;
        &::v-deep {
          > br:last-of-type {
            display: none;
          }
          p {
            margin: 0;
            em {
              font-style: normal !important;
              color: $mint-100;
              -webkit-text-stroke-width: 0;
            }
          }
        }
      }
      .subtext {
        margin-top: -10px;
        float: right;
        margin-right: -20px;
        color: $white;
        font-family: $font-viva;
        font-size: 4.8rem;
        line-height: 100%;
        transform: rotate(-10.25deg);
      }
    }
    .text {
      .lead {
        @include p--large;
      }
      .paragraph {
        @include p--small;
      }
    }
    .button {
      margin: 1rem 0;
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .footer-story {
    padding: 8rem 2.4rem;
    .content {
      .headline {
        font-size: 4rem;
        line-height: 90%;
      }
      .subtext {
        right: 15rem;
        top: 10rem;
      }
      .text {
        .lead {
          font-size: 1.8rem;
        }
        .paragraph {
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
      .button {
        min-width: 25rem;
        margin: 2rem 0;
      }
    }
  }
}
</style>
