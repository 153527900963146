export default {
  namespaced: true,
  state () {
    return {
      sections: {},
    }
  },
  mutations: {
    removeSections (state) {
      state.sections = {}
    },
    addSection (state, section) {
      state.sections = {
        ...state.sections,
        [section.section]: {
          ...state.sections[section.section],
          items: getSectionItems(section),
          activeVariant: null,
          selectedSize: null,
          hasSelectedSize: false
        }
      }
    },
    selectSize (state, { sku, sizeLabel, sectionId }) {
      for (const label in state.sections[sectionId].items[sku].options) {
        state.sections[sectionId].items[sku].options[label].selected = false
      }
      state.sections = {
        ...state.sections,
        [sectionId]: {
          ...state.sections[sectionId],
          hasSelectedSize: true,
          activeVariant: sku,
          selectedSize: { label: sizeLabel, value: state.sections[sectionId].items[sku].options[sizeLabel].value },
          items: {
            ...state.sections[sectionId].items,
            [sku]: {
              ...state.sections[sectionId].items[sku],
              options: {
                ...state.sections[sectionId].items[sku].options,
                [sizeLabel]: {
                  ...state.sections[sectionId].items[sku].options[sizeLabel],
                  selected: true
                }
              }
            }
          }
        }
      }
    },
    selectActiveVariant (state, { sku, sectionId }) {
      state.sections = {
        ...state.sections,
        [sectionId]: {
          ...state.sections[sectionId],
          activeVariant: sku,
          hasSelectedSize: false
        }
      }
    },
  },
  actions: {
    addSection ({ commit }, payload) {
      commit('addSection', payload)
    },
    removeSections ({ commit }) {
      commit('removeSections')
    },
    selectSize ({ commit }, payload) {
      commit('selectSize', payload)
    },
    selectActiveVariant ({ commit }, payload) {
      commit('selectActiveVariant', payload)
    }
  },
  getters: {
    isAllSelected: (state) => {
      for (const sku in state.items) {
        let skuHasSelectedSize = false
        for (const label in state.items[sku].options) {
          if (state.items[sku].options[label].selected) {
            skuHasSelectedSize = true
          }
        }
        if (!skuHasSelectedSize) {
          return false
        }
      }

      return true
    },
    allVariantsSelected: (state) => {
      return Object.values(state.sections).every(
        section => section.hasSelectedSize
      )
    },
    selectedBundleOptions: (state) => {
      const sections = []
      for (const section in state.sections) {
        if (!state.sections[section].hasSelectedSize) {
          return []
        }
        sections.push({
          section,
          item: state.sections[section].selectedSize.value
        })
      }
      return sections
    },
    selectedSizeForSectionItem: state => (sectionId) => {
      return state.sections[sectionId]?.selectedSize
    }
  }
}

const createOptionsObject = (sizeOptions, isOnesize) => {
  const result = {}
  for (const option of sizeOptions) {
    result[option.label] = { selected: isOnesize && option.quantity > 0, value: option.value }
  }
  return result
}

const getSectionItems = (section) => {
  const products = {}
  section.products.forEach((product) => {
    products[product.productSku] = {
      options: createOptionsObject(product.sizeOptions, product.isOnesize)
    }
  })
  return products
}
