<template>
  <div class="mini-cart">
    <div class="main">
      <ModalHeader
        slot="modal-header"
        :title="$t('Mini_Cart_My_Cart')"
        :subtitle="subTitle"
        title-position="left"
        icon-position="right"
        @close="toggleMiniCart"
      />
      <template v-if="cartIsEmpty">
        <div class="no-items">
          <p>{{ $t('Mini_Cart_Empty') }}</p>
          <div class="continue-shopping">
            <mp-link :to="$u('')" class="button solid medium black">
              <span class="label">
                {{ $t('Global_Continue_Shopping') }}
              </span>
            </mp-link>
          </div>
        </div>
      </template>
      <template v-else>
        <IngridUpsell v-if="!cartIsEmpty" :cart="cart" />
        <div class="cart-products">
          <CartItem
            v-for="item in cart.items"
            :key="item.line"
            :item="item"
            :show-color-swatch-description="false"
          />
        </div>
        <ProductUpsell :show-headings="false" :items="cart.items" />
        <Total
          :shipping-cost="shippingPrice"
          :total-cost="cart.totals.grandTotalPrice"
          :show-shipping-icon="cart.totals.shippingPriceAsNumber === 0"
          :total-discount-price="cart.totals.totalDiscountPrice || ''"
        />
        <div v-if="paymentLogos && Array.isArray(paymentLogos)" class="payment-logos">
          <div class="logos">
            <div
              v-for="(logo, index) in paymentLogos"
              :key="index"
              class="logo"
            >
              <img
                :src="logo.logo.filename"
                :alt="logo.alt"
                loading="lazy"
              >
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalHeader from '~/components/modal/modal-header/ModalHeader'
import Total from '~/components/cart/Total'
import CartItem from '~/components/mini-cart/CartItem'
import IngridUpsell from '~/components/ingrid-upsell/IngridUpsell'

export default {
  name: 'MiniCart',
  components: {
    CartItem,
    ModalHeader,
    Total,
    IngridUpsell,
  },
  props: {
    cart: {
      type: Object,
      default: null, // "your cart is empty"
    },
  },
  computed: {
    ...mapGetters({
      marketSettings: 'storyblok/marketSettings',
      languageCode: 'frontend/currentLanguageCode',
      countryCode: 'frontend/currentCountryCode',
    }),
    cartIsEmpty () {
      if (!this.cart) {
        // This also means that the cart doesn't exist. This should not be allowed for a MiniCart component
        return true
      }
      return this.cart.items.length === 0
    },
    subTitle () {
      return this.cart
        ? '(' + this.cart.items.length + ' ' + this.$t('Cart_Items') + ')'
        : ''
    },
    shippingPrice () {
      if (!this.cart.totals) {
        return
      }

      if (this.cart.totals.shippingPriceAsNumber === 0) {
        return this.$t('Cart_Shipping_Free')
      }

      return this.cart.totals.shippingPrice
    },
    paymentLogos () {
      return this.marketSettings?.data?.[0]?.paymentLogos
    },
    settings () {
      return this.marketSettings?.data[0] || null
    },
  },
  methods: {
    /**
     * Triggered when 'close' event is emitted by the ModalHeader
     */
    toggleMiniCart () {
      this.$store.dispatch('ui/toggleMiniCart')
    },
  },
}
</script>

<style lang="scss" scoped>
/**
This component is only used on landscape tablet and up, so no breakpoints for now
*/
.mini-cart {
  overflow-y: auto;
  width: 38.4rem;
  background-color: $nude-25;
  z-index: 10;
  color: $black;

  .main {
    max-height: calc(100vh - 112px);

    .cart-products {
      margin-top: 0.8rem;

      ::v-deep .cart-item {
        margin: 16px 17px;

        .top-row {
          .info .name .badge {
            margin-right: 0.8rem !important;
          }
        }
      }
    }

    // note that minicart-usp-collection is not used
    .minicart-usp-collection {
      margin-bottom: 0.8rem;
    }

    ::v-deep .cart-usp-container .cart-usp {
      padding: 1.8rem 1.6rem;

      .icon svg {
        height: 2rem;
      }

      .text p {
        font-size: 1.2rem;
      }
    }

    ::v-deep .payment-logos {
      padding: 1.6rem;

      .logos {
        display: flex;
        justify-content: space-between;

        .logo {
          height: 2.4rem;
          display: inline-block;

          img {
            height: 100%;
          }
        }
      }
    }

    .no-items {
      text-align: center;

      p {
        @include p--medium;
        padding: 3.2rem;
      }

      .continue-shopping {
        padding: 1.6rem;
        background: white;

        .button {
          width: 100%;
          margin: 0;
        }
      }
    }

    .cart-totals {
      &::v-deep {
        .button {
          margin: 0;
          min-width: unset;
        }
      }
    }

    ::v-deep .cart-usp-container {
      .cart-usp {
        margin-bottom: 0.8rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
