<template>
  <div @click="trackMenuClick">
    <mp-link
      class="collection-image"
      :to="$u(link)"
    >
      <div
        class="ratio"
        :style="backgroundImageCss"
      >
        <div class="content">
          <span>{{ label }}</span>
        </div>
      </div>
    </mp-link>
  </div>
</template>

<script>
import GtmMixin from '~/mixins/stronger-gtm'
export default {
  name: 'CollectionImage',
  mixins: [GtmMixin],
  props: {
    link: {
      type: Object,
      required: false,
      default: null
    },
    image: {
      type: Object,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    view: {
      type: String,
      required: true
    }
  },
  computed: {
    backgroundImageCss () {
      return { backgroundImage: `url("${this.image.filename}/m/fit-in/600x0/filters:quality(70)")` }
    }
  },
  methods: {
    trackMenuClick () {
      this.gtm_trackMenuClick({
        view: this.view,
        id: this.link.cached_url,
        label: this.link.cached_url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-image {
  @include aspect-ratio(1, 0.5);
  width: 100%;
  position: relative;
  border-radius: .8rem;
  overflow: hidden;
  display: block;
  cursor: pointer;
  .ratio {
    background-color: black;
    background-size: cover !important;
    background-position: center center !important;
    z-index: 0;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: white;
      bottom: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      mix-blend-mode: overlay;
      transition: all .2s ease-out;
      border-radius: .8rem;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 100%);
      bottom: 0;
      left: 0;
      transition: all .2s ease-out;
      opacity: 0.4;
    }
    .content {
      position: absolute;
      @include h--mini;
      font-size: 1.4rem;
      font-weight: 800;
      margin: 0;
      color: $white;
      left: 1.6rem;
      bottom: 1.2rem;
      z-index: 1;
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .collection-image {
    @media (hover: hover) {
      .ratio {
        &:hover {
          &:before {
            opacity: .3;
          }
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
</style>
