<template>
  <div
    class="root"
    tabindex="0"
    @keyup.esc="closeModal"
    :class="{
      'mobile-header-sticky': mainMenu.content.stickyMobile,
      'desktop-header-sticky': mainMenu.content.stickyDesktop,
    }"
    :style="cssProps"
  >
    <!-- Special banner which enables the user to exit a vip-campaign (removes the campaign cookie) -->
    <VipBanner v-show="!!vipCampaign" />
    <Header
      ref="headerBlock"
      :cart-qty="0"
      :main-menu="mainMenu.content.topLevel"
      :show-cart="false"
    >
      <Topbar
        slot="topbar"
        :instagram-text="currentMarketInfo.topbarInstagram"
        :topbar-text-items="currentMarketInfo.topbarTextItems"
        :topbar-icon="currentMarketInfo.topbarIcon"
        :topbar-icon-position="currentMarketInfo.topbarIconPosition"
        :foreground-color="currentMarketInfo.foregroundColor"
        :foreground-color-hex="currentMarketInfo.foregroundColorHex"
        :background-color="currentMarketInfo.backgroundColor"
        :background-color-hex="currentMarketInfo.backgroundColorHex"
        :shipment-time="currentMarketInfo.shipmentTime"
        :display-shipment-timer="currentMarketInfo.displayShipmentTimer"
        :enable-three-hour-countdown="
          currentMarketInfo.enableThreeHourCountdown
        "
      >
        <CountrySelector
          slot="country-selector"
          :country-code="currentCountryCode"
        />
      </Topbar>
    </Header>

    <!-- Slide in Usp popup from right -->
    <transition name="slide-in-right">
      <UspPopup v-if="uspPopupOpen" class="sidebar right" />
    </transition>

    <FreeGiftPopup v-if="freeGiftPopupOpen" :gift="gift" />

    <!-- Nuxt -->
    <nuxt />

    <!-- Tinted Overlay to be used when triggering varios popups and sidebars -->
    <transition name="fade">
      <Overlay v-if="overlayIndex > 0" :z-index="overlayIndex" />
    </transition>

    <!-- Dynamic modals are added to this container -->
    <modals-container />

    <!-- vue-notification placeholder -->
    <client-only>
      <notifications
        classes="stronger-notification"
        position="top center"
        width="100%"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FreeGiftPopup from '~/components/free-gift-popup/FreeGiftPopup'
import VipBanner from '~/components/vip-banner/VipBanner.vue'
import UspPopup from '~/components/usps/UspPopup.vue'
import CountrySelector from '~/components/country-selector/CountrySelector.vue'
import Header from '~/components/header/Header.vue'
import Topbar from '~/components/header/topbar/Topbar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Topbar,
    Header,
    CountrySelector,
    FreeGiftPopup,
    VipBanner,
    UspPopup,
  },
  data () {
    return {
      scrolledToBottom: false,
      version: null,
      versionCheckTimer: null,
      forceReloadTimer: null,
      headerHeight: 0,
      currentMarketInfo: {},
    }
  },
  computed: {
    ...mapState({
      accountEnabled: state => state.ui.accountEnabled,
      overlayIndex: state => state.ui.overlayIndex,
      freeGiftPopupOpen: state => state.ui.freeGiftPopupOpen,
      desktopSubmenuOpen: state => state.ui.desktopSubmenuOpen,
      gift: state => state.ui.gift,
      uspPopupOpen: state => state.ui.uspPopupOpen,
      searchOpen: state => state.ui.searchOpen,
    }),
    ...mapGetters({
      getMarketSettings: 'storyblok/getMarketSettings',
      getMarketByCountryCode: 'frontend/getMarketByCountryCode',
      currentCountryCode: 'frontend/currentCountryCode',
      currentLanguageCode: 'frontend/currentLanguageCode',
      vipCampaign: 'campaign/activeCampaign',
      cart: 'centra-cart/cart',
      getMenuById: 'storyblok/getMenuById',
      accountSettings: 'storyblok/accountSettings',
      isLoggedIn: 'account/isLoggedIn',
    }),
    mainMenu () {
      return this.getMenuById('main-menu')
    },
    footer () {
      return this.getMenuById('footer-menu')
    },
    cartHasGiftItem () {
      return this.cart.items?.find(item => item.totalPriceAsNumber === 0)
    },
    cssProps () {
      return {
        '--header-height': `${this.headerHeight}px`,
      }
    },
  },
  watch: {
    $route () {
      this.$store.dispatch('ui/closeAll')
    },
    freeGiftPopupOpen () {
      if (!this.freeGiftPopupOpen) {
        this.$confetti.stop()
        this.$store.dispatch('ui/hideFreeGiftPopup')
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.detectBottom)
    clearInterval(this.versionCheckTimer)
    clearTimeout(this.forceReloadTimer)
  },
  created () {
    const settings = this.getMarketSettings(
      this.currentLanguageCode,
      this.getMarketByCountryCode(this.currentCountryCode)
    )
    if (settings?.data?.[0]) {
      this.currentMarketInfo = settings.data[0]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.detectBottom, {
      capture: true,
      passive: true,
    })
    this.setVH()
    window.addEventListener('resize', this.setVH)
    // check the build version number
    this.version = this.$config.buildVersion
    this.versionCheckTimer = setInterval(this.checkVersion, 1000 * 60 * 5)
  },
  methods: {
    /**
     * Check the autogenerated build version agains current build version
     */
    checkVersion () {
      fetch('/build.json')
        .then(response => response.json())
        .then((data) => {
          if (data.version !== this.version) {
            clearInterval(this.versionCheckTimer)
            // Turns nuxt-links into defacto normal links
            // Pages refreshes on next navigation
            this.$router.beforeEach((to) => {
              window.location.href = to.fullPath
            })

            this.forceReloadTimer = setTimeout(this.forceReload, 1000 * 60 * 10)
          }
        })
    },
    forceReload () {
      location.reload()
    },
    detectBottom () {
      this.scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight
    },
    closeModal () {
      this.$store.dispatch('ui/closeAll')
    },
    // Creates a css variable for 100vh that works on ios safari
    setVH () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    getLdJsonDataScript (jsonData) {
      return {
        type: 'application/ld+json',
        json: jsonData,
      }
    },
    getRecaptchaScript () {
      return {
        src: `https://www.google.com/recaptcha/api.js?render=${this.$config.recaptchaSiteKey}`,
        async: true,
      }
    },
    getSearchActionData () {
      return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://www.strongerlabel.com',
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate:
                'https://www.strongerlabel.com/search?q={search_term}',
            },
            'query-input': 'required name=search_term',
          },
        ],
        inLanguage: 'sv-SE',
      }
    },
    getOrganizationData () {
      return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'STRONGER',
        sameAs: [
          'https://www.facebook.com/strongersweden/',
          'https://www.instagram.com/stronger/',
          'https://www.youtube.com/channel/UCOFSYlnYoiEbu0CzuxhLILw',
          'https://www.tiktok.com/@strongersweden',
          'https://www.pinterest.com/strongersweden/',
        ],
        url: 'https://www.strongerlabel.com',
        logo: {
          '@type': 'ImageObject',
          inLanguage: 'sv-SE',
          url: 'https://www.strongerlabel.com/icons/logo-static.svg',
        },
      }
    },
  },
  head () {
    const script = []
    script.push(this.getLdJsonDataScript(this.getOrganizationData()))
    script.push(this.getLdJsonDataScript(this.getSearchActionData()))
    script.push(this.getRecaptchaScript())

    return {
      script,
      meta: [
        {
          name: 'robots',
          content: 'noindex,nofollow',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.root {
  height: 100%;
  .sidebar,
  .size-selector {
    position: fixed;
    z-index: 100;
    &.left {
      top: 0;
      left: 0;
    }
    &.right {
      top: 0;
      right: 0;
    }
    &.bottom {
      bottom: 0;
      left: 0;
    }
  }
  &.mobile-header-sticky {
    ::v-deep {
      #main-content {
        position: relative;

        .sticky-toasts {
          // if mobile header is sticky, we need to position the toasts below
          top: var(--header-height);
        }
      }
      .cart {
        .mobile-header {
          top: 86px;
        }
      }
    }
    header {
      top: 0;
      position: sticky;
    }
  }
  .search-results-wrapper {
    position: absolute;
    // z-index set to lower than header (10) but higher than overlay (which is set to 8 in this use-case)
    z-index: 9;
    width: 100%;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .root {
    .search-results-wrapper {
      width: 100%;
      left: 0rem;
    }
    // reset eventual sticky-mobile styling
    &.mobile-header-sticky {
      header {
        position: relative;
      }
    }
    &.desktop-header-sticky {
      header {
        top: 0;
        position: sticky;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .root {
    .search-results-wrapper {
      width: calc(100% - 42rem);
      left: 21rem;
    }
  }
}
</style>
