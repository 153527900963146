<template>
  <div class="product-price" :class="{'product-price--row': showAsRow }">
    <div :class="[discounted ? 'product-price__price-on-sale' : 'product-price__price']">
      {{ price }}
    </div>
    <div class="product-price__price-before-discount" v-if="discounted === true">
      {{ priceBeforeDiscount }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductPrice',
  props: {
    price: {
      type: String,
      default: ''
    },
    priceBeforeDiscount: {
      type: String,
      default: ''
    },
    discounted: {
      type: Boolean,
      default: false
    },
    showAsRow: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.product-price {
  font-weight: 600;

  &--row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__price-before-discount {
    color: $grey-75;
    text-decoration: line-through;
  }

  &__price-on-sale {
    color: $poppy-red;
  }
}
</style>
