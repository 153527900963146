<template>
  <div
    ref="carticon"
    class="toggle-cart"
    @click="toggleMiniCart"
  >
    <div class="icon" title="Open cart">
      <CartIcon />
    </div>
    <client-only>
      <div
        v-if="cartQty > 0"
        class="quantity"
      >
        <span>{{ cartQty }}</span>
      </div>
    </client-only>
  </div>
</template>

<script>
import CartIcon from '~/assets/icons/cart.svg'

export default {
  name: 'ToggleCart',
  components: { CartIcon },
  props: {
    device: {
      type: String,
      default: 'mobile'
    },
    cartQty: {
      type: Number,
      required: true
    }
  },
  watch: {
    cartQty () {
      const cartIcon = this.$refs.carticon
      if (cartIcon) {
        cartIcon.classList.add('pop')
        setTimeout(() => {
          cartIcon.classList.remove('pop')
        }, 2000)
      }
    }
  },
  methods: {
    toggleMiniCart () {
      if (this.device === 'desktop') {
        this.$store.dispatch('ui/toggleMiniCart')
      } else {
        this.$router.push(this.$u('cart'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-cart {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (hover: hover) {
    cursor: pointer;
    &:hover svg {
      opacity: 0.6;
    }
  }
  .icon {
    height: 2.2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transition: opacity .2s ease-out;
      width: 100%;
      fill: white;
    }
  }
  .quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.9rem;
    height: 1.9rem;
    background: $ui-green;
    font-size: 1.2rem;
    font-weight: 600;
    color: $white;
    border-radius: 100%;
    position: absolute;
    right: .2rem;
    top: .4rem;
  }
  &.pop {
    .quantity {
      animation: blink .6s both;
    }
  }
  @keyframes blink {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }
}
</style>
