import { each } from 'underscore'
import accountService from '../../services/made-people-backend/account'

export default ({ $backendApi, store }, inject) => {
  const context = {
    getCountryCode: () => store.getters['frontend/currentCountryCode'],
    getCustomerToken: () => store.getters['account/token'],
    getCurrentCaptchaToken: () => store.getters['captcha/token'],
  }

  const methods = {
    ...accountService($backendApi, context)
  }

  const injector = method => inject(method, methods[method])
  each(Object.keys(methods), injector)
}
