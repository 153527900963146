<template>
  <div>
    <div
      v-if="trustboxData"
      class="trustpilot-widget"
      :data-locale="languageCode + '-' + countryCode"
      :data-template-id="trustboxData.templateId"
      :data-businessunit-id="trustboxData.businessUnitId"
      :data-style-height="trustboxData.styleHeight"
      data-style-width="100%"
      :data-theme="trustboxData.theme"
      :data-sku="skuString"
      :data-review-languages="languageCode"
      data-no-reviews="hide"
      data-stars="4,5"
    >
      <a
        href="https://www.trustpilot.com/review/strongerlabel.tp-testing.com"
        target="_blank"
        rel="noopener"
      >Trustpilot</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Trustpilot',
  props: {
    height: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    businessUnitId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    skuString () {
      if (!this.product || !this.product.items) {
        return ''
      }
      return Object.values(this.product.items).map(item => this.product.productSku + '-' + item.name).join(',')
    },
    trustboxData () {
      return this.data || {
        templateId: this.type,
        styleHeight: this.height,
        theme: this.theme,
        businessUnitId: this.businessUnitId,
      }
    },
    ...mapGetters({
      languageCode: 'frontend/currentLanguageCode',
      countryCode: 'frontend/currentCountryCode',
    }),
  },
  mounted () {
    this.loadTrustpilotScript()
  },
  methods: {
    loadTrustpilotScript () {
      if (document.getElementById('trustpilot-script')) {
        document.getElementById('trustpilot-script').remove()
      }
      const script = document.createElement('script')
      script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      script.async = true
      script.setAttribute('id', 'trustpilot-script')
      script.onload = this.initializeTrustpilotWidgets
      document.head.appendChild(script)
    },
    initializeTrustpilotWidgets () {
      const elements = document.getElementsByClassName('trustpilot-widget')
      if (window.Trustpilot && typeof window.Trustpilot.loadFromElement === 'function') {
        for (let i = 0; i < elements.length; i++) {
          window.Trustpilot.loadFromElement(elements[i])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.trustpilot-widget {
  margin: auto;
  padding: 4rem 0;
}
</style>
