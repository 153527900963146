<template>
  <div
    v-if="logos"
    class="footer-logos"
  >
    <span
      v-for="(logo, index) in logos"
      :key="index"
      class="logo"
    >
      <img
        :src="logo.image.filename"
        :alt="logo.image.alt ? logo.image.alt : ''"
        loading="lazy"
      >
    </span>
  </div>
</template>

<script>
export default {
  name: 'FooterLogos',
  props: {
    logos: {
      type: Array,
      required: false,
      default () {}
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-logos {
  background-color: $night-75;
  padding: 1.6rem 0;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 25%;
  .logo {
    height: 2.4rem;
    width: auto;
    margin: .8rem 1.6rem;
    display: inline-block;
    img {
      height: 100%;
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .footer-logos {
    justify-content: flex-start;
    .logo {
      margin: .8rem 4rem .8rem 0;
    }
  }
}
</style>
