<template>
  <div class="usp-popup">
    <div class="usp-popup__wrapper">
      <ModalHeader
        :title="usp.uspText"
        title-position="center"
        icon-position="left"
        @close="hideModal"
      />
      <div class="usp-popup__content" v-html="br2p(usp.uspModalContent.html ? usp.uspModalContent.html : '')" />
      <UspLogos v-if="usp && usp.logos" :logos="usp.logos" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UspLogos from './UspLogos.vue'
import ModalHeader from '~/components/modal/modal-header/ModalHeader'
import { br2p } from '~/lib/helpers'

export default {
  name: 'UspPopup',
  components: {
    ModalHeader,
    UspLogos
  },
  computed: {
    ...mapState({
      usp: state => state.ui.usp
    }),
  },
  methods: {
    hideModal () {
      this.$store.dispatch('ui/hideUspPopup')
    },
    br2p
  }
}
</script>

<style lang="scss" scoped>
.usp-popup {
  height: 100vh;
  width: 40rem;
  max-width: 100%;
  background: $white;
  overflow: auto;

  &__content {
    padding: 1.6rem 2.4rem;
  }
  /* Used to style the content for the usp popup from storyblok */
  ::v-deep {
    font-size: 1.4rem;

    h2 {
      font-size: 1.4rem;
      font-weight: 600;
      margin: 1.2rem 0 0.7rem;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    table,
    th,
    td {
      border: 1px solid $sand;
      text-align: left;
    }

    th,
    td {
      padding: 1rem;
      font-size: 1.2rem;
      width: 33%;
      line-height: 1.8rem;
    }

    td:first-child,
    th {
      font-weight: 600;
    }
  }
}
</style>
