import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _af336176 = () => interopDefault(import('../pages/_context/blank.vue' /* webpackChunkName: "pages/_context/blank" */))
const _1b5d393f = () => interopDefault(import('../pages/_context/cart.vue' /* webpackChunkName: "pages/_context/cart" */))
const _52cff3a8 = () => interopDefault(import('../pages/_context/checkout/index.vue' /* webpackChunkName: "pages/_context/checkout/index" */))
const _c26fe854 = () => interopDefault(import('../pages/_context/hero-league/index.vue' /* webpackChunkName: "pages/_context/hero-league/index" */))
const _4084ca4c = () => interopDefault(import('../pages/_context/login.vue' /* webpackChunkName: "pages/_context/login" */))
const _a04f507c = () => interopDefault(import('../pages/_context/register.vue' /* webpackChunkName: "pages/_context/register" */))
const _b0777990 = () => interopDefault(import('../pages/_context/request-password.vue' /* webpackChunkName: "pages/_context/request-password" */))
const _4295b390 = () => interopDefault(import('../pages/_context/reset-password.vue' /* webpackChunkName: "pages/_context/reset-password" */))
const _899f0a92 = () => interopDefault(import('../pages/_context/checkout/result.vue' /* webpackChunkName: "pages/_context/checkout/result" */))
const _0965491c = () => interopDefault(import('../pages/_context/hero-league/change-password.vue' /* webpackChunkName: "pages/_context/hero-league/change-password" */))
const _faa21b74 = () => interopDefault(import('../pages/_context/hero-league/details.vue' /* webpackChunkName: "pages/_context/hero-league/details" */))
const _280594d4 = () => interopDefault(import('../pages/_context/hero-league/orders/index.vue' /* webpackChunkName: "pages/_context/hero-league/orders/index" */))
const _b76bfe72 = () => interopDefault(import('../pages/_context/hero-league/returns.vue' /* webpackChunkName: "pages/_context/hero-league/returns" */))
const _1f49c9c0 = () => interopDefault(import('../pages/_context/hero-league/terms-and-conditions.vue' /* webpackChunkName: "pages/_context/hero-league/terms-and-conditions" */))
const _1594899c = () => interopDefault(import('../pages/_context/hero-league/update-address.vue' /* webpackChunkName: "pages/_context/hero-league/update-address" */))
const _18f95594 = () => interopDefault(import('../pages/_context/hero-league/update-info.vue' /* webpackChunkName: "pages/_context/hero-league/update-info" */))
const _7ad6d6b8 = () => interopDefault(import('../pages/_context/hero-league/orders/_orderId.vue' /* webpackChunkName: "pages/_context/hero-league/orders/_orderId" */))
const _7c7fb7a1 = () => interopDefault(import('../pages/_context/add/_productSku/_size.vue' /* webpackChunkName: "pages/_context/add/_productSku/_size" */))
const _4002703f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:context?/blank",
    component: _af336176,
    name: "context-blank"
  }, {
    path: "/:context?/cart",
    component: _1b5d393f,
    name: "context-cart"
  }, {
    path: "/:context?/checkout",
    component: _52cff3a8,
    name: "context-checkout"
  }, {
    path: "/:context?/hero-league",
    component: _c26fe854,
    name: "context-hero-league"
  }, {
    path: "/:context?/login",
    component: _4084ca4c,
    name: "context-login"
  }, {
    path: "/:context?/register",
    component: _a04f507c,
    name: "context-register"
  }, {
    path: "/:context?/request-password",
    component: _b0777990,
    name: "context-request-password"
  }, {
    path: "/:context?/reset-password",
    component: _4295b390,
    name: "context-reset-password"
  }, {
    path: "/:context?/checkout/result",
    component: _899f0a92,
    name: "context-checkout-result"
  }, {
    path: "/:context?/hero-league/change-password",
    component: _0965491c,
    name: "context-hero-league-change-password"
  }, {
    path: "/:context?/hero-league/details",
    component: _faa21b74,
    name: "context-hero-league-details"
  }, {
    path: "/:context?/hero-league/orders",
    component: _280594d4,
    name: "context-hero-league-orders"
  }, {
    path: "/:context?/hero-league/returns",
    component: _b76bfe72,
    name: "context-hero-league-returns"
  }, {
    path: "/:context?/hero-league/terms-and-conditions",
    component: _1f49c9c0,
    name: "context-hero-league-terms-and-conditions"
  }, {
    path: "/:context?/hero-league/update-address",
    component: _1594899c,
    name: "context-hero-league-update-address"
  }, {
    path: "/:context?/hero-league/update-info",
    component: _18f95594,
    name: "context-hero-league-update-info"
  }, {
    path: "/:context?/hero-league/orders/:orderId",
    component: _7ad6d6b8,
    name: "context-hero-league-orders-orderId"
  }, {
    path: "/:context?/add/:productSku?/:size?",
    component: _7c7fb7a1,
    name: "context-add-productSku-size"
  }, {
    path: "/*",
    component: _4002703f,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
