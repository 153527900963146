<template>
  <client-only>
    <DefaultLayout />
  </client-only>
</template>

<script>
import { mapState } from 'vuex'
import DefaultLayout from '~/layouts/default'

export default {
  name: 'HeroLeague',
  components: {
    DefaultLayout,
  },
  ...mapState({
    pageReferrer: state => state.ui.pageReferrer,
  }),
  mounted () {
    if (this.pageReferrer !== '') {
      this.$store.dispatch('ui/incrementPageVisits')
    }
  },
}
</script>
