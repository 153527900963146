<template>
  <div class="countdown">
    &nbsp;{{ displayTime }}
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { setupDayjs } from '~/plugins/dayjs'
const dayjsInstance = setupDayjs(dayjs)

export default {
  name: 'CountdownTimer',
  props: {
    shipmentTime: {
      type: String,
      required: true
    },
    enableThreeHourCountdown: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      interval: null,
      displayTime: '',
      nextShipmentTime: ''
    }
  },
  mounted () {
    this.nextShipmentTime = this.initNextShipmentTime()
    this.interval = setInterval(this.setTimeleft, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    setTimeleft () {
      let shipping = dayjsInstance().set('hour', parseInt(this.nextShipmentTime)).set('minute', 0).set('second', 0)
      if (shipping.isBefore(dayjsInstance())) {
        shipping = shipping.add(1, 'day')
      }
      const nowCET = dayjsInstance().tz('Europe/Stockholm')
      const timeLeft = dayjsInstance.duration(shipping.diff(nowCET))
      const h = timeLeft.hours() === 0 ? '00' : timeLeft.hours() < 10 ? '0' + timeLeft.hours() : timeLeft.hours()
      const m = timeLeft.minutes() === 0 ? '00' : timeLeft.minutes() < 10 ? '0' + timeLeft.minutes() : timeLeft.minutes()
      const s = timeLeft.seconds() === 0 ? '00' : timeLeft.seconds() < 10 ? '0' + timeLeft.seconds() : timeLeft.seconds()
      this.displayTime = `${h}:${m}:${s}`
    },
    initNextShipmentTime () {
      if (this.enableThreeHourCountdown) {
        const nowCET = dayjsInstance().tz('Europe/Stockholm')
        const currentHour = parseInt(nowCET.$H)

        switch (true) {
          case (currentHour < 3):
            return 3
          case (currentHour < 6):
            return 6
          case (currentHour < 9):
            return 9
          case (currentHour < 12):
            return 12
          case (currentHour < 15):
            return 15
          case (currentHour < 18):
            return 18
          case (currentHour < 21):
            return 21
          case (currentHour < 24):
            return 0
        }
      } else {
        return this.shipmentTime
      }
    }
  }
}
</script>
