
export default {
  namespaced: true,
  state () {
    return {
      accountEnabled: false,
      displayAccountIcon: false,
      mobileSidebarOpen: false,
      desktopSubmenuOpen: false,
      miniCartOpen: false,
      searchAutocompleteResult: null,
      countrySelectorOpen: false,
      enableDiscountFilter: false,
      pageVisits: 0,
      totalPageVisits: 0,
      currentQueryName: null,
      selectedProductID: '',
      hotspotProductsOpen: false,
      sizeSelectorOpen: false,
      sizeDepictSelectorOpen: false,
      productRestockSidebarOpen: false,
      freeGiftPopupOpen: false,
      uspPopupOpen: false,
      pageReferrer: '',
      listName: '',
      listId: '',

      /**
       * 'Notify me when back in stock' messages
       * They shall all be disabled for now
       */
      notifyMeEnabled: true,
      // Selected item used for restock
      itemId: null,
      restockItems: null,

      /**
       * Overlay
       * Hidden if set to 0
       * The header has a z-index of 10, so set this to either
       * above or below 10 depending on use case
       */
      overlayIndex: 0,
      disableUi: false, // disables ui in checkout to wait for api:s to finish
      bundleProduct: undefined,
      currentBundleSectionID: null,
      selectedDepictProduct: undefined,
      showGwpMessage: true,
      topbarPanelOpen: false,
    }
  },
  mutations: {
    setAccountIconVisibility (state, payload) {
      state.displayAccountIcon = payload
    },
    setAccountEnabled (state, payload) {
      state.accountEnabled = payload
    },

    // Soft Conversion
    incrementPageVisits (state) {
      state.pageVisits++
      state.totalPageVisits++
    },

    resetPageVisits (state) {
      state.pageVisits = 0
    },
    setPageReferrer (state, url) {
      state.pageReferrer = url
    },
    setActiveList (state, { listName, listId }) {
      state.listName = listName
      state.listId = listId
    },
    /**
     * Mobile Sidebar
     */
    showMobileSidebar (state) {
      // Close everything else
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.hotspotProductsOpen = null
      state.productRestockSidebarOpen = false
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Open sidebar
      state.mobileSidebarOpen = true
      state.overlayIndex = 11
    },
    hideMobileSidebar (state) {
      state.mobileSidebarOpen = false
      state.overlayIndex = 0
    },

    /**
     * Desktop Submenu
     */
    showDesktopSubmenu (state) {
      // Close everything else
      state.countrySelectorOpen = false
      state.mobileSidebarOpen = false
      state.miniCartOpen = false
      state.hotspotProductsOpen = null
      state.productRestockSidebarOpen = false
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Open submenu
      state.desktopSubmenuOpen = true
      state.overlayIndex = 9
    },
    hideDesktopSubmenu (state) {
      state.desktopSubmenuOpen = false
      state.overlayIndex = 0
    },

    /**
     * Minicart
     */
    showMiniCart (state) {
      // Close everything else
      state.countrySelectorOpen = false
      state.mobileSidebarOpen = false
      state.desktopSubmenuOpen = false
      state.hotspotProductsOpen = null
      state.productRestockSidebarOpen = false
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Open minicart
      state.miniCartOpen = true
      state.overlayIndex = 9
    },
    hideMiniCart (state) {
      state.miniCartOpen = false
      state.overlayIndex = 0
    },
    /**
     * Country Selector
     */
    showCountrySelector (state) {
      // Close everything else
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.desktopSubmenuOpen = false
      state.hotspotProductsOpen = null
      state.productRestockSidebarOpen = false
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Open country selector
      state.countrySelectorOpen = true
      state.overlayIndex = 11
    },
    hideCountrySelector (state) {
      state.countrySelectorOpen = false
      state.overlayIndex = 0
    },
    /**
     * Hotspot Products Popup
     */
    showHotspotProducts (state, payload) {
      state.currentQueryName = payload.queryName

      // Close everything else
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.productRestockSidebarOpen = false
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false

      // Open products
      state.hotspotProductsOpen = {
        popupType: payload.popupType,
        popupHeadline: payload.popupHeadline,
        popupAlign: payload.popupAlign,
        popupColor: payload.popupColor,
        productSkus: payload.productSkus,
      }
      state.overlayIndex = 11
    },
    hideHotspotProducts (state) {
      state.hotspotProductsOpen = null
      state.overlayIndex = 0
    },

    /**
     * Size Selector Popup
     */
    showSizeSelector (state, { productID, bundleProduct, sectionID }) {
      state.selectedProductID = productID
      state.bundleProduct = bundleProduct
      state.currentBundleSectionID = sectionID
      // Close everything else
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.hotspotProductsOpen = null
      state.productRestockSidebarOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Open Size Selector
      state.sizeSelectorOpen = true
      state.overlayIndex = 11
    },

    showDepictSizeSelector (state, { product }) {
      // Add depcit product to state
      state.selectedDepictProduct = product
      // Close everything else
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.hotspotProductsOpen = null
      state.productRestockSidebarOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Open Size Selector
      state.sizeDepictSelectorOpen = true
      state.overlayIndex = 11
    },
    hideSizeSelector (state) {
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.selectedProductID = ''
      state.overlayIndex = 0
    },

    /**
     * Product Restock Sidebar.
     * The sidebar only requires the list of all items (sizes) of a Product.
     * If itemId is available it will be pre-selected.
     */
    showProductRestockSidebar (state, { items, itemId }) {
      // We can use this here as well
      // state.currentQueryName = queryName
      state.itemId = itemId
      state.restockItems = items

      // Close everything else
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.hotspotProductsOpen = null
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Open product restock
      state.productRestockSidebarOpen = true
      state.overlayIndex = 11
    },
    hideProductRestockSidebar (state) {
      state.productRestockSidebarOpen = false
      state.overlayIndex = 0
      state.itemId = null
      state.restockItems = null
    },

    showFreeGiftPopup (state, { gift }) {
      // Close everything else
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.hotspotProductsOpen = null
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.productRestockSidebarOpen = false
      state.uspPopupOpen = false
      state.topbarPanelOpen = false
      // Show free gift popup
      state.freeGiftPopupOpen = true
      state.gift = gift
    },
    hideFreeGiftPopup (state) {
      state.freeGiftPopupOpen = false
      state.overlayIndex = 0
      state.gift = null
    },

    showUspPopup (state, { usp }) {
      // Close everything else
      state.countrySelectorOpen = false
      state.desktopSubmenuOpen = false
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.mobileProductFilterOpen = false
      state.hotspotProductsOpen = null
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.productRestockSidebarOpen = false
      state.freeGiftPopupOpen = false
      state.topbarPanelOpen = false

      state.overlayIndex = 11
      state.uspPopupOpen = true
      state.usp = usp
    },
    hideUspPopup (state) {
      state.uspPopupOpen = false
      state.overlayIndex = 0
      state.usp = null
    },

    /**
     * Close All
     */
    closeAll (state) {
      state.miniCartOpen = false
      state.mobileSidebarOpen = false
      state.desktopSubmenuOpen = false
      state.countrySelectorOpen = false
      state.hotspotProductsOpen = null
      state.sizeSelectorOpen = false
      state.sizeDepictSelectorOpen = false
      state.productRestockSidebarOpen = false
      state.freeGiftPopupOpen = false
      state.uspPopupOpen = false
      state.overlayIndex = 0
      state.topbarPanelOpen = false
    },

    searchAutocompleteResult (state, result) {
      state.searchAutocompleteResult = result
    },
    disableUi (state, payload) {
      state.disableUi = payload
    },
    showGwpMessage (state) {
      state.showGwpMessage = true
    },
    hideGwpMessage (state) {
      state.showGwpMessage = false
    },
    showTopbarPanel (state) {
      state.topbarPanelOpen = true
      state.overlayIndex = 10
    },
    hideTopbarPanel (state) {
      state.topbarPanelOpen = false
      state.overlayIndex = 0
    },
  },
  actions: {
    setAccountIconVisibility ({ commit }, payload) {
      commit('setAccountIconVisibility', payload)
    },
    setAccountEnabled ({ commit }, payload) {
      commit('setAccountEnabled', payload)
    },
    disableUi ({ commit }, payload) {
      commit('disableUi', payload)
    },
    setSearchAutocompleteResult ({ commit }, result) {
      commit('searchAutocompleteResult', result)
    },
    showMobileSidebar ({ commit }) {
      commit('showMobileSidebar')
    },
    incrementPageVisits ({ commit }) {
      commit('incrementPageVisits')
    },
    resetPageVisits ({ commit }) {
      commit('resetPageVisits')
    },
    setPageReferrer ({ commit }, url) {
      commit('setPageReferrer', url)
    },
    setActiveList ({ commit }, { listName, listId }) {
      commit('setActiveList', { listName, listId })
    },
    hideMobileSidebar ({ commit }) {
      commit('hideMobileSidebar')
    },
    showDesktopSubmenu ({ commit }) {
      commit('showDesktopSubmenu')
    },
    hideDesktopSubmenu ({ commit, state }) {
      if (state.desktopSubmenuOpen) {
        commit('hideDesktopSubmenu')
      }
    },
    toggleMiniCart ({ commit, state }) {
      if (!state.miniCartOpen) {
        commit('showMiniCart')
      } else {
        commit('hideMiniCart')
      }
    },
    hideMiniCart ({ commit }) {
      commit('hideMiniCart')
    },
    showCountrySelector ({ commit }) {
      commit('showCountrySelector')
    },
    hideCountrySelector ({ commit }) {
      commit('hideCountrySelector')
    },
    showHotspotProducts ({ commit }, payload) {
      commit('showHotspotProducts', payload)
    },
    hideHotspotProducts ({ commit }) {
      commit('hideHotspotProducts')
    },
    showSizeSelector ({ commit }, payload) {
      commit('showSizeSelector', payload)
    },
    showDepictSizeSelector ({ commit }, payload) {
      commit('showDepictSizeSelector', payload)
    },
    hideSizeSelector ({ commit }) {
      commit('hideSizeSelector')
    },
    showProductRestockSidebar ({ commit }, payload) {
      commit('showProductRestockSidebar', {
        items: payload.items,
        itemId: payload.itemId,
      })
    },
    hideProductRestockSidebar ({ commit }) {
      commit('hideProductRestockSidebar')
    },
    showFreeGiftPopup ({ commit }, payload) {
      commit('showFreeGiftPopup', payload)
    },
    hideFreeGiftPopup ({ commit }) {
      commit('hideFreeGiftPopup')
    },
    showUspPopup ({ commit }, payload) {
      commit('showUspPopup', payload)
    },
    hideUspPopup ({ commit }) {
      commit('hideUspPopup')
    },
    closeAll ({ commit }) {
      commit('closeAll')
    },
    showGwpMessage ({ commit }) {
      commit('showGwpMessage')
    },
    hideGwpMessage ({ commit }) {
      commit('hideGwpMessage')
    },
    showTopbarPanel ({ commit }) {
      commit('showTopbarPanel')
    },
    hideTopbarPanel ({ commit }) {
      commit('hideTopbarPanel')
    },
  },
}
