var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header",class:[
    'icon--' + _vm.iconPosition,
    'title--' + _vm.titlePosition,
    {
      lowercase: !_vm.uppercase
    }
  ]},[(_vm.displayIcon)?_c('span',{staticClass:"icon",class:'icon--' + _vm.iconSize,on:{"click":_vm.close}},[(_vm.customIconPassed)?_vm._t("icon"):_c('CrossIcon')],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"title"},[_c('span',{staticClass:"main",style:({color: _vm.popupColor})},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"sub"},[_vm._v("\n      "+_vm._s(_vm.subtitle)+"\n    ")]),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }