<template>
  <div
    v-if="eyebrow || headline.html"
    class="headline-wrapper"
    :style="[styleProps, fontStyleProps]"
    :class="styleModifier"
  >
    <div v-if="eyebrow" class="eyebrow">
      {{ eyebrow }}
    </div>
    <Component
      v-if="headline.html"
      :is="headlineType"
      v-html="nl2br(headline.html)"
      class="headline"
    />
  </div>
</template>

<script>
import ColorHexCode from '~/mixins/ColorHexCode'

export default {
  name: 'HeroHeadline',
  mixins: [ColorHexCode],
  props: {
    isInsideGrid: {
      type: Boolean,
      required: false,
      default: false,
    },
    headline: {
      type: Object,
      required: true,
    },
    headlineType: {
      type: String,
      default: 'h2',
    },
    headlineTextColor: {
      type: String,
      default: 'white',
    },
    headlineTextColorHex: {
      type: String,
      default: '#FFFFFF',
    },
    fontWeight: {
      type: String,
      default: '400',
    },
    styleModifier: {
      type: Array,
      required: false,
      default: () => [],
    },
    mobileDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    desktopDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    eyebrow: {
      type: String,
      required: false,
      default: '',
    },
    eyebrowTextColor: {
      type: String,
      default: 'white',
    },
    eyebrowTextColorHex: {
      type: String,
      default: '#FFFFFF',
    },
  },
  computed: {
    styleProps () {
      return {
        '--headline-text-color': this.color(
          this.headlineTextColor,
          this.headlineTextColorHex
        ),
        '--eyebrow-text-color': this.color(
          this.eyebrowTextColor,
          this.eyebrowTextColorHex
        ),
        '--font-weight': this.fontWeight,
      }
    },

    fontStyleProps () {
      return this.isInsideGrid
        ? {
          '--headline-font-size-mobile': 'max(2.5rem, min(4.5vw, 4.2rem))',
          '--headline-font-size-desktop': 'max(4.2rem, min(4vw, 5rem))',
          '--headline-font-size-custom': '5rem',
          '--eyebrow-font-size-mobile': '1.2rem',
          '--eyebrow-font-size-desktop': '1.6rem',
          '--eyebrow-font-size-custom': '1.9rem',
          '--eyebrow-margin': '0.9rem',
          '--letter-spacing-mobile': '-0.75px',
          '--letter-spacing-desktop': '-1.26px',
        }
        : {
          '--headline-font-size-mobile': '5rem',
          '--headline-font-size-desktop': 'max(5rem, min(6vw, 7rem))',
          '--headline-font-size-custom': 'max(7rem, 5.4vw)',
          '--eyebrow-font-size-mobile': 'max(1.667rem, min(2.5vw, 1.9rem))',
          '--eyebrow-font-size-desktop': 'max(1.9rem, min(2.5vw, 3rem))',
          '--eyebrow-font-size-custom': 'max(3rem, 2vw)',
          '--eyebrow-margin': '1.1rem',
          '--letter-spacing-mobile': '-1.5px',
          '--letter-spacing-desktop': '-2.1px',
        }
    },
  },
  methods: {
    nl2br (str) {
      str = str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.replace(/^\s*<br\s*\/?>|<br\s*\/?>\s*$/g, '')
    },
  },
}
</script>

<style lang="scss" scoped>
.headline-wrapper {
  display: flex;
  flex-direction: column;
  font-family: $font-base-new;
  color: var(--headline-text-color);
}

.eyebrow {
  font-size: var(--eyebrow-font-size-mobile);
  font-weight: 400;
  line-height: 80%;
  margin-bottom: 0.6rem;
  color: var(--eyebrow-text-color);
}

.headline {
  line-height: 80%;
  letter-spacing: var(--letter-spacing-mobile);
  font-weight: var(--font-weight);
  font-size: var(--headline-font-size-mobile);
}

.uppercase {
  text-transform: uppercase;
}

.neon-text {
  font-style: normal;
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fae1f2;
  -webkit-font-smoothing: antialiased;
  filter: drop-shadow(0 0 2px #fae1f2) drop-shadow(0 0 1px #e35bce)
    drop-shadow(0 0 3px #e35bce);
}

@media (min-width: $tablet) {
  .eyebrow {
    font-size: var(--eyebrow-font-size-desktop);
    margin-bottom: var(--eyebrow-margin);
    letter-spacing: 0.3px;
  }

  .headline {
    font-size: var(--headline-font-size-desktop);
    letter-spacing: var(--letter-spacing-desktop);
  }
}

@media (min-width: $pdp-custom-breakpoint) {
  .eyebrow {
    font-size: var(--eyebrow-font-size-custom);
  }

  .headline {
    font-size: var(--headline-font-size-custom);
  }
}
</style>
