<template>
  <div
    v-if="visible === '1' && text"
    :style="colorVariables"
    :class="position"
    class="ribbon"
  >
    <div class="bg">
      <RibbonSvg />
    </div>
    <div class="label">
      {{ text }}
    </div>
  </div>
</template>

<script>
import ColorHexCode from '~/mixins/ColorHexCode.js'
import RibbonSvg from '~/assets/icons/ribbon.svg'

export default {
  name: 'Ribbon',
  components: {
    RibbonSvg
  },
  mixins: [ColorHexCode],
  props: {
    visible: {
      type: String,
      required: false,
      default: '1'
    },
    textColor: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    position: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    colorVariables () {
      return {
        '--text-color': this.textColor,
        '--background-color': this.color(this.backgroundColor, this.backgroundColorHex)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ribbon {
  position: absolute;
  padding: 0.6rem 2.2rem 0.6rem 2.8rem;
  color: var(--text-color);
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 110%;
  &.top-left,
  &.bottom-left {
    transform: rotate(-90deg) translate(-100%, 0);
    transform-origin: top left;
  }
  &.top-right,
  &.bottom-right {
    transform: rotate(-90deg) translate(0, -100%);
    transform-origin: top right;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    svg {
      height: 100%;
      fill: var(--background-color)
    }
  }
  .label {
    position: relative;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .ribbon {
    font-size: 1.2rem;
    padding: 1rem 4.9rem 0.7rem;
  }
}
</style>
