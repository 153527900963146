
import axios from 'axios'
import getDpc from '~/components/depict/utils/getDpc'

export default {
  computed: {
    currentSiteSlug () {
      return this.$nuxt.$store.getters['frontend/currentSiteLangSlug']
    },
    currentCountry () {
      return this.$nuxt.$store.getters['frontend/currentCountryCode'].toLowerCase()
    },
    currentMarketId () {
      return this.$nuxt.$store.getters['frontend/currentMarketId']
    },
    currentPricelistId () {
      return this.$nuxt.$store.getters['frontend/currentPricelistId']
    },
    activeCampaign () {
      return this.$nuxt.$store.getters['campaign/activeCampaign']
    },
    depictMerchant () {
      return this.$nuxt.$config.depictMerchant
    }
  },
  methods: {
    depict_trackAddToCart ({ sku, sizeLabel }) {
      const dpc = getDpc(this.depictMerchant, this.currentCountry)
      dpc.sendAddToCartEvent({ product_id: this.getDepictTrackingId({ sku, sizeLabel }) })
    },
    depict_trackProductView (id) {
      const dpc = getDpc(this.depictMerchant, this.currentCountry)
      dpc.setProductId(id)
    },
    depict_trackPurchase ({ items, orderId, currency }) {
      const dpc = getDpc(this.depictMerchant, this.currentCountry)
      dpc.sendPurchaseEvent({
        transaction_id: orderId,
        items: items.map(item => ({
          item_id: item._product.productSku,
          price: item.priceEachAsNumber,
          quantity: item.quantity,
        })),
        currency,
      })
    },
    getPageUrl ({ slug }) {
      return `/${this.currentSiteSlug}/${slug}`
    },
    // Depict needs the product_id from the display data to track the add to cart event, this is the productSku + sizeLabel
    getDepictTrackingId ({ sku, sizeLabel }) {
      return `${sku}-${sizeLabel}`
    },
    async fetchCampaignPrices (ids) {
      try {
        if (!ids || !ids?.length || !this.currentMarketId || !this.currentPricelistId) {
          return null
        }
        if (Array.isArray(ids)) {
          ids = ids.join(',')
        }
        const { data } = await this.$backendApi.get(`/products/prices/${ids}`)
        return data
      } catch (error) {
        console.error('Error fetching campaign prices for depict campaign sites')
        return null
      }
    },
    async getDisplays ({ displays, listId, listName, type }) {
      const getDepictGtmData = () => {
        return {
          listId,
          listName,
          type,
        }
      }

      const getBaseVariantDisplayData = (display) => {
        return {
          ...display,
          page_url: this.getPageUrl({ slug: display.slug })
        }
      }
      if (this.activeCampaign) {
        // Depict does not support campaign prices so we need to fetch campaign prices from backend.

        const productIds = [...new Set(displays.map(display => display.variant_displays.map(variantDisplay => variantDisplay.display_item_id)).flat())]
        const campaignPrices = await this.fetchCampaignPrices(productIds)

        if (campaignPrices) {
          return (
            displays.map((display) => {
              return {
                ...display,
                ...getDepictGtmData(),
                variant_displays: display.variant_displays.map((display) => {
                  const campaignPriceData = campaignPrices.find(campaignPrice => campaignPrice.id === display.display_item_id)

                  if (!campaignPriceData) {
                    return getBaseVariantDisplayData(display)
                  }
                  return {
                    ...getBaseVariantDisplayData(display),
                    // Override original and sale price with campaign prices
                    original_price: campaignPriceData.priceBeforeDiscountAsNumber,
                    sale_price: campaignPriceData.priceAsNumber,
                  }
                }),
              }
            }))
        }
      }

      return (
        displays.map((display) => {
          return {
            ...display,
            ...getDepictGtmData(),
            variant_displays: display.variant_displays.map((display) => {
              return getBaseVariantDisplayData(display)
            }),
          }
        }))
    },
    async getDepictListing (listingId) {
      try {
        const baseUrl = this.$nuxt.$config.depictApiBaseUrl
        const response = await axios.get(`${baseUrl}/listings/${listingId}?merchant=${this.$nuxt.$config.depictMerchant}&market=${this.$nuxt.$config.depictMarket}&locale=${this.$nuxt.$config.depictLocale}`)
        return response.data.title
      } catch (error) {
        console.error('Error fetching listing data:', error)
        return `error fetching listing data for ${listingId}`
      }
    },
  }
}

// eslint-disable camelcase
