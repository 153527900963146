<template>
  <div class="footer-menu">
    <div class="mobile-menu">
      <client-only>
        <FooterMenuRow
          v-for="(item, index) in menu"
          :id="index"
          :key="index"
          :item="item"
          :open="childUid"
          @clickToggle="openAccordion"
        />
      </client-only>
    </div>
    <div class="desktop-menu">
      <div
        v-for="(link, index) in menu"
        :key="'menu-column-' + index"
        class="column"
      >
        <div class="label">
          <span>{{ link.label }}</span>
        </div>
        <div
          v-for="(child, indexKey) in link.menuItems"
          :key="'child-' + indexKey"
          class="link"
        >
          <img
            v-if="child.icon.filename"
            :src="child.icon.filename"
            :alt="child.label"
            loading="lazy"
          >
          <mp-link
            :to="$u(child.link)"
            :target="child.targetBlank ? '_blank' : '_self'"
            :rel="child.targetBlank ? 'noreferrer nofollow' : false"
          >
            {{ child.label }}
          </mp-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterMenuRow from '~/components/footer-menu/FooterMenuRow'

export default {
  name: 'FooterMenu',
  components: {
    FooterMenuRow
  },
  props: {
    menu: {
      type: Array,
      required: false,
      default () {}
    }
  },
  data () {
    return {
      // This value is set to the value emitted by the child
      childUid: ''
    }
  },
  methods: {
    // Triggered when 'clickToggle' event is emitted by the child
    openAccordion (value) {
      this.childUid = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-menu {
    background-color: $night-75;
    color: $white;
    padding: 1.6rem 0;
    .desktop-menu {
      display: none;
    }
  }

  // Tablet (Landscape)
  @media screen and (min-width: $tablet-landscape) {
    .footer-menu {
      padding: 0;
      .mobile-menu {
        display: none;
      }
      .desktop-menu {
        display: flex;
        //justify-content: space-between;
        .column {
          //min-width: 20rem;
          //margin-right: 12rem;
          width: 25%;
          flex-grow: 1;
          flex-shrink: 1;
          &:last-of-type {
            width: 5%;
          }
          .label {
            @include h--medium;
            font-size: 1.6rem !important;
            font-weight: bold;
            margin: 0;
            padding-bottom: 2.4rem;
          }
          .link {
            display: flex;
            font-size: 1.6rem;
            line-height: 180%;
            font-weight: 500;
            margin-bottom: 0.6rem;
            a {
              border-bottom: 1px solid transparent;
              @media (hover: hover) {
                &:hover {
                  border-color: white;
                }
              }
            }
            img {
              margin-right: .8rem;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  // Laptop
  @media screen and (min-width: $laptop) {
    .footer-menu {
      .desktop-menu {
        .column {
          min-width: 20rem;
          margin-right: 1.6rem;
          .label {
          }
          .link {
            img {
            }
            &:last-child {
            }
          }
          &:last-child {
          }
        }
      }
    }
  }
</style>
