import axios from 'axios'

export default function (app, store, marketGetter, countryGetter, languageGetter, campaignGetter) {
  const backendApiUrl = app.$config.backendApiUrl

  const client = axios.create({
    timeout: 30000,
    baseURL: backendApiUrl,
    withCredentials: true
  })

  // Send getters on every request
  client.interceptors.request.use((request) => {
    request.params = request.params || {}
    request.params.market = marketGetter()
    request.params.country = countryGetter()
    request.params.language = languageGetter()
    request.params.campaign = campaignGetter()
    return request
  }, error => Promise.reject(error))

  return client
};
