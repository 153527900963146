<template>
  <div class="change-color">
    <span v-if="showText" class="change-color-title">{{ $t('Change_Color') }}</span>
    <div class="colors">
      <button
        v-for="color in colorSwatches"
        :key="color.uri"
        class="color-option"
        @click="handleChange(color)"
        :class="[
          color.isActive && 'selected',
        ]"
        :style="[getBackgroundColor(color)]"
        :disabled="color.isActive"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorSwatches',
  props: {
    colorSwatches: {
      type: Array,
      required: true
    },
    showText: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getBackgroundColor (color) {
      return { 'background-image': `url(${color.image?.url})` }
    },
    handleChange (color) {
      this.$emit('handleColorChange', color)
    },
  }
}
</script>

<style lang="scss" scoped>
    .change-color {
    padding-top: 0.8rem;
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .change-color-title {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 140%;
      margin-bottom: 1.2rem;
      display: block;
    }
    .colors {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.2rem;
      gap: 0.8rem;
      .color-option {
        @include button-reset;

        position: relative;
        border-radius: 100%;
        width: 1.6rem;
        height: 1.6rem;

        &:after {
        content: '';
        display: block;
        position: absolute;
        top: -0.3rem;
        left: -0.3rem;
        width: calc(100% + 0.6rem);
        height: calc(100% + 0.6rem);
        border-radius: 100%;
        border: 1px solid $black;
        opacity: 0;
        pointer-events: none;
      }

      &.selected {
        cursor: default;
        &:after {
          opacity: 1;
        }
      }
      }
    }
  }
</style>
