<template>
  <div class="size-selector" :class="{ loading: loading }">
    <div class="size-wrapper">
      <ModalHeader
        title-position="center"
        icon-position="right"
        @close="hideSizeSelector"
      >
        <div class="size-guide" @click="showSizeGuide(product.sizeguide)">
          <span class="icon">
            <SizeGuideIcon />
          </span>
          <span class="label">
            {{ $t('Global_Size_Guide') }}
          </span>
        </div>
      </ModalHeader>
      <div
        v-for="(size, index) in sizes"
        :key="'size-' + index"
        class="size"
        :class="{ 'adding-to-cart': loading === size }"
        @click="!loading ? selectSize(size) : null"
      >
        <SizeSelectorItem
          :size="size"
          :product-terminated="product.terminated"
          :loading="loading"
        />
      </div>
      <div class="ctas" v-if="!bundleProduct">
        <div class="name-and-prices">
          <div class="name">
            {{ product.name }}
          </div>
          <ProductPrice
            :discounted="product.discounted"
            :price="product.price"
            :price-before-discount="product.priceBeforeDiscount"
            :show-as-row="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ModalHeader from '~/components/modal/modal-header/ModalHeader.vue'
import SizeGuideIcon from '~/assets/icons/hanger.svg'
import AllowAddToCart from '~/mixins/AllowAddToCart.js'
import Depict from '~/mixins/depict.js'
import ProductPrice from '~/components/product/product-price/ProductPrice.vue'
import SizeSelectorItem from '~/components/size-selector/SizeSelectorItem.vue'

let SizeModal

export default {
  name: 'SizeSelector',
  components: {
    SizeGuideIcon,
    ModalHeader,
    ProductPrice,
    SizeSelectorItem,
  },
  mixins: [AllowAddToCart, Depict],
  props: {
    productId: {
      type: String,
      default: '',
    },
    bundleProduct: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      loading: null,
    }
  },
  computed: {
    product () {
      return !this.bundleProduct
        ? this.transformProduct(
          this.$store.getters['centra-product/getProductById'](this.productId),
        )
        : this.bundleProduct
    },
    ...mapState({
      notifyMeEnabled: state => state.ui.notifyMeEnabled,
      freeGiftPopupOpen: state => state.ui.freeGiftPopupOpen,
      currentBundleSectionID: state => state.ui.currentBundleSectionID,
    }),
    ...mapGetters({
      lineProduct: 'centra-cart/lineProduct',
    }),
    sizes () {
      return this.product.sizeOptions
    },
  },
  methods: {
    hideSizeSelector () {
      this.$store.dispatch('ui/hideSizeSelector')
    },
    showProductRestockSidebar (size) {
      this.$store.dispatch('ui/showProductRestockSidebar', {
        items: this.product.sizeOptions,
        itemId: size.value,
      })
    },
    async showSizeGuide (sizeGuideCode) {
      if (!SizeModal) {
        SizeModal = await import('../size-guide/SizeGuide.vue')
      }
      this.$modal.show(
        SizeModal.default,
        {
          modal: 1,
          sizeGuideCode,
        },
        {
          adaptive: true,
          height: 'auto',
          scrollable: true,
          classes: ['mobile-size-guide'],
        }
      )
    },
    selectSize (size) {
      if (this.bundleProduct) {
        this.$store.dispatch('bundles/selectSize', {
          sku: this.bundleProduct.productSku,
          sizeLabel: size.label,
          sectionId: this.currentBundleSectionID,
        })
        this.hideSizeSelector()
      } else if (
        size.quantity === 0 &&
        !this.product.terminated &&
        this.notifyMeEnabled
      ) {
        this.showProductRestockSidebar(size)
      } else if (
        size.quantity > 0 &&
        this.allowAddToCart(size.value, size.quantity)
      ) {
        this.addToCart(size)
      }
    },
    addToCart (size) {
      this.loading = size

      const addedItem = {
        productName: this.product.name,
        _product: {
          productSku: this.product.sku,
          product_type: this.product.product_type,
        },
        priceEachAsNumber: this.product.priceAsNumber,
        brandName: this.product.brandName,
        size: size.label,
      }

      this.$addToCart(this.product.productSku, size.label, 1, this.product.url)
        .then((cart) => {
          this.$store.dispatch('centra-cart/setCart', cart)
          this.gtm_trackAddToCart(addedItem, 1)
          this.depict_trackAddToCart({ sku: this.product.productSku, sizeLabel: size.label })
          this.$nuxt.$emit('after_add_product_to_cart')
        })
        .catch((error) => {
          this.$notify({
            title: this.$t('Dialog_Add_To_Cart_Error_Title'),
            text: this.$t('Dialog_Add_To_Cart_Error_Message'),
            type: 'error',
          })
          console.error(error, 'size-selector', this._name, {
            ...this._data,
            ...this._props,
          })
        })
        .finally(() => {
          this.loading = null
          this.hideSizeSelector()
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.size-selector {
  bottom: 2.4rem;
  background-color: $white;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  .size-wrapper {
    .size {
      padding: 0.8rem 0;
      position: relative;
      text-align: center;

      .label {
        font-size: 1.4rem;
        font-weight: 400;
      }
      .button {
        width: 8rem;
        min-width: unset;
        margin: 0 auto;
        height: 3.2rem;
        @media (hover: hover) {
          &:hover:enabled {
            background-color: $mint-25;
            border-color: $mint-100;
          }
        }
        &:disabled {
          background-color: $grey-25;
          color: $grey-75;
          box-shadow: none;
        }
      }
      .stock-message-left,
      .stock-message-right {
        position: absolute;
        height: 100%;
        width: calc((100% - 8rem) / 2);
        padding: 0.8rem;
        @include p--mini;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .stock-message-left {
        left: 0;
        top: 0;
      }
      .stock-message-right {
        display: flex;
        right: 0;
        top: 0;
        line-height: 130%;
        text-align: left;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 2rem;
            height: 100%;
          }
          span {
            margin-left: 0.5rem;
            width: 65px;
            text-decoration: underline;
          }
        }
      }
      &:active {
        background: $nude-25;
      }
      &.adding-to-cart {
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          .chosen-size {
            animation-name: blinker;
            animation-duration: 0.4s;
            animation-iteration-count: 2;
          }
          @keyframes blinker {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .size-guide {
    height: 5.6rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
    z-index: 1;
    text-decoration: underline;
    cursor: pointer;
    .icon {
      svg {
        width: 1.6rem;
      }
    }
  }

  .modal-header {
    box-shadow: none;
  }
  .ctas {
    width: 100%;
    padding: 1.6rem;
  }

  .name-and-prices {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    .name {
      font-weight: 600;
      font-size: 1.8rem;
      padding: 0 1.5rem 0.4rem 0;
    }
  }
}
</style>
