/* eslint-disable camelcase */
export const getDepictConfig = async (store, merchant) => {
  const depictLocales = await import('@depict-ai/ui/locales')
  const [locales] = await Promise.all([
    depictLocales
  ])

  const { en_GB, de_DE, fr_FR, nl_NL, sv_SE } = locales
  let localization

  const language = store.getters['frontend/currentLanguageCode'].toLowerCase()

  switch (language) {
    case 'de':
      localization = de_DE
      break
    case 'fr':
      localization = fr_FR
      break
    case 'nl':
      localization = nl_NL
      break
    case 'sv':
      localization = sv_SE
      break
    default: localization = en_GB
  }

  return {
    MERCHANT: merchant,
    getMarket: async () => {
      return await store.getters['frontend/currentCountryCode'].toLowerCase()
    },
    LOCALE: localization.backend_locale_,
    LOCALIZATION: {
      ...localization,
      price_formatting_: {
        pre_: store.getters['frontend/pricelist'].currency.prefix,
        post_: store.getters['frontend/pricelist'].currency.suffix,
        places_after_comma_: 0,
      },
    }
  }
}
/* eslint-enable camelcase */
