import { campaignStorageKey } from '~/store/campaign'

function persistActiveCampaign ($cookies, action) {
  if (action.type !== 'campaign/setActiveCampaign') {
    return
  }

  $cookies.set(campaignStorageKey, action.payload)
}

function persistCampaignRemoval ($cookies, action) {
  if (action.type !== 'campaign/removeActiveCampaign') {
    return
  }

  $cookies.remove(campaignStorageKey)
}

export default (context) => {
  const { store, $cookies } = context

  store.subscribeAction(action => persistActiveCampaign($cookies, action))
  store.subscribeAction(action => persistCampaignRemoval($cookies, action))
}
