import { createHash } from 'crypto'
import { mapGetters, mapState } from 'vuex'
import { isEmpty } from 'underscore'

const latestOrderIdKey = 'latestOrderId'
function shouldTrackPurchase (order) {
  if ('localStorage' in window) {
    if (window.localStorage.getItem(latestOrderIdKey) === order.order) {
      return false
    }
  }

  if ('localStorage' in window) {
    window.localStorage.setItem(latestOrderIdKey, order.order)
  }

  return true
}

export default {
  computed: {
    ...mapGetters('frontend', [
      'market',
      'currentLanguageCode',
      'currentLanguageCodeIso',
      'currentCountryCode',
    ]),
    pricelist () {
      return this.$nuxt.$store.getters['frontend/pricelist']
    },
    account () {
      return this.$nuxt.$store.getters['account/account']
    },
    ...mapState('ui', [
      'pageReferrer',
      'totalPageVisits',
    ]),
    canonicalUrl: ({ $config, $route }) => $config.baseUrl + $route.path,
    fullUrl: ({ $config, $route }) => $config.baseUrl + $route.fullPath,
    triggerType: ({ totalPageVisits }) => totalPageVisits > 0 ? 'soft' : 'hard',
    baseUrl: ({ $config }) => $config.baseUrl,
  },
  mounted () {
    if (!window) {
      return
    }
    window.dataLayer = window.dataLayer || []
    window.queuedProductImpressions = window.queuedProductImpressions || []
  },
  methods: {
    clearEcommerceObject () {
      window.dataLayer.push({
        ecommerce: null
      })
    },
    createHash (data) {
      if (isEmpty(data)) {
        return ''
      }
      return createHash('sha256').update(data).digest('hex')
    },

    getHashedAccountData () {
      if (isEmpty(this.account)) {
        return {}
      }

      return {
        sha256HashedEmail: this.createHash(this.account.email),
        sha256HashedPhone: this.createHash((this.account.phoneNumber).toString()),
      }
    },

    getAccountData () {
      if (isEmpty(this.account)) {
        return {}
      }

      return {
        first_name: this.account.firstName,
        last_name: this.account.lastName,
        email: this.account.email,
        sha256HashedEmail: this.createHash(this.account.email),
      }
    },

    isProduction () {
      return this.baseUrl === 'https://www.strongerlabel.com'
    },

    setPageReferrer () {
      this.$store.dispatch('ui/setPageReferrer', this.$route.fullPath)
    },

    getCategories (productCategories) {
      const listOfCategories = []
      const categories = productCategories.categoriesNonLocalized || productCategories.categories
      for (const key in categories) {
        listOfCategories.push(categories[key].name)
      }
      return listOfCategories.sort()
    },

    getItem (item) {
      return {
        item_id: item._product?.productSku || item.productSku,
        item_sku: item.sku,
        item_name: item.nameNonLocalized || item.productName,
        item_brand: item.brandName || 'Stronger',
        item_category: this.getCategories(item._product || item),
        item_category2: Object.values(item._product?.style || item.style || []).sort(),
        item_category3: item.productTypeNonLocalized || item._product?.productTypeNonLocalized || item._product?.product_type,
        item_category4: item._product?.colorNonLocalized || item.colorNonLocalized || item._product?.color,
        item_category5: item._product?.collectionName || item.collectionName,
        discount: (item.priceEachBeforeDiscountAsNumber ?? item.priceBeforeDiscountAsNumber) - (item.priceEachAsNumber ?? item.priceAsNumber),
        price: item.priceEachAsNumber || item.priceAsNumber,
        product_image_url: item._product?.mainImage || item.mainImage,
      }
    },

    gtm_trackPageImpression (pageTitle, pageType) {
      window.dataLayer.push({
        event: 'pageImpression',
        pageInfo: {
          url: this.fullUrl,
          canonicalUrl: this.canonicalUrl,
          pagePath: this.$route.path,
          pageTitle,
          pageType,
          pageReferrer: this.pageReferrer || document.referrer,
          country: this.currentCountryCode,
          language: this.currentLanguageCodeIso,
          currency: this.pricelist.currency.currency,
          triggerType: this.triggerType,
        },
        environmentInfo: {
          isProduction: this.isProduction(),
        }
      })
      this.setPageReferrer()
    },
    gtm_trackBundleSaveButtonClick (bundleUri) {
      window.dataLayer.push({
        event: 'bundleSaveButtonClick',
        ecommerce: {
          bundleUri
        }
      })
    },
    gtm_trackVideoClick (productUrl) {
      window.dataLayer.push({
        event: 'videoClick',
        ecommerce: {
          productUrl
        }
      })
    },
    gtm_softConversion () {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'pageviews_reached',
        ecommerce: {
          account: this.getHashedAccountData()
        }
      })
    },

    gtm_trackProductClick (item, list) {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
          account: this.getHashedAccountData(),
          currencyCode: this.pricelist.currency.currency,
          click: {
            actionField: { list },
            products: [{
              id: item.custom_fields.centra_product_sku?.[0],
              name: item.title,
              price: item.custom_fields?.[`price_${this.pricelist.currency.uri}`]?.[0],
              category: item.category?.[10]?.category1
            }]
          }
        }
      })
    },

    gtm_trackProductDetail (item) {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'productDetailImpression',
        ecommerce: {
          value: item.priceAsNumber,
          userInfo: this.getAccountData(),
          items: [{ ...this.getItem(item) }]
        }
      })
    },

    gtm_trackProductImpression (items) {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'productImpressions',
        ecommerce: {
          item_list_id: this.$nuxt.$store.state.ui.listId,
          item_list_name: this.$nuxt.$store.state.ui.listName,
          items
        }
      })
    },

    /**
     * We queue impressions and flush them either when 20 is
     * pushed, or when the listname changes, or after the queue has existed for 0.5 seconds. The reason for
     * this is that you can push too much data by mistake into GTM and we
     * don't want to do that, while we also want to push the separate product
     * as they appear in the browser window. Also we want to make a separate push for each list being displayed.
     */
    gtm_queueProductImpression ({ item, listName, listId, position, depict }) {
      if (this.$nuxt.$store.state.ui.listName === '') {
        // if it's a personalized list from depict, we use item type as list name
        this.$nuxt.$store.dispatch('ui/setActiveList', { listName: listName ?? item.type, listId })
      }
      const flushImpressionQueue = () => {
        if (window.queuedProductImpressions.length !== 0) {
          this.gtm_trackProductImpression(window.queuedProductImpressions)
          window.queuedProductImpressions = []
        }
      }

      clearTimeout(window.impressionPushTimer)
      if (this.$nuxt.$store.state.ui.listName !== (listName ?? item.type) || window.queuedProductImpressions.length >= 20) {
        flushImpressionQueue()
        this.$nuxt.$store.dispatch('ui/setActiveList', { listName: listName ?? item.type, listId })
      } else {
        window.impressionPushTimer = setTimeout(flushImpressionQueue, 500)
      }

      // product data can originate from both centra and depict, and both need to be tracked
      if (depict) {
        window.queuedProductImpressions.push({
          item_id: item.main_product_id,
          item_sku: item.product_id,
          item_name: item.title_eng || item.title,
          index: position,
          item_brand: 'Stronger',
          item_category: [item.canonical_category],
          item_category2: item.styles ? Object.values(item.styles).sort() : [],
          item_category3: item.product_type_eng,
          item_category4: item.color_eng,
          item_category5: item.collection,
          discount: item.original_price - item.sale_price,
          price: item.sale_price,
          product_image_url: item.image_urls[0],
          quantity: 1,
          item_list_name: this.$nuxt.$store.state.ui.listName,
          item_list_id: this.$nuxt.$store.state.ui.listId,
        })
      } else {
        window.queuedProductImpressions.push({
          item_id: item.custom_fields?.centra_product_sku,
          item_sku: item.custom_fields?.centra_sku,
          item_name: item.title,
          index: position,
          item_brand: item.brandName,
          item_category: item.category ? Object.values(item.category).sort() : [],
          item_category2: item.style ? Object.values(item.style).sort() : [],
          item_category3: item.custom_fields?.product_type[0],
          item_category4: item.color,
          item_category5: item.custom_fields?.collection[0],
          discount: item.priceBeforeDiscountAsNumber - item.priceAsNumber,
          price: item.priceAsNumber,
          product_image_url: item.thumbnail_url,
          quantity: 1,
          item_list_name: this.$nuxt.$store.state.ui.listName,
          item_list_id: this.$nuxt.$store.state.ui.listId,
        })
      }
    },

    gtm_trackAddToCart (item, quantity, quickShop) {
      this.clearEcommerceObject()
      const data = {
        event: 'addToCart',
        ecommerce: {
          value: item.priceEachWithoutTaxAsNumber * quantity,
          userInfo: this.getAccountData(),
          items: [{
            ...this.getItem(item),
            quantity,
            item_variant: item.size,
          }]
        }
      }
      if (quickShop) {
        // Only add quickshop data if true
        data.ecommerce = { ...data.ecommerce, quickShop }
      }
      window.dataLayer.push(data)
    },

    gtm_trackRemoveFromCart (item, quantity) {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          value: item.priceEachWithoutTaxAsNumber * quantity,
          userInfo: this.getAccountData(),
          items: [{ ...this.getItem(item), quantity, item_variant: item.size, remove_from_cart_location: this.$route.path }]
        }
      })
    },

    gtm_trackCheckoutStep (cart) {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          value: cart.totals?.grandTotalPriceAsNumber - cart.totals?.grandTotalPriceTaxAsNumber,
          userInfo: this.getAccountData(),
          items: cart.items.map((item, index) => {
            return { ...this.getItem(item), quantity: item.quantity, item_variant: item.size, index }
          }),
        }
      })
    },

    gtm_trackShippingMethod (cart) {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'shippingRendered',
        ecommerce: {
          value: cart.totals?.grandTotalPriceAsNumber - cart.totals?.grandTotalPriceTaxAsNumber,
          shipping_tier: cart.shippingMethodName,
          userInfo: {
            first_name: cart.address?.firstName,
            last_name: cart.address?.lastName,
            email: cart.address?.email,
            sha256HashedEmail: this.createHash(cart.address?.email || ''),
          },
          items: cart.items.map((item, index) => {
            return { ...this.getItem(item), quantity: item.quantity, item_variant: item.size, index }
          }),
        }
      })
    },

    gtm_trackPaymentInfo (cart) {
      this.clearEcommerceObject()
      window.dataLayer.push({
        event: 'paymentRendered',
        ecommerce: {
          value: cart.totals?.grandTotalPriceAsNumber - cart.totals?.grandTotalPriceTaxAsNumber,
          shipping_tier: cart.shippingMethodName,
          userInfo: {
            first_name: cart.address?.firstName,
            last_name: cart.address?.lastName,
            email: cart.address?.email,
            sha256HashedEmail: this.createHash(cart.address?.email || ''),
          },
          items: cart.items.map((item, index) => {
            return { ...this.getItem(item), quantity: item.quantity, item_variant: item.size, index }
          }),
        }
      })
    },
    gtm_trackMenuClick ({ label, parent, id, view }) {
      window.dataLayer.push({
        event: 'menuClick',
        ecommerce: {
          label,
          parent,
          id,
          view
        }
      })
    },
    gtm_trackPurchase (order) {
      if (!shouldTrackPurchase(order)) {
        return
      }

      this.clearEcommerceObject()
      let coupon
      if (order?.discounts?.vouchers) {
        coupon = Object.values(order.discounts.vouchers)
          .map(voucher => `${voucher.description} (${voucher.voucher})`)
          .join(', ')
      }
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: order.order,
          value:
            order.totals?.grandTotalPriceAsNumber -
            order.totals?.grandTotalPriceTaxAsNumber -
            order.totals?.shippingPriceAsNumber,
          tax: order.totals?.grandTotalPriceTaxAsNumber,
          shipping: order.totals?.shippingPriceAsNumber,
          coupon,
          shipping_tier: order.attributes?.shipwallet_method?.data,
          payment_type: order.paymentMethodName,
          new_customer: order.isNewCustomer,
          userInfo: {
            first_name: order.address?.firstName,
            last_name: order.address?.lastName,
            email: order.address?.email,
            sha256HashedEmail: this.createHash(order.address?.email),
            phone_number: order.address?.phoneNumber,
            sha256HashedPhone: this.createHash(order.address?.phoneNumber),
            street: order.address?.address1,
            city: order.address?.city,
            region: order.address?.state || '',
            postal_code: order.address?.zipCode,
            country: order.address?.country,
          },
          items: order.items.map((item, index) => {
            return {
              ...this.getItem(item),
              quantity: item.quantity,
              item_variant: item.size,
              index,
              coupon,
              product_image_url: item._product?.media[0].sources.full.url,
            }
          }),
        },
      })
    },
  }
}
