<template>
  <div
    class="shop-usps"
    :class="'bg-color--' + backgroundColor"
    :style="[
      foregroundColorCss,
      backgroundColorCss
    ]"
  >
    <div class="di di--1">
      <div
        v-if="usps"
        :class="[
          'mc--' + mobileColumns,
          'dc--' + desktopColumns
        ]"
        class="wrapper"
      >
        <ShopUsp
          v-for="(usp, index) in usps"
          :key="'usp-' + index"
          v-bind="usp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ShopUsp from '~/components/shop-usps/ShopUsp'
import ColorHexCode from '~/mixins/ColorHexCode.js'

export default {
  name: 'ShopUsps',
  components: {
    ShopUsp
  },
  mixins: [ColorHexCode],
  props: {
    usps: {
      type: Array,
      required: false,
      default () {}
    },
    backgroundColor: {
      type: String,
      default: 'mint-25'
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    foregroundColor: {
      type: String,
      default: 'black'
    },
    customForegroundColor: {
      type: String,
      default: ''
    },
    mobileColumns: {
      type: String,
      default: '3'
    },
    desktopColumns: {
      type: String,
      default: '4'
    }
  },
  computed: {
    foregroundColorCss () {
      let color = this.foregroundColor
      if (this.customForegroundColor) {
        color = this.customForegroundColor
      }
      return { '--foreground-color': color }
    },
    backgroundColorCss () {
      const backgroundColor = this.color(this.backgroundColor, this.backgroundColorHex)
      return backgroundColor ? { backgroundColor } : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-usps {
  .wrapper {
    padding: 1.6rem .8rem;
    grid-row-gap: 3.2rem;
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .shop-usps {
    .wrapper {
      padding: 3.2rem 2.4rem 3.6rem 2.4rem;
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .shop-usps {
    .wrapper {
      padding: 3.2rem 2.4rem 3.6rem 2.4rem;
    }
  }
}

</style>
