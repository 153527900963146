<template>
  <div class="size-selector-item" :class="{ 'loading': loading }">
    <button class="button small black" :disabled="size.quantity === 0 ? 'disabled' : false">
      <span v-if="loading === size" class="chosen-size label"> {{ size.label }} </span>
      <span v-else class="label">
        <template v-if="size.label != ''">
          {{ size.label }}
        </template>
        <template v-else>
          {{ $t('Product_Size_Onesize') }}
        </template>
      </span>
    </button>
    <div class="stock-message-left">
      <template v-if="size.quantity <= 10 && size.quantity > 5">
        {{ $t('Global_10_Or_Less') }}
      </template>
      <template v-if="size.quantity <= 5 && size.quantity > 0">
        {{ $t('Global_Low_Stock') }}
      </template>
      <template v-if="size.quantity === 0">
        {{ $t('Global_Out_Of_Stock') }}
      </template>
    </div>
    <template v-if="size.quantity === 0 && !productTerminated && notifyMeEnabled">
      <div class="stock-message-right" @click="showProductRestockSidebar(size)">
        <span>
          <MailIcon />
          <span>{{ $t('Size_Selector_Notify_Me') }}</span>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import MailIcon from '~/assets/icons/mail.svg'

export default {
  name: 'SizeSelectorItem',
  components: { MailIcon },
  props: {
    size: {
      type: Object || Boolean,
      required: true
    },
    productTerminated: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Object,
      default: null,
    }
  },
  computed: {
    ...mapState({
      notifyMeEnabled: state => state.ui.notifyMeEnabled,
    }),
  },
  methods: {
    showProductRestockSidebar (size) {
      this.$store.dispatch('ui/showProductRestockSidebar', { items: this.product?.sizeOptions || null, itemId: size.value })
    },
  }
}
</script>

<style lang="scss" scoped>
.size-selector-item {
  padding: 0.8rem 0;
  position: relative;
  text-align: center;

  &:active {
    background: $nude-25;
  }

  &.loading {
    .button {
      display: flex;
      justify-content: center;
      align-items: center;

      .chosen-size {
        animation-name: blinker;
        animation-duration: 0.4s;
        animation-iteration-count: 2;
      }

      @keyframes blinker {
        from {
          opacity: 1.0;
        }

        to {
          opacity: 0.0;
        }
      }
    }
  }

  .label {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .button {
    width: 8rem;
    min-width: unset;
    margin: 0 auto;
    height: 3.2rem;

    @media (hover: hover) {
      &:hover:enabled {
        background-color: $mint-25;
        border-color: $mint-100;
      }
    }

    &:disabled {
      background-color: $grey-25;
      color: $grey-75;
      box-shadow: none;
    }
  }

  .stock-message-left,
  .stock-message-right {
    position: absolute;
    height: 100%;
    width: calc((100% - 8rem) / 2);
    padding: .8rem;
    @include p--mini;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stock-message-left {
    left: 0;
    top: 0;
  }

  .stock-message-right {
    display: flex;
    right: 0;
    top: 0;
    line-height: 130%;
    text-align: left;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 2rem;
        height: 100%;
      }

      span {
        margin-left: 0.5rem;
        width: 65px;
        text-decoration: underline;
      }
    }
  }
}
</style>
