<template>
  <div
    class="hero-wrapper"
    :class="[
      {
        plate: diagonalPlate !== '',
        tint,
        'hide-mobile': hideMobile,
        'hide-desktop': hideDesktop,
      },
      'ms--' + mobileDisplaySize,
      'ds--' + desktopDisplaySize,
    ]"
    :style="[styleProps, bottomPlateColorCss, backgroundColorCss]"
  >
    <div v-if="content.length > 0" class="hero-content">
      <div :class="isInsideGrid ? 'wrapper-inside-grid' : 'wrapper'">
        <HeroHeadline
          v-if="heroHeadline"
          v-bind="heroHeadline"
          :is-inside-grid="isInsideGrid"
        />
        <div class="hero-buttons">
          <template v-for="(button, index) in heroButtons">
            <HeroButton
              class="hero-btn"
              :key="index"
              v-bind="button"
              v-editable="button"
              :inherited-link="overwriteCtaLinks ? link : {}"
              :class="{ 'hide-button-desktop': hideButtonDesktop && index === 1, 'hide-button-mobile': hideButtonMobile && index === 1 }"
            />
          </template>
        </div>
      </div>
    </div>
    <Vue2Video
      v-if="mobileVideo || desktopVideo"
      ref="video"
      class="hero-video"
      :src="videoSrc"
      :class="videoBlockClasses"
    />
    <Vue2Image
      v-if="mobileVideo"
      :src="mobileImageFilename"
      :width="400"
      :alt="mobileImage.alt ? mobileImage.alt : ''"
      :quality="60"
      class="poster-image-mobile"
    />
    <Vue2Image
      v-if="desktopVideo"
      :src="desktopImageFilename"
      :width="400"
      :alt="desktopImage.alt ? mobileImage.alt : ''"
      :quality="60"
      ref="poster-image"
      class="poster-image-desktop"
    />
    <ResponsiveImage
      v-if="mobileImageFilename || desktopImageFilename"
      class="hero-image"
      :mobile-src="mobileImageFilename"
      :desktop-src="desktopImageFilename"
      :alt="mobileImage.alt ? mobileImage.alt : ''"
      :mobile-display-size="mobileDisplaySize"
      :desktop-display-size="desktopDisplaySize"
      :lazyload="true"
      :class="[imageBlockClasses, loaded ? 'loaded' : 'loading']"
      @imageLoaded="imageLoaded"
    />
    <template v-for="(tag, index) in tags">
      <Tag
        v-if="tag.type === 'tag'"
        :key="index"
        :visible="tag.visible"
        :text="tag.text"
        :position="tag.position"
        :text-color="tag.textColor"
        :background-color="tag.backgroundColor"
        :background-color-hex="tag.backgroundColorHex"
      />
      <Ribbon
        v-if="tag.type === 'ribbon'"
        :key="index"
        :visible="tag.visible"
        :text="tag.text"
        :position="tag.position"
        :text-color="tag.textColor"
        :background-color="tag.backgroundColor"
        :background-color-hex="tag.backgroundColorHex"
      />
    </template>
    <HotspotController
      v-for="(hotspot, index) in hotspots"
      :key="'hotspot-' + index"
      v-bind="hotspot"
    />
    <mp-link
      v-if="$u(link) !== null"
      :to="$u(link)"
      class="link"
      :class="{ 'hover-effect': hoverEffect }"
    />
  </div>
</template>

<script>
import Vue2Video from '@made-people/vue2-video'
import Vue2Image from '@made-people/vue2-image'
import HeroHeadline from '~/components/hero/HeroHeadline.vue'
import HeroButton from '~/components/button/HeroButton.vue'
import ResponsiveImage from '~/components/image/ResponsiveImage.vue'
import ColorHexCode from '~/mixins/ColorHexCode.js'
import Tag from '~/components/tag/Tag'
import Ribbon from '~/components/ribbon/Ribbon'
import HotspotController from '~/components/hotspots/HotspotController.vue'

export default {
  name: 'HeroWrapper',
  components: {
    HeroHeadline,
    HeroButton,
    ResponsiveImage,
    Tag,
    Ribbon,
    HotspotController,
    Vue2Video,
    Vue2Image,
  },
  mixins: [ColorHexCode],
  props: {
    isInsideGrid: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    hoverEffect: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileVideo: {
      type: String,
      required: false,
      default: '',
    },
    desktopVideo: {
      type: String,
      required: false,
      default: '',
    },
    mobileImage: {
      type: Object,
      required: false,
      default: null,
    },
    desktopImage: {
      type: Object,
      required: false,
      default: null,
    },
    tags: {
      type: Array,
      required: true,
    },
    hotspots: {
      type: Array,
      default: () => [],
    },
    link: {
      type: Object,
      required: false,
      default: null,
    },
    overwriteCtaLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
    diagonalPlate: {
      type: String,
      required: true,
    },
    tint: {
      type: Boolean,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    mobileSize: {
      type: String,
      required: false,
      default: 'auto',
    },
    desktopSize: {
      type: String,
      required: false,
      default: 'auto',
    },
    mobileDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    desktopDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    verticalAlignmentMobile: {
      type: String,
      required: false,
      default: 'center',
    },
    verticalAlignmentDesktop: {
      type: String,
      required: false,
      default: 'same-as-mobile',
    },
    horizontalAlignmentMobile: {
      type: String,
      required: false,
      default: 'center',
    },
    horizontalAlignmentDesktop: {
      type: String,
      required: false,
      default: 'same-as-mobile',
    },
    desktopColumnsNumber: {
      type: String,
      required: false,
      default: '1',
    },
    mobileColumnsNumber: {
      type: String,
      required: false,
      default: '1',
    },
  },
  data () {
    return {
      loaded: false,
    }
  },
  computed: {
    isDesktop: () =>
      typeof window !== 'undefined' ? window.innerWidth >= 1024 : false,

    mobileImageFilename () {
      return this.mobileImage?.filename || ''
    },

    desktopImageFilename () {
      return this.desktopImage?.filename || ''
    },

    videoSrc () {
      return this.isDesktop && this.desktopVideo ? this.desktopVideo : this.mobileVideo
    },

    videoBlockClasses () {
      return {
        'hide-desktop': this.mobileVideo && !this.desktopVideo,
        'show-desktop hide-mobile': !this.mobileVideo && this.desktopVideo,
      }
    },

    imageBlockClasses () {
      return {
        'hide-mobile': this.mobileVideo && this.desktopVideo,
        'hide-mobile show-desktop': this.mobileVideo && !this.desktopVideo,
        'show-mobile hide-desktop': !this.mobileVideo && this.desktopVideo,
      }
    },

    bottomPlateColorCss () {
      return { '--bottom-plate-color': this.diagonalPlate }
    },

    backgroundColorCss () {
      return {
        '--bg-color': this.color(this.backgroundColor, this.backgroundColorHex),
      }
    },

    heroHeadline () {
      return this.content?.find(item => item.component === 'HeroHeadline')
    },

    heroButtons () {
      return this.content?.filter(item => item.component === 'HeroButton')
    },

    hideButtonDesktop () {
      return this.heroButtons?.length === 2 && this.isInsideGrid && this.desktopColumns > 2
    },

    hideButtonMobile () {
      return this.heroButtons?.length === 2 && this.isInsideGrid && this.mobileColumns > 1
    },

    getStyle () {
      return this.isInsideGrid
        ? {
          contentGapMobile: '1.1rem',
          contentGapDesktop: '1.9rem',
          contentPadding: '1.9rem 2.4rem',
          contentPaddingDesktop: '3.5rem 3.2rem',
        }
        : {
          contentGapMobile: '2.4rem',
          contentGapDesktop: '3.3rem',
          contentPadding: '3.3rem 4.4rem',
          contentPaddingDesktop: '7.1rem 14rem',
        }
    },

    verticalAlignment () {
      return this.verticalAlignmentDesktop === 'same-as-mobile'
        ? this.verticalAlignmentMobile
        : this.verticalAlignmentDesktop
    },
    horizontalAlignment () {
      return this.horizontalAlignmentDesktop === 'same-as-mobile'
        ? this.horizontalAlignmentMobile
        : this.horizontalAlignmentDesktop
    },

    styleProps () {
      const mobile = {
        auto: 'none',
        tall: '3 / 4',
        wide: '4 / 3',
        square: '1 / 1',
      }

      const desktop = {
        auto: 'none',
        tall: '3 / 4',
        wide: '2 / 1',
        square: '1 / 1',
      }

      return {
        '--aspect-ratio-mobile': mobile[this.mobileSize],
        '--aspect-ratio-desktop': desktop[this.desktopSize],
        '--vertical-alignment-mobile': `${this.verticalAlignmentMobile}`,
        '--vertical-alignment-desktop': `${this.verticalAlignment}`,
        '--horizontal-alignment-mobile': `${this.horizontalAlignmentMobile}`,
        '--horizontal-alignment-desktop': `${this.horizontalAlignment}`,
        '--gap-mobile': this.getStyle?.contentGapMobile,
        '--gap-desktop': this.getStyle?.contentGapDesktop,
        '--padding': this.getStyle?.contentPadding,
        '--padding-desktop': this.getStyle?.contentPaddingDesktop,
      }
    },
  },
  methods: {
    imageLoaded () {
      this.loaded = true
    }
  },
}
</script>

<style lang="scss" scoped>
.poster-image-desktop {
  display: none;
}

.poster-image-mobile {
  display: block;
  width: 100%;
}

.hero-wrapper {
  aspect-ratio: var(--aspect-ratio-mobile);
  position: relative;
  overflow: hidden;
  background: var(--bg-color);
}

.hide-mobile {
  display: none;
}

.hero-image {
  width: 100%;
  height: 100%;
  background-color: $page-background;
  &::v-deep {
    picture {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.show-mobile {
  display: block;
}

.loading {
  opacity: 0;
}

.loaded {
  opacity: 1;
  transition: opacity .3s cubic-bezier(.5, 0, 0, 1);
}

.hero-video {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.hide-mobile {
  display: none;
}

/**
 * Adds a diagonal plate to the bottom part of the hero
 */
.plate {
  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bottom-plate-color);
    transform: skewY(-12deg) translateY(55%);
    z-index: 2;
    pointer-events: none;
  }
}

/**
 * Adds a tint to the bottom part of the hero
 */
.tint {
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 50%;
    width: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, #000000 3.7%, rgba(0, 0, 0, 0) 95.76%);
    mix-blend-mode: multiply;
    opacity: 0.3;
    z-index: 1;
    pointer-events: none;
  }
}

.link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: $black;
  opacity: 0;
  z-index: 4;
  transition: opacity 0.2s ease-out;
  &.hover-effect {
    @media (hover: hover) {
      &:hover {
        opacity: 0.3;
      }
    }
  }
}

.hero-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: var(--vertical-alignment-mobile);
  align-items: var(--horizontal-alignment-mobile);
  padding: 3rem 2rem;
}

.wrapper,
.wrapper-inside-grid {
  display: flex;
  flex-direction: column;
  gap: var(--gap-mobile);
  width: fit-content;
  justify-content: var(--vertical-alignment-mobile);
  text-align: center;
}

.wrapper {
  align-items: var(--horizontal-alignment-mobile);
}

.hero-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 1.6rem;
  line-height: 11.96px;
}

.hero-btn {
  ::v-deep {
    .label {
      padding: 0;
    }
  }
}

.hide-button-mobile {
  display: none;
}

.tag {
  z-index: 3;
  &.top-left,
  &.top-right {
    top: 1.6rem;
  }
  &.bottom-left,
  &.bottom-right {
    bottom: 1.6rem;
  }
  &.top-left,
  &.bottom-left {
    left: 1.6rem;
  }
  &.top-right,
  &.bottom-right {
    right: 1.6rem;
  }
}

.ribbon {
  position: absolute;
  top: 0;
  z-index: 3;
  &.top-left {
    left: 0.8rem;
  }
  &.top-right {
    right: 0.8rem;
  }
}

// Mobile
// 2 Columns
.ms--51vw {
  .ribbon {
    padding: 0.6rem 1.2rem 0.6rem 1.8rem;
  }
}

// Mobile
// 3 Columns
.ms--34vw {
  .ribbon {
    padding: 0.6rem 1.2rem 0.6rem 1.8rem;
  }
}

@media (min-width: 375px) {
  .hero-content {
    padding: var(--padding);
  }

  .hero-buttons {
    gap: 2rem;
  }
}

@media (min-width: $tablet) {
  // Tablet (Portrait)
  // 3 Columns
  .ms--34vw {
    .ribbon {
      padding: 0.6rem 2.2rem 0.6rem 2.8rem;
    }
  }

  .hero-content {
    padding: var(--padding-desktop);
  }

  .wrapper,
  .wrapper-inside-grid {
    gap: var(--gap-desktop);
    justify-content: var(--vertical-alignment-desktop);
  }

  .wrapper {
    align-items: var(--horizontal-alignment-desktop);
  }
}

@media (min-width: $tablet-landscape) {
  .poster-image-desktop {
    display: block;
  }

  .poster-image-mobile {
    display: none;
  }

  .show-desktop {
    display: block;
  }

  .hide-desktop {
    display: none;
  }

  .hero-wrapper {
    aspect-ratio: var(--aspect-ratio-desktop);
  }
  // Tablet (Landscape)
  // 3 Columns
  .ds--34vw {
    .ribbon {
      padding: 0.6rem 2.2rem 0.6rem 2.8rem;
      &.top-left {
        left: 1.6rem;
      }
      &.top-right {
        right: 1.6rem;
      }
    }
    .tag {
      &.top-left {
        top: 1.6rem;
      }
      &.top-right {
        top: 1.6rem;
      }
    }
  }

  // Tablet (Landscape)
  // 4 Columns
  .ds--26vw {
    .ribbon {
      &.top-left {
        left: 0.8rem;
      }
      &.top-right {
        right: 0.8rem;
      }
    }
    .tag {
      &.top-left {
        top: 0.8rem;
      }
      &.top-right {
        top: 0.8rem;
      }
    }
    .ribbon {
      padding: 0.6rem 2.2rem 0.6rem 2.8rem;
    }
  }

  .hide-mobile {
    display: block;
  }

  .hide-desktop {
    display: none;
  }

  .hero-content {
    justify-content: var(--vertical-alignment-desktop);
    align-items: var(--horizontal-alignment-desktop);
  }

  .hide-button-mobile {
    display: block;
  }

  .hide-button-desktop {
    display: none;
  }

  .tag {
    &.top-left,
    &.top-right {
      top: 4rem;
    }
    &.bottom-left,
    &.bottom-right {
      bottom: 4rem;
    }
    &.top-left,
    &.bottom-left {
      left: 0;
    }
    &.top-right,
    &.bottom-right {
      right: 0;
    }
  }

  .ribbon {
    &.top-left {
      left: 3.2rem;
    }
    &.top-right {
      right: 3.2rem;
    }
  }
}

@media screen and (min-width: $laptop) {
  // Laptop
  // 4 Columns
  .ds--26vw {
    .ribbon {
      &.top-left {
        left: 1.6rem;
      }
      &.top-right {
        right: 1.6rem;
      }
    }
    .tag {
      &.top-left {
        top: 1.6rem;
      }
      &.top-right {
        top: 1.6rem;
      }
    }
  }

  .hero-btn {
    ::v-deep {
      .label {
        font-size: 1.4rem;
      }
    }
  }
}
</style>
