<template>
  <div class="vip-banner" :style="colorVariablesCss">
    <div class="wrapper di di--3">
      <div
        v-if="settings.campaignbanner_text"
        class="text"
        v-html="nl2br(settings.campaignbanner_text.html)"
      />
      <button v-if="settings.campaignbanner_buttonText" class="button outline white mini vip">
        <span class="label" @click="exitVip">
          {{ settings.campaignbanner_buttonText }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { nl2br } from '~/lib/helpers'

export default {
  name: 'VipBanner',
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings'
    }),
    colorVariablesCss () {
      return {
        '--foreground-color': this.settings.campaignbanner_foregroundColor || '#FFFFFF',
        '--background-color': this.settings.campaignbanner_backgroundColor || '#FF6B6B'
      }
    }
  },
  methods: {
    nl2br,
    exitVip () {
      this.$store.dispatch('campaign/removeActiveCampaign')
      window.location.href = this.$u('')
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-banner {
  background-color: var(--background-color);
  min-height: 4.8rem;
  width: 100%;
  z-index: 1;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .text {
      @include p--mini;
      text-align: left;
      color: var(--foreground-color);
      z-index: 1;
      margin: 0;
      width: 100%;
      &::v-deep {
        > br:last-of-type {
          display: none;
        }
        p {
          /*margin: 0.4rem 0;*/
          margin: 0;
          strong,
          em {
            text-transform: uppercase;
            font-weight: 700;
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
    .vip.button {
      &::v-deep {
        width: 7.2rem;
        max-width: 7.2rem;
        min-width: 7.2rem;
        max-height: 2.4rem;
        display: flex;
        flex-shrink: 0;
        margin-left: .8rem;
        color: var(--foreground-color) !important;
        border-color: var(--foreground-color) !important;
        background-color: transparent !important;
        .label {
          padding: 0;
          font-size: .8rem;
        }
      }
    }
  }
}
// Custom breakpoint (Landscape)
@media screen and (min-width: 600px) {
  .vip-banner {
    .wrapper {
      justify-content: space-between;
      align-items: center;
    }
  }
}
// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .vip-banner {
    width: 100vw;
    height: 3rem;
    padding: .8rem 0;
    .wrapper {
      justify-content: center;
      align-items: center;
      .text {
        font-size: 1.2rem;
        text-align: center !important;
        width: auto;
        padding: 0 5.6rem 0 0;
        &::v-deep p {
          margin: 0.4rem 0;
          em {
            font-size: 1.4rem !important;
          }
        }
      }
      .vip.button {
        &::v-deep {
          width: 9.6rem;
          max-width: 9.6rem;
          min-width: 9.6rem;
          display: flex;
          flex-shrink: 0;
          margin-left: .8rem;
          .label {
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
