import { HitCounter } from '~/hit-counter/client'

export default (context, inject) => {
  const { app, store } = context

  const hitCounterClient = new HitCounter({
    sourceId: {
      toString: () => store.getters['user-unique-id/id']
    },
    timeout: 1000,
    baseURL: app.$config.hitCounterApiUrl
  })

  inject('hitCounterApi', hitCounterClient)
}
