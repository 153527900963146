/**
 * If you want to generate a list of all components, lazy-loaded
 *

 #/bin/bash
 find components -name '*.vue' | while read file
 do
 name=$(basename $file)
 echo "Vue.component('${name%.vue}', () => import('~/$file'))"
 done | sort
 */
import Vue from 'vue'

import ShopUsps from '@/components/shop-usps/ShopUsps'

Vue.component('ShopUsps', ShopUsps)

Vue.component('Accordion', () => import('~/components/accordion/Accordion.vue'))
Vue.component('AccordionRow', () =>
  import('~/components/accordion/AccordionRow.vue')
)
Vue.component('AddedToCartPopup', () =>
  import('~/components/added-to-cart-popup/AddedToCartPopup.vue')
)
Vue.component('BrandUsp', () => import('~/components/brand-usps/BrandUsp.vue'))
Vue.component('BrandUsps', () =>
  import('~/components/brand-usps/BrandUsps.vue')
)
Vue.component('Breadcrumbs', () =>
  import('~/components/breadcrumbs/Breadcrumbs.vue')
)
Vue.component('Button', () => import('~/components/button/Button.vue'))
Vue.component('HeroCarousel', () =>
  import('~/components/hero-carousel/HeroCarousel.vue')
)
Vue.component('Cart', () => import('~/components/cart/Cart.vue'))
Vue.component('CartItem', () => import('~/components/mini-cart/CartItem.vue'))
Vue.component('Checkout', () => import('~/components/checkout/Checkout.vue'))
Vue.component('CheckoutAddress', () =>
  import('~/components/checkout/checkout-address/CheckoutAddress.vue')
)
Vue.component('CheckoutAddressPreview', () =>
  import('~/components/checkout/checkout-address/CheckoutAddressPreview.vue')
)
Vue.component('CheckoutMethod', () =>
  import('~/components/checkout/checkout-method/CheckoutMethod.vue')
)
Vue.component('CheckoutMethodRadio', () =>
  import('~/components/checkout/checkout-method-radio/CheckoutMethodRadio.vue')
)
Vue.component('CheckoutResult', () =>
  import('~/components/checkout/checkout-result/CheckoutResult.vue')
)
Vue.component('PaymentSnippet', () =>
  import('~/components/checkout/checkout-result/PaymentSnippet.vue')
)
Vue.component('CheckoutShippingPayment', () =>
  import(
    '~/components/checkout/checkout-shipping-payment/CheckoutShippingPayment.vue'
  )
)
Vue.component('CollectionImage', () =>
  import('~/components/header/collection-image/CollectionImage.vue')
)
Vue.component('CompleteTheLook', () =>
  import('~/components/complete-the-look/CompleteTheLook.vue')
)
Vue.component('CategorySlider', () =>
  import('~/components/category-slider/CategorySlider.vue')
)
Vue.component('DepictBlock', () =>
  import('~/components/depict/DepictBlock.vue')
)
Vue.component('DepictProductList', () =>
  import('~/components/depict/DepictProductList.vue')
)
Vue.component('SeoCopyBlocks', () =>
  import('~/components/seo-copy-blocks/SeoCopyBlocks.vue')
)
Vue.component('CountrySelectorSidebar', () =>
  import('~/components/country-selector-sidebar/CountrySelectorSidebar.vue')
)
Vue.component('CustomerSupport', () =>
  import('~/components/customer-support/CustomerSupport.vue')
)
Vue.component('DescriptionRow', () =>
  import('~/components/mobile-product-description/DescriptionRow.vue')
)
Vue.component('Discount', () => import('~/components/discount/Discount.vue'))
Vue.component('Headline', () =>
  import(/* webpackPrefetch: true */ '~/components/headlines/Headline.vue')
)
Vue.component('HeroHeadline', () =>
  import('~/components/hero/HeroHeadline.vue')
)
Vue.component('Hotspot', () => import('~/components/hotspots/Hotspot.vue'))
Vue.component('HotspotController', () =>
  import('~/components/hotspots/HotspotController.vue')
)
Vue.component('ImageBlock', () =>
  import(/* webpackPrefetch: true */ '~/components/image/ImageBlock.vue')
)
Vue.component('InstagramCollection', () =>
  import('~/components/instagram-flowbox/InstagramCollection.vue')
)
Vue.component('InstagramCollectionController', () =>
  import('~/components/instagram-flowbox/InstagramCollectionController.vue')
)
Vue.component('SwipeableInstagramPDPCollectionController', () =>
  import(
    '~/components/instagram-flowbox/SwipeableInstagramPDPCollectionController.vue'
  )
)
Vue.component('LiveShopping', () =>
  import('~/components/live-shopping/LiveShopping.vue')
)
Vue.component('Loading', () => import('~/components/loading/Loading.vue'))
Vue.component('MinicartUspCollection', () =>
  import('~/components/mini-cart/MinicartUspCollection.vue')
)
Vue.component('MobileProductDescription', () =>
  import('~/components/mobile-product-description/MobileProductDescription.vue')
)
Vue.component('ModalHeader', () =>
  import('~/components/modal/modal-header/ModalHeader.vue')
)
Vue.component('Newsletter', () =>
  import('~/components/newsletter/Newsletter.vue')
)
Vue.component('Overlay', () => import('~/components/overlay/Overlay.vue'))
Vue.component('PillFilter', () =>
  import('~/components/pill-filter/PillFilter.vue')
)
Vue.component('ProductBadge', () =>
  import('~/components/product/product-badge/ProductBadge.vue')
)
Vue.component('ProductDescriptionDesktop', () =>
  import(
    '~/components/product-description-desktop/ProductDescriptionDesktop.vue'
  )
)
Vue.component('ProductDetails', () =>
  import('~/components/product/product-details/ProductDetails.vue')
)
Vue.component('ProductImageGallery', () =>
  import('~/components/product/product-image-gallery/ProductImageGallery.vue')
)
Vue.component('ProductList', () =>
  import('~/components/product/product-list/ProductList.vue')
)
Vue.component('ProductListController', () =>
  import('~/components/product/product-list/ProductListController.vue')
)
Vue.component('ProductListHeaderContent', () =>
  import(
    '~/components/product-list-header-content/ProductListHeaderContent.vue'
  )
)
Vue.component('ProductListHeaderContentController', () =>
  import(
    '~/components/product-list-header-content/ProductListHeaderContentController.vue'
  )
)
Vue.component('ProductListSwipeable', () =>
  import('~/components/product-list-swipeable/ProductListSwipeable.vue')
)
Vue.component('ProductListSwipeableController', () =>
  import(
    '~/components/product-list-swipeable/ProductListSwipeableController.vue'
  )
)
Vue.component('ProductPopup', () =>
  import('~/components/hotspots/ProductPopup.vue')
)
Vue.component('ProductPopupSwipe', () =>
  import('~/components/hotspots/ProductPopupSwipe.vue')
)
Vue.component('ProductPopupGrid', () =>
  import('~/components/hotspots/ProductPopupGrid.vue')
)
Vue.component('ProductRestockSidebar', () =>
  import('~/components/product-restock-sidebar/ProductRestockSidebar.vue')
)
Vue.component('ProductUpsell', () =>
  import('~/components/product-upsell/ProductUpsell.vue')
)
Vue.component('ProductUpsellFoldout', () =>
  import('~/components/product-upsell/ProductUpsellFoldout.vue')
)
Vue.component('ProductZoom', () =>
  import('~/components/product-zoom/ProductZoom.vue')
)
Vue.component('PushNotifications', () =>
  import('~/components/push-notifications/PushNotifications.vue')
)
Vue.component('QuickNav', () => import('~/components/quick-nav/QuickNav.vue'))
Vue.component('Quote', () => import('~/components/quote/Quote.vue'))
Vue.component('Ribbon', () => import('~/components/ribbon/Ribbon.vue'))
Vue.component('SizeGuide', () =>
  import('~/components/size-guide/SizeGuide.vue')
)
Vue.component('SizeSelector', () =>
  import('~/components/size-selector/SizeSelector.vue')
)
Vue.component('Slideshow', () => import('~/components/slideshow/Slideshow.vue'))
Vue.component('StrongerStory', () =>
  import('~/components/stronger-story/StrongerStory.vue')
)
Vue.component('SwipeableInstagramCollection', () =>
  import('~/components/instagram-flowbox/SwipeableInstagramCollection.vue')
)
Vue.component('SwipeableInstagramCollectionController', () =>
  import(
    '~/components/instagram-flowbox/SwipeableInstagramCollectionController.vue'
  )
)
Vue.component('Tag', () => import('~/components/tag/Tag.vue'))
Vue.component('TextBlock', () =>
  import(/* webpackPrefetch: true */ '~/components/text-block/TextBlock.vue')
)
Vue.component('Textfield', () =>
  import(/* webpackPrefetch: true */ '~/components/form/Textfield.vue')
)
Vue.component('Toast', () => import('~/components/toast/Toast.vue'))
Vue.component('ToggleCart', () => import('~/components/header/ToggleCart.vue'))
Vue.component('ToggleMenu', () => import('~/components/header/ToggleMenu.vue'))
Vue.component('ToggleSearch', () =>
  import('~/components/header/ToggleSearch.vue')
)
Vue.component('Total', () => import('~/components/cart/Total.vue'))
Vue.component('UpsellProductCard', () =>
  import('~/components/product-upsell/UpsellProductCard.vue')
)
Vue.component('VideoCard', () =>
  import('~/components/live-shopping/VideoCard.vue')
)
Vue.component('ReclaimIt', () =>
  import('~/components/reclaim-it/ReclaimIt.vue')
)
Vue.component('MarketContainer', () => import('~/components/MarketContainer'))
Vue.component('KlaviyoForm', () =>
  import('~/components/klaviyo-form/KlaviyoForm.vue')
)
Vue.component('RichResult', () =>
  import('~/components/product/product-details/RichResult.vue')
)
Vue.component('TopbarPanel', () =>
  import('~/components/header/topbar/TopbarPanel.vue')
)
Vue.component('IngridTracking', () =>
  import('~/components/ingrid-tracking/IngridTracking.vue')
)
Vue.component('IngridReturns', () =>
  import('~/components/ingrid-returns/IngridReturns.vue')
)
Vue.component('HeroButton', () => import('~/components/button/HeroButton.vue'))
Vue.component('TopbarPanel', () =>
  import('~/components/header/topbar/TopbarPanel.vue')
)
Vue.component('IngridTracking', () =>
  import('~/components/ingrid-tracking/IngridTracking.vue')
)
Vue.component('Trustpilot', () =>
  import('~/components/trustpilot/Trustpilot.vue')
)

Vue.component('DepictRecommendations', () =>
  import('~/components/depict/DepictRecommendations.vue')
)

Vue.component('LanguageSelectorPopup', () =>
  import('~/components/languageSelectorPopup/LanguageSelectorPopup.vue')
)
