import { mapGetters } from 'vuex'

export default {
  name: 'ProductCardMixin',
  props: {
    product: {
      type: Object,
      required: true
    },
    image: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      centraProduct: null,
      selectedSize: null
    }
  },
  computed: {
    ...mapGetters({
      pricelist: 'frontend/pricelist',
      lineProduct: 'centra-cart/lineProduct'
    }),
    imageUrl () {
      if (this.image) {
        return this.image
      } else {
        return this.product.image_url
      }
    },
    /**
     * @deprecated - use product.productType
     */
    productType () {
      return this.product.productType
    },
    pricelistUri () {
      return this.pricelist.currency.uri
    },
    /**
     * @deprecated - use product.priceText
     */
    priceText () {
      return this.product.priceText
    },
    /**
     * @deprecated - use product.originalPriceText
     */
    originalPriceText () {
      return this.product.originalPriceText
    },
    /**
     * @deprecated - use product.priceLocal
     */
    price () {
      return this.product.priceLocal
    },
    /**
     * @deprecated - use product.originalPrice
     */
    originalPrice () {
      return this.product.originalPrice
    },
    /**
     * @deprecated - use product.isOnSale
     */
    isOnSale () {
      return this.product.isOnSale
    },
    salePercentage () {
      if (this.product.custom_fields?.[`discount_percent_${this.pricelistUri}`]?.length) {
        const percentage = ~~(this.product.custom_fields[`discount_percent_${this.pricelistUri}`][0])
        return `${percentage}% OFF`
      }
      return ''
    },
    variant () {
      const selectedVariantId = this.manuallySelectedVariantId || this.item.selected_variant_id
      return this.item.variants.find(it => it.id === selectedVariantId)
    },
    isInStock () {
      return this.product.quantity > 0
    }
  },
  methods: {
    async _loadProduct () {
      let product = this.$store.getters['centra-product/getProductById'](this.product.id)
      if (!product) {
        product = await this.$store.dispatch('centra-product/lookupProducts', [this.product.id])
      }
      return product
    }
  }
}
