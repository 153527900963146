import { isEmpty } from 'underscore'

export const accountToken = 'account_token'
export const createAccountToken = 'create_account'

export default {
  state: () => ({
    account: {},
    appliedPoints: '',
    token: '',
    createAccountFromCheckout: false,
  }),
  mutations: {
    setAccount (state, account) {
      state.account = account
    },
    removeAccount (state) {
      state.account = {}
    },
    setToken (state, token) {
      state.token = token
      localStorage.setItem(accountToken, token)
    },
    removeToken (state) {
      state.token = ''
      localStorage.removeItem(accountToken)
    },
    setAppliedPoints (state, points) {
      state.appliedPoints = points
    },

  },
  actions: {
    async initialize ({ dispatch, commit }) {
      const token = localStorage.getItem(accountToken)

      if (isEmpty(token)) {
        return
      }

      commit('setToken', token)
      await dispatch('getAccount')
    },

    async getAccount ({ dispatch, commit }) {
      try {
        const account = await this.$getAccount()
        commit('setAccount', account)
      } catch (e) {
        await dispatch('logout')
      }
    },

    async login ({ dispatch, commit }, credentials) {
      const { email, password } = credentials
      if (!email || !password) {
        throw new Error('Cannot login when missing credentials')
      }

      const { token } = await this.$login(email, password)
      if (!token) {
        throw new Error('failed to login')
      }

      commit('setToken', token)
      await dispatch('getAccount')
    },

    async register ({ dispatch, commit }, payload) {
      const { token } = await this.$register(payload)
      if (!token) {
        throw new Error('failed to register')
      }

      commit('setToken', token)
      await dispatch('getAccount')
    },

    logout ({ commit }) {
      commit('removeToken')
      commit('removeAccount')
    },
    setCreateAccount ({ commit }, newValue) {
      commit('setCreateAccount', newValue)
    },
  },
  getters: {
    appliedPoints: state => state.appliedPoints,
    account: state => state.account,
    token: state => state.token,
    isLoggedIn: (state) => {
      return !!state.token
    },
    createAccountFromCheckout: state => state.createAccountFromCheckout,
  },
}
