<template>
  <div
    class="modal-header"
    :class="[
      'icon--' + iconPosition,
      'title--' + titlePosition,
      {
        lowercase: !uppercase
      }
    ]"
  >
    <span
      v-if="displayIcon"
      class="icon"
      :class="'icon--' + iconSize"
      @click="close"
    >
      <slot v-if="customIconPassed" name="icon" />
      <CrossIcon v-else />
    </span>
    <div class="title">
      <span class="main" :style="{color: popupColor}">
        {{ title }}
      </span>
      <span class="sub">
        {{ subtitle }}
      </span>
      <slot />
    </div>
  </div>
</template>

<script>
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'ModalHeader',
  components: {
    CrossIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },

    /**
     * Title Position
     * 'left' or 'center'
     */
    titlePosition: {
      type: String,
      default: 'center'
    },

    /**
     * Icon Position
     * 'left' or 'right
     */
    iconPosition: {
      type: String,
      default: 'left'
    },
    iconSize: {
      type: String,
      default: 'small'
    },
    popupColor: {
      type: String,
      default: '#000000'
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    displayIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    customIconPassed () {
      return !!this.$slots.icon
    }
  },
  methods: {
    close (event) {
      /**
       * Emit close event to parent
       */
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-header {
    height: 5.6rem;
    margin: 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    color: $black;
    display: grid;
    grid-template-columns: 4.8rem auto 4.8rem;
    align-items: center;
    background: white;
    &.icon--left {
      grid-template-areas: "icon title none";
    }
    &.icon--right {
      grid-template-areas: "none title icon";
    }
    &.title--left {
      grid-template-areas: "title title icon";
      .title {
        justify-self: flex-start;
      }
    }
    &.title--right {
      grid-template-areas: "title title icon";
      .title {
        justify-self: flex-end;
      }
    }

    .title {
      padding: 0 1.6rem;
      grid-area: title;
      justify-self: center;
      .main {
        @include h--small;
      }
      .sub {
        @include p--medium;
      }
    }
    .icon {
      grid-area: icon;
      justify-self: center;
      cursor: pointer;
      svg {
        fill: $black;
      }

      &.icon--small {
        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }

      &.icon--medium {
        svg {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
    &.lowercase {
      .title {
        .main {
          text-transform: none;
        }
      }
    }
  }
</style>
