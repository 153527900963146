export function nl2br (str) {
  if (typeof str === 'string') {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
  }
  return ''
}

export function br2p (str) {
  let output = str
  output = output.replace(/<br\/>*/g, '</p><p>')
  output = output.replace(/<ul>*>/g, '</p><ul>')
  output = output.replace(/<\/ul>*>/g, '</ul><p>')
  return output
}

/**
 * This enables internal links embedded in text (markdown-html - richtext),
 * by simply removing the "/site/" from it.
 * It also adds target _blank to external links.
 * @param    {string}  str     String containing markup.
 * @returns  {string}  output  String containing the same markup but with modified links.
 */
export function sanitizePath (str) {
  let output = str
  output = output.replace(/href="http>*/g, 'target="_blank" href="http')
  output = output.replace(/href="\/site\/>*/g, 'href="/')
  output = output.replace(/href="\/.*\/site\/>*/g, 'href="/')
  return output
}

export function getHTML (str) {
  return sanitizePath(nl2br(str))
}

export function sanitizeDiscountVouchers (vouchers) {
  // the added vouchers in the cart is usually an Object, but when there are no vouchers, we get an Array.
  return Array.isArray(vouchers) ? {} : vouchers
}
