<template>
  <div
    v-if="product"
    :class="{ loading: loading }"
    class="desktop-product-hover"
  >
    <p v-if="!product.terminated" class="caption">
      {{ $t('Global_Add_To_Cart') }}
    </p>
    <div v-if="product.inStock && product.sizeOptions" class="size-swatches">
      <div
        v-for="(size, index) in product.sizeOptions"
        :key="'size-' + index"
        class="size"
      >
        <div
          v-if="size.quantity"
          class="label"
          @click.stop="addToCart(size)"
        >
          <span v-if="loading === size" class="loading">
            <span class="spinner2" />
          </span>
          <template v-if="size.label != ''">
            {{ size.label }}
          </template>
          <template v-else>
            {{ $t('Product_Size_Onesize') }}
          </template>
        </div>
        <div v-else class="label out-of-stock">
          <template v-if="size.label != ''">
            {{ size.label }}
          </template>
          <template v-else>
            {{ $t('Product_Size_Onesize') }}
          </template>
        </div>
      </div>
      <transition name="fade">
        <div v-if="confirming" class="confirmation">
          {{ $t('Global_Added_To_Cart') }}
        </div>
      </transition>
    </div>
    <div
      v-else-if="!product.inStock && product.sizeOptions && !product.terminated"
      class="restock-signup"
      @click="showProductRestockSidebar()"
    >
      <MailIcon />
      <span>
        {{ $t('PLP_notify_me') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AllowAddToCart from '~/mixins/AllowAddToCart.js'
import MailIcon from '~/assets/icons/mail.svg'
import Depict from '~/mixins/depict.js'

export default {
  name: 'ProductCardHover',
  components: { MailIcon },
  mixins: [AllowAddToCart, Depict],
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: null,
    confirming: false,
  }),
  computed: {
    product () {
      return this.$store.getters['centra-product/getProductById'](
        this.productId
      )
    },
    ...mapGetters({
      lineProduct: 'centra-cart/lineProduct',
    }),
    ...mapState({
      freeGiftPopupOpen: state => state.ui.freeGiftPopupOpen,
    }),
  },
  methods: {
    showProductRestockSidebar () {
      this.$store.dispatch('ui/showProductRestockSidebar', {
        items: this.product.sizeOptions,
        itemId: null,
      })
    },
    addToCart (size) {
      if (!this.allowAddToCart(size.value, size.quantity)) {
        return false
      }
      const addedItem = {
        productName: this.product.name,
        _product: {
          productSku: this.product.sku,
          product_type: this.product.product_type,
        },
        priceEachAsNumber: this.product.priceAsNumber,
        brandName: this.product.brandName,
        size: size.label,
      }
      this.loading = size
      this.$addToCart(this.product.productSku, size.label, 1, this.product.product_url)
        .then((cart) => {
          this.$store.dispatch('centra-cart/setCart', cart)
          this.gtm_trackAddToCart(addedItem, 1, true, 'plp')
          this.depict_trackAddToCart({ sku: this.product.productSku, sizeLabel: size.label })
          this.$nuxt.$emit('after_add_product_to_cart')
        })
        .catch((error) => {
          this.$notify({
            title: this.$t('Dialog_Add_To_Cart_Error_Title'),
            text: this.$t('Dialog_Add_To_Cart_Error_Message'),
            type: 'error',
          })
          console.error(error, 'product', this._name, {
            ...this._data,
            ...this._props,
          })
        })
        .finally(() => {
          this.loading = null
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.desktop-product-hover {
  background-color: transparent;
  .caption {
    @include p--small;
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }
  .restock-signup,
  .size-swatches {
    position: relative;
    display: flex;
    align-items: center;
    height: 3.6rem;
    background-color: $white;
  }
  .size-swatches {
    .size {
      flex-grow: 1;
      height: 100%;
      position: relative;
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          .label:not(.out-of-stock) {
            background-color: $grey-25;
          }
        }
      }
    }
    .label {
      font-size: 1rem;
      font-weight: bold;
      flex: 0 0 auto;
      height: 100%;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $grey-25;
        display: flex;
        justify-content: center;
        align-items: center;
        .spinner2 {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      &.out-of-stock {
        color: $grey-50;
        text-decoration: line-through;
        &:before,
        &:after {
          content: '\00a0\00a0';
        }
      }
    }
    .confirmation {
      color: $black;
      z-index: 2;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      @include p--small;
      font-size: 1rem;
      margin: 0;
    }
  }
  .restock-signup {
    justify-content: center;
    align-items: center;
    svg {
      height: 10px;
      width: 12px;
      margin-right: 8px;
    }
    span {
      line-height: 200%;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: $grey-25;
      }
    }
  }
  .color-swatches {
    background-color: $white;
    min-height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .swatch {
      margin: 0.4rem;
      position: relative;
      img {
        border-radius: 50%;
        width: 1.6rem;
        height: 1.6rem;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: 100%;
        border: 1px solid $peach-100;
        opacity: 0;
        pointer-events: none;
      }
      &.selected {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
</style>
