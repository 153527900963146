import { contains, isEmpty } from 'underscore'
import { transformProduct } from '~/store/centra-product'

export const suspendCentraCheckout = () => {
  if (window.CentraCheckout) {
    window.CentraCheckout.suspend()
  }
}

export const resumeCentraCheckout = () => {
  if (window.CentraCheckout) {
    window.CentraCheckout.resume()
  }
}

/**
 * Used in conjunction with browsing from success page to another page (check
 * loadCartOnMounted in layouts/default.vue)
 */
let fetchingCart = false

/**
 * Run something wrapped in suspend/resume
 *
 * @param callback
 * @returns {Q.Promise<any> | Promise<void>}
 */
export const withSuspendCentraCheckout = (callback) => {
  suspendCentraCheckout()
  return callback().finally(() => resumeCentraCheckout())
}

export default {
  namespaced: true,
  state () {
    return {
      /**
       * The current cart, if any. We store the entire centra object here instead
       * of turning it into something readable, and then we use getters to
       * reduce it as needed.
       *
       * This state should mutate every time any response receives a cart.
       */
      address: {},
      shippingAddress: {},
      selection: undefined,
      postToPaymentResponse: undefined,
      order: undefined,
      klarnaIsLoaded: false,
      ingridIsLoaded: false,
    }
  },
  mutations: {
    address: (state, address) => {
      state.address = address
    },
    shippingAddress (state, shippingAddress) {
      state.shippingAddress = shippingAddress
    },
    selection (state, selection) {
      if (selection && selection.items) {
        selection.items = selection.items.map((item) => {
          if (item._product) {
            item._product = transformProduct(this, item._product)
          }
          return item
        })
      }
      state.selection = selection
    },
    postToPaymentResponse (state, postToPaymentResponse) {
      state.postToPaymentResponse = postToPaymentResponse
    },
    order (state, order) {
      state.order = order
    },
    klarnaIsLoaded (state, klarnaIsLoaded) {
      state.klarnaIsLoaded = klarnaIsLoaded
    },
    ingridIsLoaded (state, ingridIsLoaded) {
      state.ingridIsLoaded = ingridIsLoaded
    },
  },
  actions: {
    updateAddress ({ state, commit }, address) {
      commit('address', { ...state.address, ...address })
      return Promise.resolve()
    },
    updateShippingAddress ({ state, commit }, shippingAddress) {
      commit('shippingAddress', {
        ...state.shippingAddress,
        ...shippingAddress,
      })
      return Promise.resolve()
    },

    setCart ({ commit }, cart) {
      commit('selection', cart)
    },

    /**
     * Add a voucher
     */
    addVoucher (
      { state, commit, rootState, rootGetters, dispatch, getters },
      voucher
    ) {
      const title = this._vm.$t('Voucher_Title', null, rootGetters)
      const successText = this._vm.$t('Voucher_Add_Success', null, rootGetters)
      const errorText = this._vm.$t('Voucher_Add_Error', null, rootGetters)
      dispatch('ui/disableUi', true, { root: true })
      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .post(`cart/voucher/${voucher}`)
          .then((response) => {
            this._vm.$notify({
              title,
              text: successText,
              type: 'success',
            })
            commit('selection', response.data)
            return response.data
          })
          .catch(() => {
            this._vm.$notify({
              title,
              text: errorText,
              type: 'error',
            })
          })
          .finally(() => {
            dispatch('ui/disableUi', false, { root: true })
          })
      )
    },

    /**
     * Remove a voucher
     */
    async removeVoucher (
      { state, commit, rootState, rootGetters, dispatch, getters },
      voucher
    ) {
      const title = this._vm.$t('Voucher_Title', null, rootGetters)
      const successText = this._vm.$t(
        'Voucher_Remove_Success',
        null,
        rootGetters
      )
      const errorText = this._vm.$t('Voucher_Remove_Error', null, rootGetters)
      try {
        await dispatch('ui/disableUi', true, { root: true })
        suspendCentraCheckout()
        const voucherRemovalResponse = await this.$backendApi.delete(
          `cart/voucher/${voucher}`
        )
        commit('selection', voucherRemovalResponse.data)
        this._vm.$notify({
          title,
          text: successText,
          type: 'success',
        })
      } catch (error) {
        this._vm.$notify({
          title,
          text: errorText,
          type: 'error',
        })
      } finally {
        resumeCentraCheckout()
        await dispatch('ui/disableUi', false, { root: true })
      }
    },

    /**
     * Add an item to cart
     */
    addItem ({ state, commit, rootState, dispatch, getters }, { item, productUrl }) {
      dispatch('ui/disableUi', true, { root: true })
      return withSuspendCentraCheckout(() =>
        this.$backendApi.post('cart/add', { item, productUrl: `${this.$config.baseUrl}/${productUrl}` }).then((response) => {
          const lineItemIdsBefore = state?.selection?.items.map(it => it.line)
          const newItems = response?.data?.items.filter(
            it => !lineItemIdsBefore.includes(it.line)
          )
          newItems.forEach((item) => {
            if (item.anyDiscount && item.totalPriceAsNumber === 0) {
              dispatch('ui/showFreeGiftPopup', { gift: item }, { root: true })
            }
          })
          commit('selection', response.data)
          dispatch('ui/disableUi', false, { root: true })
          return response.data
        })
      )
    },

    /**
     * Fetch an existing cart and replace it in the state. Since we always get
     * the complete cart we can always replace it without diffing things
     */
    fetchCart ({ state, commit }) {
      fetchingCart = true
      return this.$backendApi
        .get('cart')
        .then((response) => {
          if (response.status === 200) {
            commit('selection', response.data)
          } else {
            commit('selection', undefined)
          }
        })
        .catch((err) => {
          console.error(err, 'store', 'centra-cart', this.state)
        }).finally(() => {
          fetchingCart = false
        })
    },

    /**
     * Fetch the cart if it's not set
     *
     * Fetching the cart will create a new one if no cart is set in a cookie.
     */
    fetchCartIfNotLoaded ({ getters, dispatch }) {
      if (getters.cart || fetchingCart) {
        return Promise.resolve()
      }
      return dispatch('fetchCart')
    },

    /**
     * Updates the quantity of a cart item, useful in minicarts and checkout
     * overviews
     */
    updateItem (
      { state, rootState, dispatch, commit, getters },
      { item, quantity }
    ) {
      dispatch('ui/disableUi', true, { root: true })
      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .put(`cart/update/${item}/${quantity}`)
          .then((response) => {
            commit('selection', response.data)
            return response.data
          })
          .finally(() => dispatch('ui/disableUi', false, { root: true }))
      )
    },

    changeSize ({ state, dispatch, commit }, { oldVariant, newVariant }) {
      dispatch('ui/disableUi', true, { root: true })
      return withSuspendCentraCheckout(() =>
        this.$backendApi
          .post(`cart/change-size/${oldVariant}/${newVariant}`)
          .then((response) => {
            commit('selection', response.data)
            return response.data
          })
          .finally(() => dispatch('ui/disableUi', false, { root: true }))
      )
    },

    changeColor ({ state, dispatch, commit }, { oldVariant, newVariant }) {
      dispatch('ui/disableUi', true, { root: true })
      return withSuspendCentraCheckout(() => this.$backendApi
        .post(`cart/change-color/${oldVariant}/${newVariant}`)
        .then((response) => {
          commit('selection', response.data)
          return response.data
        }).catch((err) => {
          console.error(err, 'store', 'centra-cart', state)
        })
        .finally(() => dispatch('ui/disableUi', false, { root: true }))
      )
    },

    /**
     * Calls checkout-fields to keep the customer address in sync with
     * the Centra selection address for the current session
     */
    checkoutUpdateFields ({ commit, dispatch }, fields) {
      dispatch('ui/disableUi', true, { root: true })
      const $notify = this._vm.$notify
      return withSuspendCentraCheckout(() =>
        dispatch('checkoutUpdateFieldsWithoutCentraCheckoutSuspend', { fields, $notify })
      ).finally(() => dispatch('ui/disableUi', false, { root: true }))
    },

    checkoutPaymentCallback (_vuex, eventData) {
      return this.$backendApi
        .post('cart/checkout/adyen-post-payment', eventData)
        .then(response => response.data)
        .catch((error) => {
          console.error('Error POSTing adyen payment', error)
          return error?.response?.data ?? {}
        })
    },

    /**
     * Fetch order by receipt
     */
    paymentResultByReceipt ({ commit, dispatch }, receiptId) {
      return this.$backendApi
        .get(`/cart/checkout/order-by-receipt/${receiptId}`)
        .then((response) => {
          if (response.data) {
            // All good man, clean the cart and stuff
            commit('order', response.data)
            commit('selection', undefined)
            dispatch('setPostToPaymentResponse', undefined)
          }
          return response.data
        })
    },

    /**
     * Call checkout update fields without suspending the checkout script
     *
     * @param commit
     * @param dispatch
     * @param fields
     * @return {*}
     */
    async checkoutUpdateFieldsWithoutCentraCheckoutSuspend (
      { commit, dispatch },
      { fields, $notify }
    ) {
      try {
        const response = await this.$backendApi.put(
          'cart/checkout/update-fields',
          fields
        )
        commit('selection', response.data)
        return response.data
      } catch (e) {
        if (e.response?.data?.messages) {
          const messages = Object.entries(e.response.data.messages).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          )
          $notify({
            message: 'Error updating fields',
            text: messages.join(', '),
            type: 'error',
          })
        }
        throw e
      }
    },

    /**
     * Proxy payment result stuff to centra to see what they say about it
     *
     * @see https://docs.centra.com/guides/shop-api/payment-method-flows#payment-result-types
     */
    paymentResult ({ commit, dispatch }, paymentFields) {
      return this.$backendApi
        .post('cart/checkout/payment-result', paymentFields)
        .then((response) => {
          if (response.data.order) {
            commit('order', response.data)
            commit('selection', undefined)
            dispatch('setPostToPaymentResponse', undefined)
          } else {
            console.error('centra-cart/paymentResult didn\'t return an order', {
              paymentFields: JSON.stringify(paymentFields),
              responseData: JSON.stringify(response.data)
            })
          }
          return response.data
        })
    },

    cleanCart ({ commit, dispatch }) {
      commit('selection', undefined)
      dispatch('setPostToPaymentResponse', undefined)
    },

    /**
     * Sets the country for the selection, might destroy it
     */
    setCountry ({ commit }, countryCode) {
      withSuspendCentraCheckout(() => {
        return this.$backendApi
          .put(`cart/country/${countryCode}`)
          .then((response) => {
            commit('selection', response.data)
            return Promise.resolve(response.data)
          })
      })
    },
    setKlarnaIsLoaded ({ commit }, klarnaIsLoaded) {
      commit('klarnaIsLoaded', klarnaIsLoaded)
    },
    setIngridIsLoaded ({ commit }, ingridIsLoaded) {
      commit('ingridIsLoaded', ingridIsLoaded)
    },
    initiatePayment ({ commit, dispatch }) {
      suspendCentraCheckout()
      return this.$backendApi
        .post('cart/checkout/initiate-payment')
        .then((response) => {
          commit('selection', response.data.cart)
          dispatch('setPostToPaymentResponse', response.data.payment)
        })
        .catch((error) => {
          const responseBody = error?.response?.data ?? undefined
          if (responseBody === undefined) {
            this._vm.$notify({
              message: 'Unknown error',
              text: 'Please reload the page or contact support',
              type: 'error',
            })
            return
          }
          const message = responseBody?.message
          if (typeof message === 'string') {
            this._vm.$notify({
              message,
              text: message,
              type: 'error',
            })
          }
          if (responseBody?.retry === true) {
            // If the backend returns retry: true this can be retried one more time.
            return dispatch('initiatePayment')
          }
        })
        .finally(() => {
          resumeCentraCheckout()
        })
    },
    async updatePayment ({ commit, dispatch }) {
      suspendCentraCheckout()
      await this.$backendApi
        .post('cart/checkout/update-payment')
        .then((response) => {
          commit('selection', response.data.cart)
          dispatch('setPostToPaymentResponse', response.data.payment)
        })
        .catch((error) => {
          console.error('Error POSTing ingrid update-payment', error)
          return null
        })
        .finally(() => {
          resumeCentraCheckout()
        })
    },
    async placeOrder ({ commit, dispatch }) {
      suspendCentraCheckout()
      const response = await this.$backendApi.post('cart/checkout/place-order')
      commit('selection', response.data.cart)
      dispatch('setPostToPaymentResponse', response.data.payment)
      resumeCentraCheckout()
    },
    setPostToPaymentResponse ({ commit }, postToPaymentResponse) {
      commit('postToPaymentResponse', postToPaymentResponse)
    },
  },
  getters: {
    address: state => state.address,
    shippingAddress (state) {
      return state.shippingAddress
    },
    productReachedMaxQtyPerOrder: state => id =>
      state.selection?.restrictedQtyPerOrderGroup?.includes(id),
    cart (state) {
      return state.selection
    },
    /**
     * Get centra POST selection/ID/payment response
     *
     * @returns *|undefined
     */
    postToPaymentResponse (state, getters) {
      const cart = getters.cart
      if (cart === undefined) {
        return undefined
      }
      const postToPaymentResponse = state.postToPaymentResponse
      if (postToPaymentResponse === undefined) {
        return undefined
      }
      if (typeof postToPaymentResponse.order === 'string') {
        // This means, I think, that an order was placed.
        // When orders are placed the response format differs and 'formType' will not be set.
        return postToPaymentResponse
      }
      if (cart.paymentMethod !== postToPaymentResponse.formType) {
        // Prevent old postToPaymentResponse from being used
        return undefined
      }
      return postToPaymentResponse
    },
    order (state) {
      return state.order
    },
    ingridIsLoaded: state => state.ingridIsLoaded,
    klarnaIsLoaded: state => state.klarnaIsLoaded,
    isAdyen: (state) => {
      return state.selection.paymentMethod === 'adyen-drop-in'
    },
    cartItemByValue: state => value =>
      state.selection?.items.find(x => x.item === value),
    lineProduct: state =>
      state.selection?.items.find(item => item.line === state.selection.line),
    /**
     * Returns true if there is one voucher in Cart.
     * The voucher may origin from Yotpo or be a manually added code.
     */
    voucherInCart: (state, getters, rootState, rootGetters) => {
      const vouchers = state.selection.discounts.vouchers
      const vouchersInCart = Object.keys(vouchers).length
      return (vouchersInCart === 1) || vouchersInCart === 2
    },
    hasAddress: (state) => {
      const address = state.selection.address
      const requiredAddressFields = [
        'firstname',
        'latsname',
        'email',
        'country',
      ]

      let result = true
      for (const [key, value] of Object.entries(address)) {
        if (isEmpty(value) && contains(requiredAddressFields, key)) {
          result = false
        }
      }
      return result
    },
    /**
     * @deprecated The return value of this getter means nothing.
     */
    hasPayment: (state) => {
      return !isEmpty(state.selection.paymentMethod)
    },
    hasPostToPaymentResponse (_state, getters) {
      return getters.postToPaymentResponse !== undefined
    },
    isFreeOrder: (state, getters) => {
      const cart = getters.cart
      if (cart === undefined) {
        return false
      }
      if (cart.items.length === 0) {
        return false
      }
      return cart.totals?.grandTotalPriceAsNumber === 0
    },
    isQliro: (state) => {
      return state.selection.paymentMethod === 'qliro'
    },
    isFreeOrderThroughPaymentGateway: (state, getters) => {
      return getters.isQliro
    },
    selectionId: (state) => {
      return state.selection?.selection || ''
    },
  },
}
