export default ($backendApi, context) => ({
  sendCheckoutStartedEvent: async (selectionId) => {
    await $backendApi.post('cart/events/checkout-started', {
      selectionId
    }, {
      headers: {
        country: context.getCurrentCountryCode(),
        language: context.getCurrentLanguageCode(),
      }
    })
  }
})
