<template>
  <div
    class="badge"
    :class="type"
    :style="type === 'custom' && { backgroundColor: bgHex, color: textHex }"
  >
    <IconOrganicFood v-if="type === 'organic-cotton'" />
    <span class="label">
      {{ label }}
    </span>
  </div>
</template>

<script>
import IconOrganicFood from '~/assets/icons/organic-food.svg'
export default {
  name: 'ProductBadge',
  components: {
    IconOrganicFood,

  },
  props: {
    label: {
      type: String,
      required: true
    },

    bgHex: {
      type: String,
      required: false,
      default: '#ffffff'

    },
    textHex: {
      type: String,
      required: false,
      default: '#000000'
    },
    /**
     * Can be 'sale' etc.
     * Applies a class that can be used for styling
     */
    type: {
      type: String,
      required: true,
      default: 'custom'
    }
  }
}
</script>

<style lang="scss" scoped>
  .badge {
    max-width: 14rem;
    display: flex;
    align-items: center;
    padding: 0 .7rem;
    font-size: 1rem;
    letter-spacing: .06em;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.8em;
    color: $black;
    background: $white;
    height: 3rem;

    &--small {
      height: unset;
    }

    svg {
      margin-right: .7rem;
      height: 1.5rem;
      &.white {
        fill: $black;
      }
    }
    .label {
      margin-top: .1rem;
    }
    &.coming-soon {
      background: $white;
      color: $black;
    }
    &.sale {
      background: $badge-red;
      color: $white;
    }

    &.watch-video {
      font-weight: 500;
      text-decoration-line: underline;
      text-transform: none;
    }
  }

  // Tablet (Portrait)
  @media screen and (min-width: $tablet) {
  }

  // Tablet (Landscape)
  @media screen and (min-width: $tablet-landscape) {
    .badge {
      font-size: 1.1rem;
    }
  }

  // Laptop
  @media screen and (min-width: $laptop) {
    .badge {
      font-size: 1.2rem;
      line-height: 1.9em;
      max-width: 30rem;
    }
  }

  // Desktop
  @media screen and (min-width: $desktop) {
  }
</style>
