<template>
  <div>
    <nuxt />
  </div>
</template>

<script>
export default {
  name: 'BlankLayout'
}
</script>

<style lang="scss" scoped>
  .root {
    height: 100%;
    .sidebar,
    .size-selector {
      position: fixed;
      z-index: 100;
      &.left {
        top: 0;
        left: 0;
      }
      &.right {
        top: 0;
        right: 0;
      }
      &.bottom {
        bottom: 0;
        left: 0;
      }
    }
    .search-results-wrapper {
      position: absolute;
      // z-index set to lower than header (10) but higher than overlay (which is set to 8 in this use-case)
      z-index: 9;
      width: 100%;
    }
  }

  // Tablet (Landscape)
  @media screen and (min-width: $tablet-landscape) {
    .root {
      .search-results-wrapper {
        width: calc(100% - 42rem);
        left: 21rem;
      }
    }
  }
</style>
