/**
 * Mixin that adds images processing for Fastly
 * https://docs.fastly.com/
 */
export const imageBaseUrl = 'https://imgproxy.strongerlabel.com/preset:sharp/'

export default {
  props: {
    mobileSrc: {
      type: String,
      default () {
        return null
      }
    },
    desktopSrc: {
      type: String,
      default () {
        return null
      }
    },
    alt: {
      type: String,
      default () {
        return ''
      }
    },
    caption: {
      type: String,
      default () {
        return null
      }
    },
    mobileDisplaySize: {
      type: String,
      default () {
        return '100vw'
      }
    },
    desktopDisplaySize: {
      type: String,
      default () {
        return '100vw'
      }
    },
    lazyload: {
      type: Boolean,
      default () {
        return false
      }
    },
    imgSettings: {
      type: Object,
      default () {
        return {}
      }
    },
    imgSettingsDesktop: {
      type: Object,
      default () {
        return {}
      }
    },
    ratio: {
      type: Object,
      default () {
        return {}
      }
    },
    breakpoint: {
      type: Number,
      required: false,
      default: 1024
    },
  },
  data () {
    return {
      showing: !this.lazyload,
      pagePosition: 0 // position in px from top this image is rendered
    }
  },
  computed: {
    desktopSettings () {
      return { ...this.imgSettings, ...this.imgSettingsDesktop }
    },
    isSvg () {
      let fileformat
      if (this.mobileSrc) {
        fileformat = this.mobileSrc.split('.').pop()
      } else {
        fileformat = this.desktopSrc.split('.').pop()
      }
      return (fileformat === 'svg')
    },
    desktopSrcOut () {
      return this.desktopSrc || this.mobileSrc
    },
    /**
     * Check if the image processor supports web p
     * @return {boolean}
     */
    supportsWebP () {
      /**
       * Temporary fix since all stronger images are jpg now,
       * @todo fix this when we have cloudfront support
       */
      return true
    },
    /**
     * Check if we have a ratio, either auto or manually set
     */
    hasRatio () {
      if (this.caption) {
        return false
      }
      if (this.ratio.width && this.ratio.height) {
        return true
      }
      if (this.ratio.type) {
        const dimensions = this.detectDimensions(this.mobileSrc)
        return (dimensions)
      }
      return false
    },
    /**
     * Calculate image ratio and add as css variables
     * @return {string}
     */
    cssVariables () {
      if (this.ratio.type) {
        const mobile = this.detectDimensions(this.mobileSrc)
        const desktop = this.detectDimensions(this.desktopSrcOut)
        if (mobile && desktop) {
          return '--desktop-ratio: ' + ((desktop.height / desktop.width) * 100) + '%;' +
            '--mobile-ratio: ' + ((mobile.height / mobile.width) * 100) + '%;'
        }
      }
      if (this.ratio.width && this.ratio.height) {
        const desktopH = this.ratio.desktopHeight || this.ratio.height
        const desktopW = this.ratio.desktopWidth || this.ratio.width
        return '--desktop-ratio: ' + ((desktopH / desktopW) * 100) + '%;' +
          '--mobile-ratio: ' + ((this.ratio.height / this.ratio.width) * 100) + '%;'
      }
      return ''
    }
  },
  mounted () {
    if (!this.showing) {
      this.setPagePosition()
      this.showing = this.checkInView()

      if (!this.showing) {
        document.addEventListener('scroll', this.scrolled, { passive: true })
      }
      window.addEventListener('resize', this.resize)
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.scrolled)
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    /**
     * Get all sizes need for <picture> srcset
     * @param {string} imgSrc
     * @param {object} settings
     * @param {string} device
     * @return {string}
     */
    getSrcset (imgSrc, settings, device = 'mobile') {
      const output = []
      let sizes = device === 'mobile'
        ? [320, 640, 720, 960, 1024, 1440, 1920] // mobile sizes
        : [320, 640, 720, 960, 1024, 1440, 1920, 2560] // desktop sizes
      // Remove upscaled image
      const dimensions = this.detectDimensions(imgSrc)
      if (dimensions) {
        sizes = sizes.filter(x => x <= dimensions.width)
      }
      for (const w in sizes) {
        output.push(
          this.processImgproxy(imgSrc, { width: sizes[w], ...settings }) + ' ' + sizes[w] + 'w'
        )
      }

      return output.join(', ')
    },
    processImgproxy (url, attr) {
      const quality = attr.quality ? attr.quality : '70'
      let width = attr.width
      if (attr.ratio && attr.ratio > 0) {
        width += ':' + Math.round(attr.width * attr.ratio)
      }
      // Fix for storyblok
      url = url.replace(/^\/\/a\./, '//img2.')
      url = url.replace(/^\/\//, 'https://')

      const def = `preset:sharp/rs:fit:${width}/g:nowe/q:${quality}`
      return this.$imgproxy.transform(url, def)
    },

    /**
     * Detects dimensions of original image
     * currently only works with storyblok
     * @param url
     * @return {null|{width: *, height: *}}
     */
    detectDimensions (url) {
      if (/a.storyblok.com\//.test(url)) {
        const dimensions = RegExp(/\/(\d*)x(\d*)\//, 'g').exec(url)
        if (dimensions) {
          return { width: dimensions[1], height: dimensions[2] }
        }
      }
      return null
    },

    // Check if image is in viewport
    checkInView () {
      // Scroll position + viewport height + margin
      return (window.scrollY + window.innerHeight + 150) >= this.pagePosition
    },
    resize () {
      this.setPagePosition()
      this.scrolled()
    },
    setPagePosition () {
      this.pagePosition = Math.round(window.scrollY + this.$refs.figure?.getBoundingClientRect().top)
    },
    scrolled () {
      if (this.checkInView()) {
        this.showing = true
        document.removeEventListener('scroll', this.scrolled)
        window.removeEventListener('resize', this.resize)
      }
    },
    imageLoaded () {
      this.$emit('imageLoaded')
    }
  }

}
