import { render, staticRenderFns } from "./ColorSwatches.vue?vue&type=template&id=430c6291&scoped=true"
import script from "./ColorSwatches.vue?vue&type=script&lang=js"
export * from "./ColorSwatches.vue?vue&type=script&lang=js"
import style0 from "./ColorSwatches.vue?vue&type=style&index=0&id=430c6291&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430c6291",
  null
  
)

export default component.exports