<template>
  <div
    class="toggle-menu"
    title="Open menu"
    @click="showMobileSidebar"
  >
    <div>
      <HamburgerIcon />
    </div>
  </div>
</template>

<script>
import HamburgerIcon from '~/assets/icons/hamburger.svg'

export default {
  name: 'ToggleMenu',
  components: { HamburgerIcon },
  methods: {
    showMobileSidebar () {
      this.$store.dispatch('ui/showMobileSidebar')
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-menu {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      fill: white;
    }
  }
}
</style>
