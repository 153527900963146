var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-usps",class:'bg-color--' + _vm.backgroundColor,style:([
    _vm.foregroundColorCss,
    _vm.backgroundColorCss
  ])},[_c('div',{staticClass:"di di--1"},[(_vm.usps)?_c('div',{staticClass:"wrapper",class:[
        'mc--' + _vm.mobileColumns,
        'dc--' + _vm.desktopColumns
      ]},_vm._l((_vm.usps),function(usp,index){return _c('ShopUsp',_vm._b({key:'usp-' + index},'ShopUsp',usp,false))}),1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }