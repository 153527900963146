// This could be turned into a storyblok setting i suppose, but
// it has to be selectable from real values in that case. That means
// lowercase country codes of all countries that have both a market
// and a pricelist
import { find } from 'underscore'
import { ContextError } from '@made-people/centra-storyblok-nuxt-shared/lib/store/frontend'
import isoCountries from '~/lib/iso-countries'

// Putting countries in order here will put them first in the selector in the header
export const countrySortOrder = []

/**
 * 'route' should be a vue-router route object
 */
export const routeToLookupParts = (route, store) => {
  // Set up defaults
  const parts = {
    country: 'se',
    storyblokEntry: '',
    language: null, // if not in url we use the country default from centra
  }

  if (route.query._storyblok) {
    // Inside storyblok edit mode
    parts.language =
      route.query._storyblok_lang === 'default'
        ? 'en'
        : route.query._storyblok_lang
    parts.storyblokEntry = route.query._storyblok
  } else {
    const routeParts = route.fullPath.split('/')

    const splitSlug = routeParts[1].split('-')
    parts.country = splitSlug[0].split('?')[0]
    if (splitSlug[1]) {
      parts.language = splitSlug[1]
    }
    parts.storyblokEntry = routeParts.slice(2).join('/')
  }

  if (!isValidCountry(parts.country)) {
    return {
      ...parts,
      country: '',
    }
  }
  /**
   * Checking if the country has a market, if not we return an empty country
   */
  if (!store.getters['frontend/getMarketByCountryCode'](parts.country)) {
    return {
      ...parts,
      country: '',
    }
  }
  return parts
}

function isValidCountry (country) {
  const countryCodes = Object.keys(isoCountries)
  return !!find(
    countryCodes,
    code => code.toLowerCase() === country?.toLowerCase()
  )
}

export default {
  state () {
    return {
      currentCountryCode: null,
      currentLanguageCode: null,
      currentContext: null,
      countries: [],
      languages: [],
    }
  },

  mutations: {
    currentCountryCode (state, countryCode) {
      state.currentCountryCode = countryCode.toLowerCase()
    },
    currentLanguageCode (state, languageCode) {
      state.currentLanguageCode = languageCode.toLowerCase()
    },
    countries (state, countries) {
      state.countries = countries
    },
    languages (state, languages) {
      state.languages = languages
    },
    currentContext (state, context) {
      state.currentContext = context
    },
  },

  actions: {
    async setCurrentSite ({ state, dispatch }, lookupParts) {
      await dispatch(
        'setCurrentCountryCode',
        lookupParts.country?.toUpperCase()
      )
      const languageCode = lookupParts.language
        ? lookupParts.language
        : state.countries.find(
          x =>
            x.country?.toUpperCase() === lookupParts.country?.toUpperCase()
        ).language
      return await dispatch('setCurrentLanguageCode', languageCode)
    },

    setCurrentCountryCode ({ state, commit, rootGetters }, countryCode) {
      const country = state.countries.find(x => x.country === countryCode)
      if (!country) {
        throw new ContextError(`Country "${countryCode}" doesn't exist in any context`)
      }
      commit('currentCountryCode', countryCode)
    },

    async setCurrentLanguageCode ({ state, commit, dispatch }, languageCode) {
      const language = state.languages.find(
        it => it.language === languageCode
      )
      if (!language?.language) {
        // console.log(`Language ${languageCode} is invalid, frontend will now CRASH and the url is this: ${this?.$router?.currentRoute?.fullPath}`)
        throw new ContextError(`Language ${languageCode} is invalid`)
      }

      commit('currentLanguageCode', language.language)
      return await dispatch('setCurrentLanguage', language)
    },

    setCurrentLanguage ({ state, commit, dispatch }, language) {
      const foundLanguage = state.languages.find(
        it => it.language === language.language
      )
      if (!foundLanguage) {
        throw new ContextError(`Language ${language.language} is invalid`)
      }
    },
    setCurrentContext ({ state, commit }, context) {
      commit('currentContext', context)
    },
  },

  getters: {
    market: (state, getters, rootState) =>
      rootState['centra-market'].markets.find(
        x => x.market === getters.currentMarketId
      ),
    pricelist: (state, getters, rootState) =>
      rootState['centra-pricelist'].pricelists.find(
        x => x.pricelist === getters.currentPricelistId
      ),

    /**
     * These getters exist only so that we can automagically get the current
     * country/language code inside the centra client and not having to pass it in every
     * call all the time manually
     */
    currentCountry: state =>
      state.countries.find(
        x =>
          x.country?.toUpperCase() === state.currentCountryCode?.toUpperCase()
      ),
    currentCountryCode: state => state.currentCountryCode?.toUpperCase(),
    currentLanguageCode: state => state.currentLanguageCode,
    currentLanguageCodeIso: (state) => {
      const centraLanguage = state.languages.find(l => l.language === state.currentLanguageCode)
      if (!centraLanguage) {
        console.warn(`Language ${state.currentLanguageCode} has no match in Centra, this shouldn't happen`)
        return 'no-centra-language-found'
      }
      return `${centraLanguage.iso639}-${centraLanguage.iso3166}`
    },
    currentMarketId: (state, getters, rootState) =>
      rootState.campaign.market ||
      state.currentContext?.market ||
      getters.currentCountry?.market,
    currentPricelistId: (state, getters) => getters.currentCountry?.pricelist,
    currentContext: state => state.currentContext,
    currentLanguage: state =>
      state.languages.find(it => it.language === state.currentLanguageCode),
    currentSiteLangSlug: (state, getters) => {
      const slugParts = [state.currentCountryCode]
      if (getters.currentCountry && (state.currentLanguageCode !== getters.currentCountry?.language)) {
        slugParts.push(state.currentLanguageCode)
      }
      return slugParts.join('-')
    },

    getMarketByCountryCode: state => countryCode =>
      state.countries.find(
        x => x.country?.toUpperCase() === countryCode?.toUpperCase()
      )?.market,
    getCurrencyCodeByCountryCode:
      (state, getters, rootState) => (countryCode) => {
        const pricelistId = state.countries.find(
          x => x.country?.toUpperCase() === countryCode?.toUpperCase()
        )?.pricelist
        return rootState['centra-pricelist'].pricelists.find(
          x => x.pricelist === pricelistId
        )?.currency.currency
      },
    currentCurrency: (state, getters) =>
      getters.getCurrencyCodeByCountryCode(getters.currentCountryCode),

    /**
     * Sort the delivery countries set up in market depending on how big they are
     * as told by the client
     */
    countriesSorted (state, getters, rootState, rootGetters) {
      const countries = [...state.countries]

      // First, sort alphabetically
      countries.sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
      )

      // Then, put the main countries first
      countries.sort((a, b) => {
        if (
          countrySortOrder.includes(a.name) &&
          !countrySortOrder.includes(b.name)
        ) {
          return -1
        } else if (
          !countrySortOrder.includes(a.name) &&
          countrySortOrder.includes(b.name)
        ) {
          return 1
        } else {
          return (
            countrySortOrder.indexOf(a.name) - countrySortOrder.indexOf(b.name)
          )
        }
      })

      return countries
    },

    marketCountriesSorted (state, getters, rootState, rootGetters) {
      return getters.countriesSorted.filter(
        x => x.market === getters.currentMarketId
      )
    },

    // Because i proxy through a getter we can get countries from API later
    availableLanguages: state => state.languages,
  },
}
