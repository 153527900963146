import { each } from 'underscore'
import Centra from '~/services/centra'

export default ({ store }, inject) => {
  const context = {
    pricelists: () => store.getters['centra-pricelist/getPricelists'],
    currentPriceList: () => store.getters['frontend/currentPricelistId'],
    marketId: () => store.getters['frontend/currentMarketId'],
    language: () => store.getters['frontend/currentLanguageCode'],
  }

  const methods = {
    ...Centra(context)
  }

  const injector = method => inject(method, methods[method])
  each(Object.keys(methods), injector)
}
