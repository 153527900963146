<template>
  <CentraStaticResultsController
    :key="resultsKey"
    :child-component="'Hotspot'"
    :child-props="$attrs"
    :product-skus="centraProductSkus"
  />
</template>
<script>
import CentraStaticResultsController from '~/components/centra/CentraStaticResultsController'

export default {
  name: 'HotspotController',
  components: { CentraStaticResultsController },
  props: {
    limitProducts: {
      type: String,
      default: '15'
    },
    hotspotTitle: {
      type: String,
      default: ''
    },
    centraProducts: {
      type: [Object, String],
      default: null
    }
  },
  data () {
    return {
      resultsKey: 'results'
    }
  },
  computed: {
    centraProductSkus () {
      return this.centraProducts?.tbody?.map(it => it.body[0].value).filter(it => !!it)
    },
  },
}
</script>
