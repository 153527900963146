export function state () {
  return {
    token: ''
  }
}

export const mutations = {
  setToken (state, payload) {
    state.token = payload
  }
}

export const getters = {
  token: state => state.token
}
