<template>
  <div
    :style="[
      textColorCss,
      bottomPlateColorCss,
      backgroundColorCss
    ]"
    class="hero"
    :class="[
      {
        plate: diagonalPlate !== '',
        tint,
        'm--hide-hero': hideMobile,
        'd--hide-hero': hideDesktop
      },
      'm--' + mobileSize,
      'd--' + desktopSize,
      'ms--' + mobileDisplaySize,
      'ds--' + desktopDisplaySize,
      styleModifier ? 'sm--' + styleModifier : ''
    ]"
  >
    <div class="ratio">
      <VideoBlock
        v-if="mobileVideo || desktopVideo"
        :mobile-video="mobileVideo"
        :desktop-video="desktopVideo"
        :video-loop="true"
        :video-autoplay="true"
        :video-controls="false"
        :mobile-poster="mobileImage"
        :desktop-poster="desktopImage"
      />
      <ResponsiveImage
        v-if="mobileImage.filename || desktopImage.filename"
        :class="[
          mobileVideo ? 'm--hide' : '',
          desktopVideo ? 'd--hide' : '',
        ]"
        class="hero-image"
        :mobile-src="mobileImage.filename ? mobileImage.filename : ''"
        :desktop-src="desktopImage.filename ? desktopImage.filename : ''"
        :alt="mobileImage.alt ? mobileImage.alt : ''"
        :mobile-display-size="mobileDisplaySize"
        :desktop-display-size="desktopDisplaySize"
        :lazyload="true"
      />
      <template v-for="(tag, index) in tags">
        <Tag
          v-if="tag.type === 'tag'"
          :key="index"
          :visible="tag.visible"
          :text="tag.text"
          :position="tag.position"
          :text-color="tag.textColor"
          :background-color="tag.backgroundColor"
          :background-color-hex="tag.backgroundColorHex"
        />
        <Ribbon
          v-if="tag.type === 'ribbon'"
          :key="index"
          :visible="tag.visible"
          :text="tag.text"
          :position="tag.position"
          :text-color="tag.textColor"
          :background-color="tag.backgroundColor"
          :background-color-hex="tag.backgroundColorHex"
        />
      </template>
      <div
        v-if="eyebrow || headline || ctas"
        :class="contentPosition"
        class="hero-content"
      >
        <div
          v-if="eyebrow"
          class="eyebrow"
          :class="eyebrowFont"
          v-html="nl2br(eyebrow.html)"
        />
        <component
          :is="headlineType"
          v-if="headline"
          class="headline"
          :class="[
            headlineSize,
            (headline && desktopHeadline && desktopHeadline.html !== '') || hideMobileHeadlineOnDesktop ? 'hide' : '',
          ]"
          v-html="nl2br(headline.html)"
        />
        <component
          :is="headlineType"
          v-if="desktopHeadline && desktopHeadline.html !== ''"
          class="headline headline-desktop"
          :class="headlineSize"
          v-html="nl2br(desktopHeadline.html)"
        />
        <div class="hero-button">
          <Button
            v-for="(button, index) in ctas"
            :key="index"
            v-bind="button"
            :inherited-link="overwriteCtaLinks ? link : {}"
          />
        </div>
      </div>
      <HotspotController
        v-for="(hotspot, index) in hotspots"
        :key="'hotspot-' + index"
        :hotspot-title="hotspotTitle"
        v-bind="hotspot"
      />
      <mp-link
        v-if="$u(link) !== null"
        :to="$u(link)"
        class="link"
        :class="{ 'hover-effect': hoverEffect }"
      />
    </div>
  </div>
</template>

<script>
import Tag from '~/components/tag/Tag'
import Ribbon from '~/components/ribbon/Ribbon'
import HotspotController from '~/components/hotspots/HotspotController.vue'
import ResponsiveImage from '~/components/image/ResponsiveImage.vue'
import ColorHexCode from '~/mixins/ColorHexCode.js'
import VideoBlock from '~/components/video-block/VideoBlock'
import { nl2br } from '~/lib/helpers'

export default {
  name: 'Hero',
  components: {
    Tag,
    Ribbon,
    HotspotController,
    ResponsiveImage,
    VideoBlock
  },
  mixins: [ColorHexCode],
  props: {
    hideMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    mobileSize: {
      type: String,
      required: true
    },
    mobileImage: {
      type: Object,
      required: false,
      default: null
    },
    mobileVideo: {
      type: String,
      required: false,
      default: ''
    },
    alt: {
      type: String,
      required: true
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    overwriteCtaLinks: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false
    },
    desktopSize: {
      type: String,
      required: true
    },
    desktopImage: {
      type: Object,
      required: false,
      default: null
    },
    desktopVideo: {
      type: String,
      required: false,
      default: ''
    },
    tint: {
      type: Boolean,
      required: true
    },
    diagonalPlate: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    tags: {
      type: Array,
      required: true
    },
    hotspots: {
      type: Array,
      default: () => ([])
    },
    hotspotTitle: {
      type: String,
      default: ''
    },
    contentPosition: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      required: true
    },
    eyebrow: {
      type: Object,
      required: true
    },
    eyebrowFont: {
      type: String,
      required: true
    },
    headline: {
      type: Object,
      required: true
    },
    desktopHeadline: {
      type: Object,
      required: false,
      default: null
    },
    headlineSize: {
      type: String,
      required: true
    },
    headlineType: {
      type: String,
      required: false,
      default: 'h2'
    },
    hideMobileHeadlineOnDesktop: {
      type: Boolean,
      default: false
    },
    ctas: {
      type: Array,
      required: true
    },
    hoverEffect: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    styleModifier: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100vw if not sent
     */
    mobileDisplaySize: {
      type: String,
      required: false,
      default: '100vw'
    },
    desktopDisplaySize: {
      type: String,
      required: false,
      default: '100vw'
    }
  },
  computed: {
    textColorCss () {
      return { '--text-color': this.textColor }
    },
    bottomPlateColorCss () {
      return { '--bottom-plate-color': this.diagonalPlate }
    },
    backgroundColorCss () {
      return { '--bg-color': this.color(this.backgroundColor, this.backgroundColorHex) }
    }
  },
  methods: {
    nl2br
  }
}
</script>

<style lang="scss" scoped>
.hero {
  position: relative;
  overflow: hidden;
  --bg-color: transparent;
  background: var(--bg-color);
  &.m--hide-hero {
    display: none;
  }
  .mobile-video,
  .desktop-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //display: inherit;
  }
  .desktop-video {
    display: none;
  }
  .hero-image {
    width: 100%;
    height: 100%;
    &::v-deep {
      picture {
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.m--hide {
      display: none;
    }
  }

  /**
   * Predefined Sizes
   */
  &.m--tall {
    @include aspect-ratio(320, 488);
  }
  &.m--wide {
    @include aspect-ratio(4, 3);
  }
  &.m--square {
    @include aspect-ratio(1, 1);
  }
  &.m--auto {
    // Do nothing really...
  }

  .tag {
    position: absolute;
    z-index: 3;
    &.top-left,
    &.top-right {
      top: 1.6rem;
    }
    &.bottom-left,
    &.bottom-right {
      bottom: 1.6rem;
    }
    &.top-left,
    &.bottom-left {
      left: 1.6rem;
    }
    &.top-right,
    &.bottom-right {
      right: 1.6rem;
    }
  }
  .ribbon {
    position: absolute;
    top: 0;
    z-index: 3;
    &.top-left {
      left: 0.8rem;
    }
    &.top-right {
      right: 0.8rem;
    }
  }

  /**
   * Adds a tint to the bottom part of the hero
   */
  &.tint .ratio {
    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 50%;
      width: 100%;
      left: 0px;
      bottom: 0px;
      background: linear-gradient(0deg, #000000 3.7%, rgba(0, 0, 0, 0) 95.76%);
      mix-blend-mode: multiply;
      opacity: 0.3;
      z-index: 1;
      pointer-events: none;
    }
  }

  /**
   * Adds a diagonal plate to the bottom part of the hero
   */
  &.plate .ratio {
    &:before {
      content: '';
      position: absolute;
      display: block;
      height: 100%;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: var(--bottom-plate-color);
      transform: skewY(-12deg) translateY(55%);
      z-index: 2;
      pointer-events: none;
    }
  }

  .link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: $black;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    &.hover-effect {
      @media (hover: hover) {
        &:hover {
          opacity: .3;
        }
      }
    }
  }

  .hero-content {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: var(--text-color);
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    pointer-events: none;

    /**
     * Content Positioning
     */
    &.left-top {
      justify-content: flex-start;
      text-align: left;
    }
    &.center-top {
      justify-content: flex-start;
      text-align: center;
    }
    &.right-top {
      justify-content: flex-start;
      text-align: right;
    }
    &.left-center {
      justify-content: center;
      text-align: left;
    }
    &.center-center {
      justify-content: center;
      text-align: center;
    }
    &.right-center {
      justify-content: center;
      text-align: right;
    }
    &.left-bottom {
      justify-content: flex-end;
      text-align: left;
    }
    &.center-bottom {
      justify-content: flex-end;
      text-align: center;
    }
    &.right-bottom {
      justify-content: flex-end;
      text-align: right;
    }

    .eyebrow {
      &::v-deep {
        > br:last-of-type {
          display: none;
        }
      }
      &.avenir {
        &::v-deep {
          p {
            @include h--mini;
            font-weight: 800;
            margin: 0;
          }
        }
      }
      &.alternate {
        &::v-deep p {
          @include h--mini;
          margin: 0;
          font-family: $font-alternate;
          letter-spacing: 0.24em;
        }
      }
      &.viva {
        &::v-deep p {
          font-family: $font-viva;
          font-size: 3.2rem;
          line-height: 90%;
          margin: 0;
          letter-spacing: 0.025em;
        }
      }
    }
    .headline {
      margin-top: 0.8rem;
      &-desktop {
        display: none;
      }
      &::v-deep {
        > br:last-of-type {
          display: none;
        }
        p em {
          -webkit-text-stroke-color: var(--text-color);
        }
      }
      &.small {
        &::v-deep p {
          @include h--small;
          margin: 0;
        }
      }
      &.medium {
        &::v-deep p {
          @include h--medium;
          margin: 0;
        }
      }
      &.large {
        &::v-deep p {
          @include h--large;
          margin: 0;
        }
      }
      &.x-large {
        &::v-deep p {
          @include h--x-large;
          margin: 0;
        }
      }
      &.huge {
        &::v-deep p {
          @include h--huge;
          margin: 0;
        }
      }
    }
    .hero-button {
      z-index: 1;
      .button {
        min-width: 18.4rem;
        margin-top: 1.6rem;
      }
    }
  }

  /**
   * Style Modifiers
   */
  &.sm--neon-text {
    .hero-content {
      .headline {
        font-style: normal;
        color: transparent;

        // background-color: #E35BCE;
        // text-shadow: 2px 2px 3px red;
        // -webkit-background-clip: text;
        // -moz-background-clip: text;
        // background-clip: text;

        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fae1f2;
        -webkit-font-smoothing: antialiased;
        filter: drop-shadow(0 0 2px #fae1f2) drop-shadow(0 0 1px #E35BCE) drop-shadow(0 0 3px #E35BCE);
        // text-shadow:
        //   0 0 5px transparent;
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .hero {
    /**
     * Predefined Sizes
     */
    &.m--tall {
      @include aspect-ratio(1, 1.2);
    }
    &.m--wide {
      @include aspect-ratio(4, 3);
    }
    &.m--square {
      @include aspect-ratio(1, 1);
    }
    &.m--auto {
      // Do nothing really...
    }
    .hero-content {
      .headline.hide {
        display: none;
        & + .headline-desktop {
          display: block;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .hero {
    &.m--hide-hero {
      display: block;
    }
    &.d--hide-hero {
      display: none;
    }
    .mobile-video {
      display: none;
    }
    .desktop-video {
      display: block;
    }

    /**
     * Predefined Sizes
     */
    &.d--tall {
      @include aspect-ratio(479, 657);
    }
    &.d--wide {
      @include aspect-ratio(2, 1);
    }
    &.d--square {
      @include aspect-ratio(1, 1);
    }
    &.d--auto {
      aspect-ratio: auto;
    }

    .hero-image {
      &.m--hide {
        display: block;
      }
      &.d--hide {
        display: none;
      }
    }

    .tag {
      &.top-left,
      &.top-right {
        top: 4rem;
      }
      &.bottom-left,
      &.bottom-right {
        bottom: 4rem;
      }
      &.top-left,
      &.bottom-left {
        left: 0rem;
      }
      &.top-right,
      &.bottom-right {
        right: 0rem;
      }
    }
    .ribbon {
      &.top-left {
        left: 3.2rem;
      }
      &.top-right {
        right: 3.2rem;
      }
    }
    .hero-content {
      padding: 7.5rem;
      .headline {
        margin-top: 1.6rem;
      }
      .button {
        min-width: 24rem;
        margin-top: 3.2rem;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>

<style lang="scss" scoped>

/**
 * Custom Sizes
 *
 * When the hero is placed within a grid
 * we need to tweak some of the attributes
 * in order to as much extent as possible
 * make sure that content fits
 *
 * In order to not have to override each setting
 * across breakpoints, these styles are not inherited
 */

// Mobile
@media screen and (max-width: $tablet - 1px) {
  .hero {
    /**
     * Mobile
     * 2 Columns
     */
    &.ms--51vw {
      &::v-deep .ribbon {
        padding: 0.6rem 1.2rem 0.6rem 1.8rem;
      }
      .hero-content {
        padding: 4.5vw;
        &::v-deep .eyebrow p {
          font-size: 2.5vw;
        }
        &::v-deep .eyebrow.viva p {
          font-size: 5vw;
        }
        &::v-deep .headline {
          &.large p {
            font-size: 4vw;
          }
          &.x-large p {
            font-size: 5vw;
          }
          &.huge p {
            font-size: 12vw;
          }
        }
        .hero-button {
          &::v-deep .button {
            min-width: 25vw;
          }
        }
      }
    }

    /**
     * Mobile
     * 3 Columns
     */
    &.ms--34vw {
      &::v-deep .ribbon {
        display: none;
        padding: 0.6rem 1.2rem 0.6rem 1.8rem;
      }
      .hero-content {
        padding: 3vw;
        &::v-deep .eyebrow p {
          font-size: 1.5vw;
        }
        &::v-deep .eyebrow.viva p {
          font-size: 4vw;
        }
        &::v-deep .headline {
          margin-top: .6rem;
          &.large p,
          &.x-large p {
            font-size: 3vw;
          }
          &.huge p {
            font-size: 8vw;
          }
        }
        .hero-button {
          &::v-deep .button {
            min-width: 25vw;
            .label {
              padding: 0 .6rem;
            }
          }
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) and (max-width: $tablet-landscape - 1px) {

  /**
   * Tablet (Portrait)
   * 3 Columns
   */
  .hero {
    &.ms--34vw {
      &::v-deep .ribbon {
        padding: 0.6rem 2.2rem 0.6rem 2.8rem;
      }
      .hero-content {
        &::v-deep {
          .eyebrow p {
            //font-size: 1.8rem;
          }
          .eyebrow.viva p {
            font-size: 2.4rem;
          }
          .headline {
            &.large p,
            &.x-large p {
              font-size: 3vw;
            }
            &.huge p {
              font-size: 8vw;
            }
          }
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) and (max-width: $laptop - 1px) {

  /**
   * Tablet (Landscape)
   * 3 Columns
   */
  .hero {
    &.ds--34vw {
      .ribbon {
        &.top-left {
          left: 1.6rem;
        }
        &.top-right {
          right: 1.6rem;
        }
      }
      .tag {
        &.top-left {
          top: 1.6rem;
        }
        &.top-right {
          top: 1.6rem;
        }
      }
      &::v-deep .ribbon {
        padding: 0.6rem 2.2rem 0.6rem 2.8rem;
      }
      .hero-content {
        padding: 3.3vw 2vw;
        &::v-deep {
          .eyebrow p {
            font-size: 1.2vw;
          }
          .eyebrow.viva p {
            font-size: 2.5vw;
          }
          .headline {
            margin-top: .8vw;
            &.large p {
              //font-size: 3.2rem;
              font-size: 3vw;
            }
            &.x-large p {
              //font-size: 4rem;
              font-size: 3.5vw;
            }
            &.huge p {
              //font-size: 10.4rem;
              font-size: 8vw;
            }
          }
        }
        .button {
          min-width: 13vw;
          margin-top: 1.5vw;
        }
      }
    }
  }

  /**
   * Tablet (Landscape)
   * 4 Columns
   */
  .hero {
    &.ds--26vw {
      .ribbon {
        &.top-left {
          left: .8rem;
        }
        &.top-right {
          right: .8rem;
        }
      }
      .tag {
        &.top-left {
          top: .8rem;
        }
        &.top-right {
          top: .8rem;
        }
      }
      &::v-deep .ribbon {
        padding: 0.6rem 2.2rem 0.6rem 2.8rem;
      }
      .hero-content {
        padding: 3.3vw 2vw;
        &::v-deep {
          .eyebrow p {
            font-size: 1vw;
          }
          .eyebrow.viva p {
            font-size: 2.5vw;
          }
          .headline {
            margin-top: .8vw;
            &.large p {
              //font-size: 3.2rem;
              font-size: 2.5vw;
            }
            &.x-large p {
              //font-size: 4rem;
              font-size: 2.5vw;
            }
            &.huge p {
              //font-size: 10.4rem;
              font-size: 6vw;
            }
          }
        }
        .button {
          min-width: 13vw;
          margin-top: 1.5vw;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {

  /**
   * Laptop
   * 4 Columns
   */
  .hero {
    &.ds--26vw {
      .ribbon {
        &.top-left {
          left: 1.6rem;
        }
        &.top-right {
          right: 1.6rem;
        }
      }
      .tag {
        &.top-left {
          top: 1.6rem;
        }
        &.top-right {
          top: 1.6rem;
        }
      }
      .hero-content {
        padding: 3.3vw 2vw;
        &::v-deep {
          .eyebrow p {
            font-size: 1.4rem;
          }
          .eyebrow.viva p {
            font-size: 2.2vw;
          }
          .headline {
            margin-top: .4vw;
            &.large p {
              //font-size: 3.2rem;
              font-size: 2vw;
            }
            &.x-large p {
              //font-size: 4rem;
              font-size: 2.5vw;
            }
            &.huge p {
              //font-size: 10.4rem;
              font-size: 6vw;
            }
          }
        }
      }
    }
  }
}

// Desktop
@media screen and (min-width: $desktop) {

}
</style>
