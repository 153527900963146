<template>
  <div class="shop-usp">
    <div
      v-if="icon"
      class="icon"
      :class="icon"
    >
      <component :is="icon" />
    </div>
    <div
      v-if="text"
      class="text"
    >
      {{ text }}
    </div>
    <div class="additional-text-mobile">
      {{ additionalTextMobile }}
    </div>
    <div class="additional-text">
      {{ additionalText }}
    </div>
  </div>
</template>

<script>
import IconAirplane from '~/assets/icons/airplane-takeoff.svg'
import IconKlarna from '~/assets/icons/klarna.svg'
import IconShippingBox from '~/assets/icons/shipping-box.svg'
import IconGiftBox from '~/assets/icons/giftbox.svg'

export default {
  name: 'ShopUsp',
  components: {
    IconAirplane,
    IconKlarna,
    IconShippingBox,
    IconGiftBox,
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: ''
    },
    text: {
      type: String,
      required: true,
      default: ''
    },
    additionalText: {
      type: String,
      required: false,
      default: ''
    },
    additionalTextMobile: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-usp {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    margin-bottom: 1.4rem;
    &:after {
      position: absolute;
      content: '';
      display: block;
      background: var(--foreground-color);
      height: 1px;
      width: 1.6rem;
      left: 50%;
      transform: translateX(-50%);
      bottom: -.8rem;
    }
    svg {
      display: block;
      fill: var(--foreground-color);
    }
    &.IconAirplane svg {
      width: 2rem;
    }
    &.IconKlarna svg {
      width: 4.8rem;
    }
    &.IconShippingBox svg {
      width: 1.6rem;
    }
    &.IconGiftBox svg {
      width: 1.8rem;
    }
  }
  .text,
  .additional-text-mobile,
  .additional-text {
    @include p--mini;
    margin: 0 auto;
    color: var(--foreground-color);
  }
  .text {
    font-weight: bold;
    padding: 0.4rem 1.6rem;
  }
  .additional-text-mobile {
    font-weight: 500;
  }
  .additional-text {
    display: none;
    font-weight: 500;
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .shop-usp {
    .icon {
      height: 2rem;
      &:after {
        display: none;
      }
      &.IconAirplane svg {
        width: 3rem;
      }
      &.IconKlarna svg {
        width: 7rem;
      }
      &.IconShippingBox svg {
        width: 2.2rem;
      }
      &.IconGiftBox svg {
        width: 2.5rem;
      }
      &.IconQliroBlue svg {
        width: 5.8rem;
      }
      &.IconQliroMint svg {
        width: 5.8rem;
      }
      &.IconQliroWhite svg {
        width: 5.8rem;
      }
    }
    .text,
    .additional-text {
      display: block;
      font-size: 1.2rem;
    }
    .additional-text-mobile {
      display: none;
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .shop-usp {
    .icon {
      height: 3rem;
      &.IconAirplane svg {
        width: 4.1rem;
      }
      &.IconKlarna svg {
        width: 9rem;
      }
      &.IconShippingBox svg {
        width: 3rem;
      }
      &.IconGiftBox svg {
        width: 3.3rem;
      }
    }
    .text,
    .additional-text {
      display: block;
      font-size: 1.4rem;
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .shop-usp {
    .text,
    .additional-text {
      font-size: 1.6rem;
    }
  }
}
</style>
