import { property } from 'underscore'
import { getBadges } from '../utils/getBadges'
export const sizeChartDelimiter = 'x'

export default ({ pricelists, currentPriceList, marketId, language }) => {
  function reduceFields (dataset, ...fields) {
    let result = dataset
    for (const field of fields) {
      result = property(field)(result)
    }

    return result
  }

  function getLocalized (dataset, ...fields) {
    const languageCode = language()

    if (!dataset.localized?.[languageCode]) {
      return reduceFields(dataset, ...fields)
    }

    const result = reduceFields(dataset.localized[languageCode], ...fields)

    if (!result) {
      return reduceFields(dataset, ...fields)
    }

    return result
  }

  function getImageUrl (image) {
    return image.sources.original?.url
  }

  function getPriceByType (product, type) {
    const prices =
      product.markets[marketId()].pricesByPricelist[currentPriceList()]
    return prices?.[type] ?? ''
  }

  return {
    getLocalized,
    getBadges,
    transformCentraProduct (product) {
      if (!product.sku) {
        throw new Error('Product is invalid')
      }

      const marketInfo = product.markets?.[marketId()]

      if (!marketInfo) {
        throw new Error(
          `Product ${product.sku} is not available in market ${marketId()}`
        )
      }

      const images = product.media
      const firstImage = images[0]

      const skuRegex = new RegExp(`${product.sku.toLowerCase()}-`)
      const image = images.find(it => skuRegex.test(it)) || firstImage

      const collectionRegex = new RegExp('-collection-')
      const collectionImage =
        images.find(it => collectionRegex.test(it)) || image

      const hoverRegex = new RegExp('-hover-')
      const hoverImage =
        images.find(it => hoverRegex.test(it)) || collectionImage

      const prices = {}
      Object.keys(marketInfo?.pricesByPricelist).forEach((pricelistId) => {
        const pricelist = pricelists().find(
          it => it.pricelist === pricelistId
        )
        if (pricelist) {
          prices[`discount_percent_${pricelist.currency.uri}`] = [
            marketInfo.pricesByPricelist[pricelistId].discountPercent,
          ]
          prices[`price_${pricelist.currency.uri}`] = [
            marketInfo.pricesByPricelist[pricelistId].priceAsNumber,
          ]
          prices[`price_text_${pricelist.currency.uri}`] = [
            marketInfo.pricesByPricelist[pricelistId].price,
          ]
          prices[`original_price_${pricelist.currency.uri}`] = [
            marketInfo.pricesByPricelist[pricelistId]
              .priceBeforeDiscountAsNumber,
          ]
          prices[`original_price_text_${pricelist.currency.uri}`] = [
            marketInfo.pricesByPricelist[pricelistId].priceBeforeDiscount,
          ]
        }
      })

      const badges = getBadges(getLocalized(product, 'badge'))
      const categories = Object.values(product.categories)
      const items = Object.values(product.items)
      const sizeOptions = []
      Object.values(product.items).forEach((item) => {
        const nameParts = item.name.split(sizeChartDelimiter)
        nameParts.forEach((label, index) => {
          const itemData = {
            label,
            value: item.item,
            quantity: item.stockByMarket[marketId()],
          }

          // If you have two-dimensional sizes
          if (nameParts.length > 1) {
            sizeOptions[index] = sizeOptions[index] || []
            sizeOptions[index].push(itemData)
          } else {
            sizeOptions.push(itemData)
          }
        })
      })

      const itemsByMarket = product.markets[marketId()]
      const inStock = itemsByMarket?.stockOfAllItems !== 0

      let isOnesize = false
      if (sizeOptions.length === 1) {
        isOnesize = true
      }

      // Main image of bundle section, if multiple is chosen, chose first
      const bundleMainImageObject = images.find(it => it.attributes.mp_bundle_item_main_image_text === '1')
      const bundleMainImage = bundleMainImageObject ? getImageUrl(bundleMainImageObject) : getImageUrl(image)

      return {
        color_variants: 1,
        color: product.color,
        image_url: getImageUrl(image),
        custom_fields: {
          collection: [product.collectionName],
          product_type: [product.product_type],
          collection_image_url: [collectionImage],
          centra_sku: product.sku,
          centra_product_sku: product.productSku,
          ...prices,
          hover_image_url: [getImageUrl(hoverImage)],
          badges,
        },
        created_at: product.createdAt,
        quantity: product.markets[marketId()].stockOfAllItems,
        price: [getPriceByType(product, 'price')],
        isOnSale:
          getPriceByType(product, 'price') !==
          getPriceByType(product, 'priceBeforeDiscount'),
        priceText: getPriceByType(product, 'price'),
        priceAsNumber: getPriceByType(product, 'priceAsNumber'),
        originalPriceText: getPriceByType(product, 'priceBeforeDiscount'),
        priceBeforeDiscountAsNumber: getPriceByType(product, 'priceBeforeDiscountAsNumber'),
        availability: product.markets[marketId()].stockOfAllItems ? 1 : 0,
        selected_variant_id: items.length ? items[0].item : undefined,
        item_group_id: product.product,
        id: product.product,
        productType: getLocalized(product, 'product_type'),
        productTypeNonLocalized: product.product_type,
        product_url: product.uri,
        style: product.style,
        thumbnail_url: bundleMainImage ?? getImageUrl(image),
        category: [categories.length ? categories[0].name : ''],
        title: product.silkProductName,
        translatedName: getLocalized(product, 'name'),
        productSku: product.productSku,
        items,
        sizeOptions,
        isOnesize,
        inStock,
        sizeguide: product.size_guide,
        restrictedQtyPerOrderByGroup: product.restricted_qty_per_order_by_group,
        brandName: product.brandName,
        currentPriceList:
          product.markets[marketId()].pricesByPricelist[currentPriceList()],
      }
    },
  }
}
