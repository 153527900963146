/**
 * Verify that a product can be added to cart by subtracting the stock value
 * by eventual occurrences of the same product in cart.
 * Show an error message if there are no more items in stock.
 */

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      cartItemByValue: 'centra-cart/cartItemByValue'
    })
  },
  methods: {
    allowAddToCart (value, qty) {
      const itemInCart = this.cartItemByValue(value)
      if (itemInCart) {
        if (itemInCart.quantity < qty) {
          return true
        } else {
          this.$notify({
            title: this.$t('Dialog_Add_To_Cart_Error_Title'),
            text: this.$t('Dialog_Add_To_Cart_No_More_Items_Available'),
            type: 'error'
          })
          return false
        }
      }
      return true
    }
  }
}
