export default {
  methods: {
    // The client might enter a hex code in Storyblok in which case we need to
    // make sure that that the hex code starts with # and the format is valid
    formatHexCode (color) {
      if (color.substring(0, 1) !== '#') {
        color = '#' + color
      }
      return color
    },
    color (dropdownColor = '', hexColor = '') {
      if (hexColor) {
        return this.formatHexCode(hexColor)
      }
      return dropdownColor
    }
  }
}
