<!--
Usage instructions.

* * * Dependencies:
To use the "ratio" prop, we need a scss varable
called $tabletLandscape, thats min-width for desktop design

* * * Props:
* lazyload [bool]
For lazyloading to work, the ratio of the figure element (or it's parent) need to be set

* imgSetting [object]
Each image cdn has different options. Implemented now is:
bgColor: hex without #
Example:
:img-settings="{bgColor: 'fafafa'}"
:img-settings="{ preserveAlpha: true }"
:img-settings="{ ratio: 1 }"

* imgSettingsDesktop [object - optional]
Overwrites imgSettings on desktop

* mobileSrc [string - required]
* desktopSrc [string - optional]
Complete url to image. If desktopSrc is not set, mobile will be used on desktop
LIMITATION: You can't mix svgs and pixelimages, if mobile image is svg, it will be used instead of desktopSrc

* mobileDisplaySize [string - optional, default 100vw]
* desktopDisplaySize [string - optional, default 100vw]
If we know an image will never be more then half screen, we can set this to 50vw
so that the client does not download an image bigger than needed for given presentation

* alt [string - required]
Alt for the image

* caption [string - optional]
@deprecated - not compatible with ratio
Shown under the image

* ratio [object - optional - recommended]
@important - this can not be combined with caption
This is the ratio for the figure, for nicer looking loading
Not to be confused with the ratio we send into image processor
it can be used like this:
:ratio="{type: 'auto'}" - tries to guess, works with storyblok images
:ratio="{width: 1, height: 1.5}" - creates a wrapper 150% height of width
:ratio="{widht: 1, height: 1.5, desktopWidth: 1, desktopHeight: 1.1}" - optional desktop

* * * Slot
added content in parent <ReponsiveImage> will be displayed last in <figure>
 -->
<template>
  <figure
    v-if="(mobileSrc && mobileSrc !== '') || (desktopSrc && desktopSrc !== '')"
    ref="figure"
    :class="{hasRatio, transparent: imgSettings.preserveAlpha}"
    :style="cssVariables"
    class="responsiveImg responsive-img"
  >
    <div v-if="hasRatio" class="spacer" />
    <picture v-if="showing || !lazyload">
      <source
        v-if="!isSvg && !supportsWebP && mobileSrc"
        :srcset="getSrcset(mobileSrc, {webp: true, ...imgSettings})"
        :sizes="mobileDisplaySize"
        :media="`(max-width: ${(breakpoint - 1)}px)`"
        type="image/webp"
      >
      <source
        v-if="!isSvg && !supportsWebP && desktopSrc"
        :srcset="getSrcset(desktopSrcOut, {webp: true, ...desktopSettings}, 'desktop')"
        :sizes="desktopDisplaySize"
        :media="`(min-width: ${breakpoint}px)`"
        type="image/webp"
      >
      <source
        v-if="!isSvg && mobileSrc"
        :srcset="getSrcset(mobileSrc, imgSettings)"
        :sizes="mobileDisplaySize"
        :media="`(max-width: ${(breakpoint - 1)}px)`"
      >
      <source
        v-if="!isSvg && desktopSrc"
        :srcset="getSrcset(desktopSrcOut, desktopSettings, 'desktop')"
        :sizes="desktopDisplaySize"
        :media="`(min-width: ${breakpoint}px)`"
      >
      <img
        v-if="!isSvg && mobileSrc"
        :src="processImgproxy(mobileSrc, {width: 1250, ...imgSettings})"
        :alt="alt"
        @load="imageLoaded"
      >
      <img
        v-else
        :src="mobileSrc ? mobileSrc : desktopSrc"
        :alt="alt"
      >
    </picture>
    <slot />
    <figcaption v-if="caption">
      {{ caption }}
    </figcaption>
  </figure>
</template>

<script>
import ResponsiveImageMixin from '~/mixins/ResponsiveImageMixin'

export default {
  name: 'ResponsiveImage',
  mixins: [ResponsiveImageMixin]
}
</script>

<style lang="scss" scoped>
  @import "~/mixins/ResponsiveImageStyle";
</style>
