import { render, staticRenderFns } from "./ProductBadge.vue?vue&type=template&id=40a5f8aa&scoped=true"
import script from "./ProductBadge.vue?vue&type=script&lang=js"
export * from "./ProductBadge.vue?vue&type=script&lang=js"
import style0 from "./ProductBadge.vue?vue&type=style&index=0&id=40a5f8aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a5f8aa",
  null
  
)

export default component.exports