import {
  ContextError,
  routeToLookupParts,
} from '@made-people/centra-storyblok-nuxt-shared/lib/store/frontend'
import { isEmpty } from 'underscore'
import {
  getAccountSettingsId,
  getGlobalToastsId,
  getGwpId,
  getMarketSettingsId,
  getTrustLogosId
} from '~/store/storyblok'

/**
 * Use nuxtServerInit to populate the store with all shared data that we only
 * want to fetch once and not every time a nuxt-link is clicked
 */
export default {
  actions: {
    async nuxtServerInit (
      { dispatch, rootState, state, commit, getters }, { req, route, redirect, $config }
    ) {
      const lookupParts = routeToLookupParts(
        route,
        $config.defaultLocale,
        $config.defaultMarketSlug
      )

      try {
        await dispatch('campaign/initialize')
        await dispatch('frontend/setCurrentSite', lookupParts)

        const {
          footerMenu,
          mainMenu,
          settings,
          countrySpecificInformation,
          marketInformation,
          uiElementTranslations,
          links,
          globalToasts,
          sizeCharts,
          accountSettings,
          trustLogos,
          gwpMessage,
          marketSettings
        } = (await this.$backendApi.get('content/site')).data

        if (uiElementTranslations) {
          commit('storyblok/elementTranslations', {
            locale: rootState.frontend.currentLanguageCode,
            entries: uiElementTranslations,
          })
        }

        commit('storyblok/links', links)

        if (footerMenu) {
          commit('storyblok/story', {
            id: footerMenu.id,
            data: footerMenu,
          })
        }
        if (mainMenu) {
          commit('storyblok/story', {
            id: mainMenu.id,
            data: mainMenu,
          })
        }
        if (settings) {
          commit('storyblok/story', {
            id: settings.id,
            data: settings,
          })
        }
        if (countrySpecificInformation) {
          commit('storyblok/story', {
            id: countrySpecificInformation.id,
            data: countrySpecificInformation,
          })
        }
        if (marketInformation) {
          commit('storyblok/story', {
            id: marketInformation.id,
            data: marketInformation,
          })
        }
        if (globalToasts) {
          commit('storyblok/story', {
            id: getGlobalToastsId(state.frontend.currentLanguageCode, state.frontend.currentCountryCode),
            data: globalToasts
          })
        }
        if (globalToasts) {
          commit('storyblok/story', {
            id: getGlobalToastsId(state.frontend.currentLanguageCode, state.frontend.currentCountryCode),
            data: globalToasts.map(toast => toast.content)
          })
        }
        if (sizeCharts) {
          commit('storyblok/story', {
            id: 'size-guides',
            data: sizeCharts
          })
        }
        if (accountSettings) {
          commit('storyblok/story', {
            id: getAccountSettingsId(state.frontend.currentLanguageCode, state.frontend.currentCountryCode),
            data: accountSettings
          })
          if (!isEmpty(accountSettings.loyaltyGUID)) {
            commit('ui/setAccountEnabled', true, { root: true })
            commit('ui/setAccountIconVisibility', !accountSettings.hideAccountIcon, { root: true })
          }
        }
        if (trustLogos) {
          commit('storyblok/story', {
            id: getTrustLogosId(state.frontend.currentLanguageCode, getters['frontend/currentMarketId']),
            data: trustLogos
          })
        }
        if (gwpMessage) {
          commit('storyblok/story', {
            id: getGwpId(state.frontend.currentLanguageCode, getters['frontend/currentMarketId']),
            data: gwpMessage
          })
        }
        if (marketSettings) {
          commit('storyblok/story', {
            id: getMarketSettingsId(state.frontend.currentLanguageCode, getters['frontend/currentMarketId']),
            data: marketSettings
          })
        }
      } catch (error) {
        if (error instanceof ContextError) {
          const countryCode = req.countryCode || $config.defaultMarketSlug
          const redirectTo = `/${countryCode.toLowerCase()}${req.originalUrl}`
          return redirect(`${redirectTo}`)
        }
        throw error // The error is not about setting the market
      }
    }
  }
}
