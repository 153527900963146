<template>
  <div class="cart-totals">
    <div class="totals">
      <div class="shipping">
        <div class="label">
          {{ $t('Global_Shipping') }}
        </div>
        <div class="value">
          <ShippingIcon v-if="showShippingIcon" />
          {{ shippingCost }}
        </div>
      </div>
      <div
        v-for="(voucher, index) in discountRows"
        :key="index"
        class="voucher"
      >
        <div class="label">
          {{ $t('Cart_Code_Applied') }}: <em>{{ voucher.voucher }}</em> — <span class="mini-link" @click="$store.dispatch('centra-cart/removeVoucher', voucher.voucher)">{{ $t('Global_Remove') }}</span>
        </div>
        <div class="value">
          {{ voucher.priceOff }}
        </div>
      </div>
      <div
        v-for="(voucher, index) in autoDiscountRows"
        :key="'auto-discount-' + index"
        class="voucher"
      >
        <div class="label">
          {{ $t('Cart_Discount_Applied') }}: <em>{{ voucher.name }}</em>
        </div>
        <div class="value">
          {{ voucher.priceOff }}
        </div>
      </div>
      <div v-if="totalDiscountPrice" class="discount-total">
        <div class="label">
          {{ $t('Cart_Discount_Label') }}
        </div>
        <div class="value">
          {{ totalDiscountPrice }}
        </div>
      </div>
      <div class="grand-total">
        <div class="label">
          {{ $t('Global_Total') }}
        </div>
        <div class="value">
          {{ totalCost }}
        </div>
      </div>
    </div>
    <slot name="discount" />
    <slot name="loyalty-explainer" />
    <mp-link
      :to="$u('checkout')"
      class="button solid medium ui-green"
      @click.native="hideMiniCart"
    >
      <span class="label">
        {{ $t('Global_Checkout') }}
      </span>
    </mp-link>
    <slot name="payment-options" />
  </div>
</template>

<script>
import ShippingIcon from '~/assets/icons/check-solid-round.svg'
export default {
  name: 'Total',
  components: {
    ShippingIcon
  },
  props: {
    shippingCost: {
      type: String,
      required: false,
      default: undefined
    },
    totalCost: {
      type: String,
      required: false,
      default: undefined
    },
    autoDiscountRows: {
      type: Object,
      required: false,
      default: () => {}
    },
    discountRows: {
      type: Array,
      required: false,
      default: () => []
    },
    totalDiscountPrice: {
      type: String,
      required: false,
      default: ''
    },
    showShippingIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hideMiniCart () {
      this.$store.dispatch('ui/hideMiniCart')
    }
  }
}
</script>

<style lang="scss" scoped>

.cart-totals {
  background-color: $white;
  padding: 1.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.4rem;
  .totals {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
    .discount-total,
    .shipping,
    .voucher {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      div {
        @include p--small;
        font-size: 1.2rem;
        line-height: 140%;
        margin: 0;
        .mini-link {
          text-decoration: underline;
          cursor: pointer;
          @include p--mini;
        }
      }
    }
    .shipping .value {
      display: flex;
      align-items: center;
      svg {
        height: 1.2rem;
        fill: $ui-green;
        margin-right: .4rem;
      }
    }
    .grand-total {
      display: flex;
      justify-content: space-between;
      width: 100%;
      div {
        @include h--mini;
        font-size: 1.4rem;
        line-height: 140%;
        margin: 0;
      }
    }
  }
  .button {
    width: 100%;
    height: 4.8rem;
    margin: 1.6rem 0;
  }
}
</style>
