import { DPC } from '@depict-ai/dpc'

let dpc

const getDpc = (merchant, market) => {
  if (!dpc) {
    dpc = new DPC(merchant, { market })
  }
  return dpc
}

export default getDpc
