<template>
  <div
    v-if="canDisplay"
    :class="[
      !mobileRoundedCorners ? 'mc--square' : 'mc--rounded',
      !desktopRoundedCorners ? 'dc--square' : 'dc--rounded',
      contextSearch ? 'context-search' : '',
      { 'context-quick-add': contextQuickAdd },
      { 'coming-soon': product?.coming_soon },
      { 'has-hover-image': hasHoverImage },
      { 'product-card': true },
      { 'out-of-stock': !isInStock },
      { loading: loading },
    ]"
    @mouseenter="mouseOver"
    @mouseleave="mouseOut"
  >
    <div class="hover-wrapper image" @click="$emit('closeModal')">
      <div class="ratio">
        <div v-if="!loaded" class="spinner" />
        <ResponsiveImage
          :mobile-display-size="mobileDisplaySize"
          :desktop-display-size="desktopDisplaySize"
          :mobile-src="imageUrl"
          :desktop-src="imageUrl"
          :alt="product.translatedName"
          :lazyload="true"
          class="product-image main"
          @imageLoaded="imageLoaded"
        />

        <transition name="fade-plp">
          <ResponsiveImage
            v-if="loaded && canHover"
            v-show="hasHoverImage && hovering"
            :mobile-src="hoverImageUrl"
            :desktop-src="hoverImageUrl"
            :mobile-display-size="mobileDisplaySize"
            :desktop-display-size="desktopDisplaySize"
            :alt="product.translatedName"
            class="product-image hover"
          />
        </transition>
        <div v-if="product.custom_fields?.badges?.length">
          <ProductBadge
            v-for="(badge, badgeIndex) in product.custom_fields.badges"
            :key="'badge-' + badgeIndex"
            :type="badge.type"
            :label="badge.text"
            :bg-hex="badge.bg_hex"
            :text-hex="badge.text_hex"
            class="badge top-left"
          />
        </div>
        <div v-if="contextCollection" class="collection-product">
          <div class="collection-details">
            <div class="name">
              <p>
                <b class="title">
                  {{ product.translatedName }}
                </b>
                <br>
                <span class="type">
                  {{ product.productType }}
                </span>
              </p>
            </div>
            <div class="price">
              <span :class="{ 'sale-price': product.isOnSale }">
                {{ product.priceText }}
              </span>
              <span v-if="product.isOnSale" class="old-price">
                {{ product.originalPriceText }}
              </span>
            </div>
          </div>
          <div class="button medium outline white">
            <span class="label">
              Shop Now
            </span>
          </div>
        </div>
        <mp-link
          :to="$u(product.product_url)"
          class="pdp-link"
          @click.native="trackClick"
        />
        <ProductCardHover
          v-if="
            showCartIcon &&
              hovering &&
              !contextCollection &&
              !reachedMaxPurchase &&
              !product?.coming_soon
          "
          :product-id="product.id"
          :price="product.priceLocal"
          :original-price="product.originalPrice"
        />
      </div>
    </div>
    <div v-if="showCartIcon" class="actions">
      <div
        v-if="showCartIcon && !reachedMaxPurchase"
        class="add-to-cart"
        @click="openSizeSelector"
      >
        <BagIcon />
      </div>
    </div>
    <div v-if="!contextCollection" class="details">
      <mp-link
        :to="$u(product.product_url)"
        class="pdp-link"
        @click.native="trackClick"
      >
        <div class="name">
          <p>
            <b class="title">
              {{ product.translatedName }}
            </b>
            <br>
            <span class="type">
              {{ product.productType }}
            </span>
          </p>
          <div
            translate="no"
            v-if="!contextSearch && !contextQuickAdd && stockStatus"
            class="stock mobile-stock"
          >
            <div :class="['stock-status', stockStatus]" />
            {{ $t(`Stock_Status_${stockStatus}`) }}
          </div>
        </div>
        <div class="price">
          <p :class="{ 'sale-price': product.isOnSale }">
            <b>{{ product.priceText }}</b>
          </p>
          <p v-if="product.isOnSale" class="old-price">
            {{ product.originalPriceText }}
          </p>
          <div
            translate="no"
            v-if="!contextSearch && !contextQuickAdd && stockStatus"
            class="stock desktop-stock"
          >
            <div :class="['stock-status', stockStatus]" />
            {{ $t(`Stock_Status_${stockStatus}`) }}
          </div>
        </div>
      </mp-link>
      <div
        v-if="product.colorVariants && !contextSearch"
        class="swatches"
      >
        <div
          v-for="swatch in product.colorVariants"
          :key="swatch.id"
          class="swatch"
        >
          <img
            :src="
              $imgproxy.transform(
                swatch.variantSwatch,
                'preset:sharp/rs:fit:60/g:nowe/q:70'
              )
            "
            alt="swatch"
            loading="lazy"
          >
        </div>
      </div>
    </div>
    <div v-if="contextQuickAdd" class="quick-add-actions">
      <QuickAdd
        v-if="centraProduct"
        class="size-selector"
        :items="centraProduct.sizeOptions"
        :added="addedToCart"
        :one-size="isOnesize"
        :expanded="quickAddExpanded"
        @quickAddToCart="submitAddToCart"
        @toggleQuickAdd="$emit('toggleQuickAdd')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BagIcon from '~/assets/icons/bag.svg'
import ProductBadge from '~/components/product/product-badge/ProductBadge'
import ProductCardHover from '~/components/product-card-hover/ProductCardHover'
import QuickAdd from '~/components/quick-add/QuickAdd'
import ProductCardMixin from '~/mixins/ProductCardMixin.js'
import ResponsiveImage from '~/components/image/ResponsiveImage.vue'
import ViewportMixin from '~/mixins/viewport'
import AllowAddToCart from '~/mixins/AllowAddToCart.js'
import Depict from '~/mixins/depict.js'

export default {
  name: 'ProductCard',
  components: {
    BagIcon,
    ProductBadge,
    ProductCardHover,
    QuickAdd,
    ResponsiveImage,
  },
  mixins: [ProductCardMixin, ViewportMixin, AllowAddToCart, Depict],
  props: {
    gtmListName: {
      type: String,
      default () {
        return this.$route.path.replace(/(^\/[^/]+\/?)|(\/$)/g, '')
      },
    },

    position: {
      type: Number,
      required: true,
    },

    /**
     * Show the cart icon
     */
    showCartIcon: {
      type: Boolean,
      default: false,
    },

    /**
     * Show the trashcan icon
     */
    showTrashIcon: {
      type: Boolean,
      default: false,
    },

    /**
     * If the product is shown in search results
     */
    contextSearch: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * If the product is shown in AddedToCartPopup
     */
    contextQuickAdd: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * If the product is shown as part of a collection
     */
    contextCollection: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     */
    mobileDisplaySize: {
      type: String,
      default: '',
    },
    desktopDisplaySize: {
      type: String,
      default: '',
    },

    /**
     * Rounded Corners
     */
    mobileRoundedCorners: {
      type: Boolean,
      default: false,
    },
    desktopRoundedCorners: {
      type: Boolean,
      default: false,
    },
    quickAddExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      sizeSelectorOpen: false,
      hovering: false,
      loaded: false,
      canHover: false,
      stockStatus: null,
      addedToCart: false, // for the quick add feature in add to cart popup
      addingToCart: false, // loading spinner
    }
  },
  computed: {
    ...mapGetters({
      productReachedMaxQtyPerOrder: 'centra-cart/productReachedMaxQtyPerOrder',
    }),
    ...mapState({
      freeGiftPopupOpen: state => state.ui.freeGiftPopupOpen,
    }),
    reachedMaxPurchase () {
      if (
        this.product?.custom_fields?.restricted_qty_per_order_by_group
          ?.length === 1
      ) {
        return this.productReachedMaxQtyPerOrder(
          this.product.custom_fields.restricted_qty_per_order_by_group[0]
        )
      }
      return false
    },
    badges () {
      return this.product.custom_fields?.badges || []
    },
    hoverImageUrl () {
      if (
        this.product.custom_fields &&
        this.product.custom_fields.hover_image_url
      ) {
        return this.product.custom_fields.hover_image_url[0]
      }
      return false
    },
    hasHoverImage () {
      return this.imageUrl !== this.hoverImageUrl
    },
    isOnesize () {
      return this.centraProduct.isOnesize
    },
    canDisplay () {
      return this.product
    },
  },
  mounted () {
    this.canHover = window.matchMedia('(hover: hover)').matches
    this.initStockStatus(this.product.quantity, this.badges)
  },
  async created () {
    this.loadProduct = this.$debounce(this._loadProduct, 100)

    if (this.contextQuickAdd) {
      await this._loadProduct()
      this.centraProduct = await this.transformProduct(
        this.$store.getters['centra-product/getProductById'](this.product.id),
      )
      this.selectedSize =
        this.centraProduct.isOnesize && this.centraProduct.sizeOptions.length
          ? this.centraProduct.sizeOptions[0]
          : null
    }
  },
  methods: {
    initStockStatus (quantity) {
      if (this.product?.coming_soon) {
        this.stockStatus = 'coming-soon'
      } else if (quantity === 0) {
        this.stockStatus = 'out-of-stock'
      } else if (quantity < 40) {
        this.stockStatus = 'low-stock'
      }
    },
    inViewportHandler () {
      if (!this.seenInViewport && this.testInViewport()) {
        const listName =
          this.gtmListName ||
          this.$route.path.replace(/(^\/[^/]+\/?)|(\/$)/g, '')
        this.gtm_queueProductImpression({ item: this.product, listName, listId: `centra-products-listname-${listName}`, position: this.position, depict: false })
      }
    },
    imageLoaded () {
      this.loaded = true
    },
    mouseOver () {
      this.hovering = true
      this.loadProduct()
    },
    mouseOut () {
      this.hovering = false
    },
    async openSizeSelector () {
      await this._loadProduct()
      this.$store.dispatch('ui/showSizeSelector', {
        productID: this.product.id,
      })
    },
    trackClick () {
      this.gtm_trackProductClick(this.product, this.gtmListName)
    },
    submitAddToCart (size) {
      if (size) {
        setTimeout(() => {
          this.addedToCart = true
        }, 500)
        this.selectedSize = size
        this.addToCart()
      }
    },
    addToCart () {
      if (!this.selectedSize) {
        return
      }
      if (
        !this.allowAddToCart(
          this.selectedSize.value,
          this.selectedSize.quantity
        )
      ) {
        return false
      }

      const addedItem = {
        productName: this.product.title,
        _product: {
          productSku: this.product.productSku,
          product_type: this.product.productType,
        },
        priceEachAsNumber: this.product.currentPriceList.priceAsNumber,
        brandName: this.product.brandName,
        size: this.selectedSize.label,
      }

      this.addingToCart = true
      const { productSku: sku, custom_fields: customFields } = this.product
      const sizeLabel = this.selectedSize.label
      this.$addToCart(sku || customFields?.centra_product_sku, sizeLabel, 1, this.product.product_url)
        .then((cart) => {
          this.$store.dispatch('centra-cart/setCart', cart)
          this.gtm_trackAddToCart(addedItem, 1, true, 'plp')
          this.$nuxt.$emit('after_add_product_to_cart')
          this.depict_trackAddToCart({ sku: this.product.productSku, sizeLabel: this.selectedSize.label })
        })
        .then(() => {
          this.addingToCart = false
        })
        .catch(() => {
          this.$notify({
            title: this.$t('Dialog_Add_To_Cart_Error_Title'),
            text: this.$t('Dialog_Add_To_Cart_Error_Message'),
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-card {
  width: 100%;
  position: relative;

  &.coming-soon {
    .actions {
      display: none!important;
    }
    .hover-wrapper {
      position: relative;
      &:before {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
        background: hsla(0,0%,100%,.5);
        color: black;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        pointer-events: none;
        text-transform: uppercase;
        content: "Coming Soon";
      }
    }
  }
  .image {
    overflow: hidden;
    position: relative;
    @include aspect-ratio(1, 1.5);
    background-color: $page-background;
    .pdp-link {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .desktop-product-hover {
      display: none;
    }
    .product-image {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 100%;
      &::v-deep {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .badge {
      z-index: 1;
      position: absolute;
      &.top-left {
        left: 0.8rem;
        top: 0.8rem;
      }
      &.bottom-left {
        left: 0.8rem;
        bottom: 0.8rem;
      }
    }
    .collection-product {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 1.6rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-end;
      &:before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          0deg,
          rgba(black, 0.5) 0%,
          rgba(black, 0) 100%
        );
        position: absolute;
        z-index: 0;
      }
      .collection-details {
        color: white;
        position: relative;
        z-index: 1;
        p {
          margin: 0;
        }
        .name p {
          @include h--small;
          margin: 0;
        }
        .price span {
          @include p--medium;
          margin: 0;
          font-weight: 500;
          &.old-price {
            @include p--small;
            text-decoration: line-through;
            font-weight: 500;
          }
        }
      }
      .button {
        position: relative;
        z-index: 1;
        min-width: unset;
      }
    }
  }
  .details {
    padding: 0.8rem;
    @include p--mini;
    .pdp-link {
      display: grid;
      grid-template-columns: 3fr 1fr;
    }
    .name {
      p {
        margin: 0;
      }
      b {
        font-weight: 700;
        @include h--mini;
        text-transform: none;
      }
      .mobile-stock {
        margin-top: 4px;
      }
    }
    .price {
      text-align: right;
      font-weight: 700;
      p {
        @include h--mini;
        margin: 0;
        text-align: right;
      }
      .sale-price {
        color: $badge-red; // this is not a typo
      }
      .old-price {
        text-decoration: line-through;
        font-weight: 700;
      }
      .desktop-stock {
        display: none;
      }
    }
    .swatches {
      display: flex;
      margin: 0 -0.4rem;
      .swatch {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0.4rem;
      }
    }
  }
  .quick-add-actions {
    position: relative;
  }
  .quick-add-actions {
    height: 32px;
  }
  .quick-add-actions {
    .size-selector {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }
  }
  .actions {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    z-index: 1;
    .add-to-cart,
    .remove {
      width: 2.4rem;
      height: 2.4rem;
      background: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      margin-bottom: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .add-to-cart svg {
      width: 1.2rem;
      height: 1.3rem;
      margin-top: -2px;
    }
    .remove {
      @media (hover: hover) {
        &:hover {
          background: $ui-red;
          svg {
            fill: white;
          }
        }
      }
      svg {
        width: 1.2rem;
        margin-top: -1px;
      }
    }
  }
  &.mc--square {
    .image {
      border-radius: 0;
      &::v-deep img {
        border-radius: 0;
      }
    }
  }
  &.mc--rounded {
    .image {
      border-radius: 0.8rem;
      &::v-deep img {
        border-radius: 0.8rem;
      }
    }
  }
  &.context-quick-add {
    display: flex;
    flex-direction: column;
  }
  &.context-quick-add {
    .details {
      font-size: 1rem;
      .title,
      .price p {
        font-size: 1rem;
      }
    }
  }
  &.context-search {
    .details {
      .pdp-link {
        display: block;
        text-align: center;
        .name {
          p {
            .title {
              text-transform: none;
              font-weight: 600;
            }
            .type {
              display: none;
            }
          }
        }
        .price {
          text-align: center;
          p {
            font-size: 1rem;
            text-align: center;
            display: inline;
          }
        }
      }
    }
  }
  &.out-of-stock {
    .actions .add-to-cart {
      display: none;
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .product-card {
    .details {
      .name b {
        text-transform: uppercase;
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .product-card {

    &.coming-soon {
      .hover-wrapper {
        &:before {
          font-size: 18px;
          content: "Coming Soon";
        }
      }
    }
    .actions {
      top: 1.6rem;
      right: 1.6rem;
      .add-to-cart,
      .remove {
        width: 3.3rem;
        height: 3.3rem;
      }
      .remove svg {
        width: 1.5rem;
      }
      .add-to-cart {
        display: none;
      }
    }
    .image .badge {
      &.top-left {
        left: 1.6rem;
        top: 1.6rem;
      }
      &.bottom-left {
        left: 1.6rem;
        bottom: 1.6rem;
      }
    }
    .details {
      @include p--small;
      .pdp-link {
        grid-template-columns: 1fr 1fr;
      }
      .name .mobile-stock {
        display: none;
      }
      .name b,
      .price p {
        @include p--small;
        text-transform: none;
        font-weight: 700 !important;
      }
      .price {
        .desktop-stock {
          display: flex;
          justify-content: flex-end;
        }
      }
      .swatches {
        display: none;
      }
    }

    /**
     * Hover Product Card
     */
    @media (hover: hover) {
      .hover-wrapper {
        position: relative;
        .ratio {
          .product-image {
            position: absolute;
          }
        }
      }
      &:hover {
        .desktop-product-hover {
          z-index: 1;
          width: calc(100% - 3.2rem);
          display: block;
          position: absolute;
          bottom: 1.6rem;
          left: 1.6rem;
          pointer-events: all;
        }
        &.has-hover-image {
          .product-image {
            &.hover {
              display: block;
            }
          }
        }
      }
    }
    &.dc--square {
      .image {
        border-radius: 0 !important;
        &::v-deep img {
          border-radius: 0 !important;
        }
      }
    }
    &.dc--rounded {
      .image {
        border-radius: 0.8rem !important;
        &::v-deep img {
          border-radius: 0.8rem !important;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .product-card {
    .details {
      @include p--medium;
      .name b,
      .price p {
        @include p--medium;
      }
    }
  }
}

// Desktop
@media screen and (min-width: $desktop) {
}
</style>
