export default {
  namespaced: true,
  state () {
    return {
      categories: []
    }
  },
  mutations: {
    categories (state, categories) {
      state.categories = categories.concat(state.categories.filter((item) => {
        return !categories.find(category => category.category === item.category)
      }))
    }
  },
  actions: {
    /**
     * Looks up a single category from centra
     */
    lookupCategory ({ state, commit }, id) {
      return this.$backendApi.get(`/categories/${id}`).then((response) => {
        commit('categories', [response.data])
        return response.data
      }).catch((e) => {
        console.error(e, 'store', 'centra-category', { id })
      })
    }
  },
  getters: {
    getCategoryById: state => id => state.categories.find(x => x.category === id)
  }
}
