/**
 * The VueSlickCarousel does not work with lazy loaded components, so all
 * children it renders need to be added below, or *only* in the components
 * using slick (and not lazily)
 */
import Vue from 'vue'

import ProductCard from '~/components/product/product-card/ProductCard.vue'
import ProductCardHover from '~/components/product-card-hover/ProductCardHover.vue'
import Grid from '~/components/grid/Grid.vue'
import Hero from '~/components/hero/Hero.vue'
import HeroWrapper from '~/components/hero/HeroWrapper.vue'
import ResponsiveImage from '~/components/image/ResponsiveImage.vue'
import VideoBlock from '~/components/video-block/VideoBlock.vue'
import MpLink from '~/components/MpLink.vue'
import ImageSwatch from '~/components/image-swatches/ImageSwatch.vue'

Vue.component('ProductCard', ProductCard)
Vue.component('ProductCardHover', ProductCardHover)
Vue.component('Grid', Grid)
Vue.component('Hero', Hero)
Vue.component('HeroWrapper', HeroWrapper)
Vue.component('ResponsiveImage', ResponsiveImage)
Vue.component('VideoBlock', VideoBlock)
Vue.component('MpLink', MpLink)
Vue.component('ImageSwatch', ImageSwatch)
