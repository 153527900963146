<template>
  <div>
    <template v-if="error.statusCode === 404">
      <HeroWrapper
        v-if="heroContent"
        v-bind="heroContent"
      />
    </template>
    <div v-else class="error-page">
      <template>
        <div class="message">
          <h1 class="h--huge">
            <em>{{ error.statusCode }}</em>
          </h1>
          <h2 class="h--small">
            {{ $t('Generic_Error') }}
          </h2>
          <p>{{ error.message }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      pageTitle: 'Stronger | 404 - the page cannot be found',
    }
  },
  computed: {
    settings: ({ $store }) => $store.getters['storyblok/settings'],
    heroContent: ({ settings }) => {
      if (settings && settings.pageNotFoundHero && settings.pageNotFoundHero[0]) {
        return settings.pageNotFoundHero[0].content
      }
    }
  },
  mounted () {
    if (this.pageReferrer !== '') {
      this.$store.dispatch('ui/incrementPageVisits')
    }
    this.gtm_trackPageImpression(this.pageTitle, '404')
  },
  head () {
    return {
      title: this.pageTitle,
    }
  }
}
</script>

<style lang="scss">
  .error-page {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: $peach-25;
    color: $night-100;
    .message {
      h1 {
        margin: .8rem 0;
      }
    }
    .link {
      margin-top: 2.4rem;
      .label {
        font-size: 1.4rem;
      }
    }
    .img {
      max-width: 125px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: $tablet-landscape) {
    .error-page {
      .link .label {
        font-size: 1.8rem;
      }
    }
  }
</style>
