function _log (level, ...all) {
  const log = {
    level,
    messages: all.map(printer)
  }
  console[level](JSON.stringify(log))
}

function printer (input) {
  const { name, message, stack } = input

  if (name) {
    return { error: name, message, stack }
  }

  return input
}

module.exports = {
  ...console,
  log (...all) {
    _log('log', ...all)
  },
  debug (...all) {
    if (process.env.DEBUG === '1') {
      _log('debug', ...all)
    }
  },
  error (...all) {
    _log('error', ...all)
  }
}
