import { v4 as uuidv4 } from 'uuid'

export const userUniqueIdKey = 'user_id'

export default {
  namespaced: true,
  state () {
    return {
      id: ''
    }
  },
  mutations: {
    setUserId (state, id) {
      state.id = id
    }
  },
  actions: {
    initialize ({ commit }) {
      let storedId = localStorage.getItem(userUniqueIdKey)
      if (!storedId) {
        storedId = uuidv4()
        localStorage.setItem(userUniqueIdKey, storedId)
      }
      commit('setUserId', storedId)
    }
  },
  getters: {
    id: state => state.id
  }
}
