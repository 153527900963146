import { getBadges } from '../utils/getBadges'

export const sizeChartDelimiter = 'x'

export const getProductPricelist = (store, product) => {
  return product.markets[store.getters['frontend/currentMarketId']]
    ?.pricesByPricelist[store.getters['frontend/currentPricelistId']]
}

export const getProductPrice = (store, product, key) => {
  const pricelist = getProductPricelist(store, product)
  if (pricelist) {
    return pricelist[key]
  } else {
    return ''
  }
}

export const transformProduct = (store, product) => {
  if (!product) {
    return null
  }
  if (product.url) {
    // Already transformed
    return product
  }

  const marketId = store.getters['frontend/currentMarketId']

  // Get the localized value or the default one
  const getLocalizedValue = (key) => {
    const localeObject = product.localized && product.localized[store.state.frontend.currentLanguageCode]
      ? product.localized[store.state.frontend.currentLanguageCode] : product

    if (localeObject[key] !== undefined) {
      return localeObject[key]
    } else {
      return product[key]
    }
  }

  const getLocalizedCanonicalCategory = () => {
    const currentLanguageCode = store.state.frontend.currentLanguageCode
    const canonicalCategoryId = product.canonicalCategory
    const categoryObject = product.localized?.[currentLanguageCode]?.categories?.[canonicalCategoryId]
    if (categoryObject?.name) {
      return categoryObject.name
    }

    return ''
  }

  const getCanonicalCategoryUri = () => {
    const { canonicalUri } = product
    if (!canonicalUri) {
      return ''
    }

    return canonicalUri.split('/').shift()
  }
  const sizeOptions = []
  Object.values(product.items).forEach((item) => {
    const nameParts = item.name.split(sizeChartDelimiter)
    nameParts.forEach((label, index) => {
      const itemData = {
        label,
        value: item.item,
        quantity: item.stockByMarket[marketId]
      }

      // If you have two-dimensional sizes
      if (nameParts.length > 1) {
        sizeOptions[index] = sizeOptions[index] || []
        sizeOptions[index].push(itemData)
      } else {
        sizeOptions.push(itemData)
      }
    })
  })

  let isOnesize = false
  if (sizeOptions.length === 1) {
    isOnesize = true
  }

  const itemsByMarket = product.markets[marketId]
  const inStock = itemsByMarket?.stockOfAllItems !== 0
  const isLowStock = itemsByMarket?.stockOfAllItems < 40
  const url = product.uri.replace(/^\//, '')

  const bundleAtcImageMobile = product?.media?.filter(it => it.type === 'image')
    .filter(it => it.type === 'image' && it.attributes.bundle_atc_image_value === 'bundle_atc_image_mobile')
  const bundleAtcImageDesktop = product?.media?.filter(it => it.type === 'image')
    .filter(it => it.type === 'image' && it.attributes.bundle_atc_image_value === 'bundle_atc_image_desktop')

  const media = product.media.filter(it => it.type === 'image')
    .filter(it => it.type === 'image' && !['bundle_atc_image_mobile', 'bundle_atc_image_desktop'].includes(it.attributes.bundle_atc_image_value))
    .map(it => it.sources)
    .filter(it => it.original)
    .map(it => it.original.url)

  const mediaWithCustomAttributes = product.media.filter(it => it.type === 'image')
    .filter(it => it.type === 'image' && !['bundle_atc_image_mobile', 'bundle_atc_image_desktop'].includes(it.attributes.bundle_atc_image_value))
    .filter(it => it.sources.original)
    .map(it => ({
      url: it.sources.original.url,
      sizeDescription: it.attributes.mp_image_size_description_size_description,
      bundleItemMainImage: it.attributes.mp_bundle_item_main_image,
    }))
  const badges = getBadges(getLocalizedValue('badge'))

  const discounted = getProductPrice(store, product, 'price') !== getProductPrice(store, product, 'priceBeforeDiscount')

  const getBundleDiscountValue = (bundle) => {
    if (!bundle.is_bundle) {
      return undefined
    }

    const productPrices = bundle.bundle_sections.map(it => getProductPrice(store, it.products[0], 'priceBeforeDiscountAsNumber'))
    const totalPriceOfBundleItems = productPrices.reduce((sum, it) => sum + it, 0)
    return totalPriceOfBundleItems - getProductPrice(store, bundle, 'priceAsNumber')
  }

  const getBundleDiscount = (bundle) => {
    const discountValue = getBundleDiscountValue(bundle)
    if (!discountValue) {
      return undefined
    }

    return `${discountValue.toFixed(2)} ${store.getters['frontend/currentCurrency']}`
  }

  return {
    product: product.product,
    sku: product.sku,
    productSku: product.productSku,
    badges,
    restrictedQtyPerOrderByGroup: product.restricted_qty_per_order_by_group,
    promoBlockId: product.pr_promo_block_id,
    brandName: product.brandName,
    silkProduct: product.silkProduct,
    price: getProductPrice(store, product, 'price'),
    priceAsNumber: getProductPrice(store, product, 'priceAsNumber'),
    priceBeforeDiscount: getProductPrice(store, product, 'priceBeforeDiscount'),
    priceBeforeDiscountAsNumber: getProductPrice(store, product, 'priceBeforeDiscountAsNumber'),
    discounted,
    discountPercent: getProductPrice(store, product, 'discountPercent'),
    name: getLocalizedValue('name'),
    nameNonLocalized: product.name,
    metaTitle: getLocalizedValue('metaTitle'),
    silkProductName: getLocalizedValue('silkProductName'),
    description: getLocalizedValue('description'),
    shortDescription: getLocalizedValue('excerpt'),
    washingInstructions: getLocalizedValue('washing_instructions'),
    descriptionHeader: getLocalizedValue('description_header'),
    descriptionSubHeader: getLocalizedValue('description_sub_header'),
    fit: getLocalizedValue('fit'),
    color: getLocalizedValue('color'),
    colorNonLocalized: product.color,
    color_swatch: getLocalizedValue('color_swatch'),
    product_type: getLocalizedValue('product_type'),
    productTypeNonLocalized: product.product_type,
    categories: getLocalizedValue('categories'),
    categoriesNonLocalized: product.categories,
    collection: product.collection,
    collectionName: product.collectionName,
    pattern: getLocalizedValue('pattern'),
    sizeguide: product.size_guide,
    canonicalCategoryLabel: getLocalizedCanonicalCategory(),
    canonicalCategoryUri: getCanonicalCategoryUri(),
    sizeOptions,
    sizeOptionsVisible: false,
    style: product.style,
    isOnesize,
    isLowStock,
    inStock,
    items: product.items,
    url,
    videoUrl: product.video_url || null,
    videoPlacement: product.video_placement || null,
    swatchProducts: product.swatchProducts,
    coming_soon: product?.coming_soon === '1',

    // This allows us to hax business logic that we can rely on
    mainImage: media[0],
    thumbnailImage: media[0],
    media,
    bundleAtcImageMobile,
    bundleAtcImageDesktop,
    mediaWithCustomAttributes,
    terminated: product.pr_terminated === '1',
    product_url: url,
    richRelations: {
      swatch_products: product.rich_relations?.swatch_products,
      complete_the_look: product.rich_relations?.complete_the_look
    },
    isBundle: product.is_bundle ?? false,
    bundleData: product.bundle ?? {},
    bundleInfo: product.bundle_info ?? {},
    bundleSections: product.bundle_sections ?? [],
    bundleDiscount: getBundleDiscount(product),
    bundleDiscountAsNumber: getBundleDiscountValue(product),
    bundleAddToCartBgColor: getLocalizedValue('bundle_atc_bg_color'),
    bundleAtcText: getLocalizedValue('bundle_atc_text'),
  }
}

/**
 * The store should for now keep a basic structure of the product tree and leave
 * all bigger and heavy lookups to route level lookups
 */
export default {
  namespaced: true,
  state () {
    return {
      products: [],
    }
  },
  mutations: {
    toggleSizeOptionsVisible (state, product) {
      product.sizeOptionsVisible = !product.sizeOptionsVisible
    },
    products (state, products) {
      state.products = products.concat(state.products.filter((item) => {
        return !products.find(product => product.product === item.product)
      })).map(product => transformProduct(this, product))
    },
  },
  actions: {

    /**
     * Looks up products from centra
     */
    lookupProducts ({ commit }, ids) {
      if (!ids && !ids?.length) {
        return null
      }
      if (Array.isArray(ids)) {
        ids = ids.join(',')
      }
      return this.$backendApi.get(`/products/${ids}`).then((response) => {
        commit('products', response.data)
        return response.data
      }).catch((e) => {
        console.error(e, 'store', 'centra-product', { ids, method: 'lookupProducts' })
      })
    },

    /**
     * Looks up all products related to a silk product from centra
     */
    lookupSilkProduct ({ commit }, id) {
      return this.$backendApi.get(`/products/by-silk-product/${id}`).then((response) => {
        commit('products', [response.data])
        return response.data
      }).catch((e) => {
        console.error(e, 'store', 'centra-product', { id, method: 'lookupSilkProduct' })
      })
    },
    lookupProductsBySkus ({ commit }, skus) {
      return this.$backendApi.get(`/products/by-skus/${skus}`).then((response) => {
        return response.data
      }).catch((e) => {
        console.error(e, 'store', 'centra-product', { skus, method: 'lookupProductsBySkus' })
        throw e
      })
    }
  },
  getters: {
    getProductById: state => id => state.products.find(x => x.product === id)
  }
}
