<template>
  <div
    class="image-swatch"
    :class=" { 'image-swatch--is-active': isActive}"
    @mouseenter="$emit('swatchMouseEnter')"
    @mouseleave="$emit('swatchMouseLeave')"
  >
    <mp-link
      :to="$u(uri)"
    >
      <img
        class="image-swatch__thumbnail"
        :src="$imgproxy.transform(image, 'preset:sharp/rs:fit/w:200/g:sm/q:70')"
        :alt="label"
        loading="lazy"
      >
    </mp-link>
  </div>
</template>

<script>

export default {
  name: 'ImageSwatch',
  props: {
    label: {
      type: String,
      required: true
    },
    uri: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.image-swatch {
  &__thumbnail {
    object-fit: cover;
    height: 9.6rem;
  }

  &:not(.image-swatch--is-active) {
    padding-left: 0.3rem;
  }

  &--is-active {
    .image-swatch__thumbnail {
      border: 0.1rem solid $black;
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .image-swatch {
    &__thumbnail {
      height: 12.4rem;
    }
  }
}
</style>
