<template>
  <component
    v-if="resultsFetched"
    :is="childComponent"
    :product-skus="productSkus"
    :products="products"
    v-bind="childProps"
  />
</template>

<script>
export default {
  name: 'CentraStaticResultsController',
  props: {
    childProps: {
      type: Object,
      required: true,
    },
    childComponent: {
      type: String,
      required: true,
    },
    productSkus: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      resultsFetched: false
    }
  },
  computed: {
    products () {
      return this.$store.getters['centra-product-repository/getProductsBySkus'](...this.productSkus)
    }
  },
  mounted () {
    this.loadItems()
  },
  methods: {
    loadItems () {
      return this.$store.dispatch('centra-product-repository/load', this.productSkus)
        .catch((e) => {
          console.error(e, 'centra-static-results-controller', this._name, { data: this._data, props: this._props })
        }).finally(() => {
          this.resultsFetched = true
        })
    }
  }
}
</script>
