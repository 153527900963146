<template>
  <div
    class="root"
    tabindex="0"
    @keyup.esc="closeModal"
    :class="{
      'mobile-header-sticky': mainMenu.content.stickyMobile,
      'desktop-header-sticky': mainMenu.content.stickyDesktop,
    }"
    :style="cssProps"
  >
    <!-- Header -->
    <!-- Special banner which enables the user to exit a vip-campaign (removes the campaign cookie) -->
    <VipBanner v-show="!!vipCampaign" />
    <Header
      ref="headerBlock"
      :cart-qty="cartQty"
      :main-menu="mainMenu.content.topLevel"
    >
      <Topbar
        slot="topbar"
        :instagram-text="currentMarketInfo.topbarInstagram"
        :topbar-text-items="currentMarketInfo.topbarTextItems"
        :topbar-icon="currentMarketInfo.topbarIcon"
        :topbar-icon-position="currentMarketInfo.topbarIconPosition"
        :foreground-color="currentMarketInfo.foregroundColor"
        :foreground-color-hex="currentMarketInfo.foregroundColorHex"
        :background-color="currentMarketInfo.backgroundColor"
        :background-color-hex="currentMarketInfo.backgroundColorHex"
        :shipment-time="currentMarketInfo.shipmentTime"
        :display-shipment-timer="currentMarketInfo.displayShipmentTimer"
        :enable-three-hour-countdown="
          currentMarketInfo.enableThreeHourCountdown
        "
      >
        <CountrySelector
          slot="country-selector"
          :country-code="currentCountryCode"
        />
      </Topbar>
      <MiniCart
        v-if="miniCartOpen"
        slot="mini-cart"
        :cart="cart"
      />
      <DesktopSubmenu
        v-if="desktopSubmenuOpen"
        slot="desktop-submenu"
        :main-menu="mainMenu"
      />
    </Header>

    <!-- Mobile Sidebar (Main Menu) -->
    <transition name="slide-in-left">
      <MobileSidebar
        v-if="mobileSidebarOpen"
        class="sidebar left"
        :main-menu="mainMenu"
      >
        <CountrySelector
          slot="country-selector"
          :country-code="currentCountryCode"
        />
      </MobileSidebar>
    </transition>

    <!-- Slide in Country Selector from right -->
    <transition name="slide-in-right">
      <CountrySelectorSidebar
        v-if="countrySelectorOpen"
        :countries="countriesSorted"
        :languages="$store.state.frontend.languages"
        :initial-country-code="currentCountryCode"
        :initial-language-code="currentLanguageCode"
        :markets="markets"
        :site-lang-slug="currentSiteLangSlug"
        class="sidebar right"
      />
    </transition>

    <!-- Slide in Usp popup from right -->
    <transition name="slide-in-right">
      <UspPopup v-if="uspPopupOpen" class="sidebar right" />
    </transition>

    <!-- Slide in Product Restock from right -->
    <transition name="slide-in-right">
      <ProductRestockSidebar
        v-if="productRestockSidebarOpen"
        class="sidebar right"
      />
    </transition>

    <!-- Hotspot Product Popup -->
    <transition :name="hotspotSlidein">
      <ProductPopup v-if="hotspotProductsOpen" class="sidebar bottom" />
    </transition>

    <transition name="slide-in-bottom">
      <SizeSelector
        v-if="sizeSelectorOpen"
        class="size-selector bottom"
        :product-id="selectedProductID"
        :bundle-product="bundleProduct"
      />
      <DepictSizeSelectorModal
        v-if="sizeDepictSelectorOpen"
        class="size-selector bottom"
        :product="selectedDepictProduct"
      />
    </transition>

    <FreeGiftPopup v-if="freeGiftPopupOpen" :gift="gift" />

    <!-- Nuxt -->
    <nuxt />

    <!-- Footer -->
    <Footer>
      <FooterStory
        v-if="footer.content.theStrongerStory.length"
        slot="footer-story"
        :content="footer.content.theStrongerStory"
      />
      <ShopUsps
        v-if="globalFooter && (!isPdp || !currentMarketInfo.hideUspsOnPdp)"
        slot="usp-collection"
        :usps="globalFooter.usps"
        :background-color="globalFooter.backgroundColor"
        :foreground-color="globalFooter.foregroundColor"
        :mobile-columns="globalFooter.mobileColumns"
        :desktop-columns="globalFooter.desktopColumns"
      />
      <CountrySelector
        slot="country-selector"
        :country-code="currentCountryCode"
      />
      <FooterMenu
        v-if="mainMenu.content.secondaryMenus.length"
        slot="footer-menu"
        :menu="mainMenu.content.secondaryMenus"
      />
      <FooterLogos
        v-if="trustLogos.data.length"
        slot="footer-logos"
        :logos="trustLogos.data"
      />
      <client-only>
        <Trustpilot
          v-if="
            footer.content.displayTrustPilot &&
              footer.content.displayTrustPilot.length
          "
          height="100px"
          theme="dark"
          slot="trustpilot"
          :data="footer.content.displayTrustPilot[0]"
        />
      </client-only>
    </Footer>
    <!-- Tinted Overlay to be used when triggering varios popups and sidebars -->
    <transition name="fade">
      <Overlay v-if="overlayIndex > 0" :z-index="overlayIndex" />
    </transition>

    <!-- Dynamic modals are added to this container -->
    <modals-container />

    <!-- vue-notification placeholder -->
    <client-only>
      <notifications
        classes="stronger-notification"
        position="top center"
        width="100%"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Footer from '~/components/footer/Footer.vue'
import FooterLogos from '~/components/footer-logos/FooterLogos.vue'
import FooterMenu from '~/components/footer-menu/FooterMenu.vue'
import FooterStory from '~/components/footer-story/FooterStory.vue'
import FreeGiftPopup from '~/components/free-gift-popup/FreeGiftPopup'
import SizeSelector from '~/components/size-selector/SizeSelector.vue'
import Topbar from '~/components/header/topbar/Topbar.vue'
import CountrySelector from '~/components/country-selector/CountrySelector'
import MiniCart from '~/components/mini-cart/MiniCart'
import DesktopSubmenu from '~/components/header/DesktopSubmenu'
import MobileSidebar from '@/components/header/MobileSidebar'
import Header from '@/components/header/Header'
import VipBanner from '~/components/vip-banner/VipBanner.vue'
import UspPopup from '~/components/usps/UspPopup.vue'
import Trustpilot from '~/components/trustpilot/Trustpilot.vue'
import { depictSearchProvider } from '~/components/depict/providers/depictSearchProvider'
import DepictSizeSelectorModal from '~/components/depict/DepictSizeSelectorModal.vue'
import Depict from '~/mixins/depict'

export default {
  name: 'DefaultLayout',
  components: {
    MiniCart,
    Header,
    Footer,
    FooterLogos,
    FooterMenu,
    FooterStory,
    FreeGiftPopup,
    SizeSelector,
    DepictSizeSelectorModal,
    Topbar,
    CountrySelector,
    DesktopSubmenu,
    MobileSidebar,
    VipBanner,
    UspPopup,
    Trustpilot,
  },
  mixins: [Depict],
  provide () {
    if (typeof window === 'undefined') {
      return { depictSearchProvider: undefined }
    }

    return {
      depictSearchProvider: depictSearchProvider({ router: this.$router, store: this.$store, getDisplays: this.getDisplays, merchant: this.$config.depictMerchant })
    }
  },
  data () {
    return {
      // TODO: unhardcoderize it
      reviewSummary: {
        aggregateScore: 4.7,
        reviewsTotal: 15484,
      },
      scrolledToBottom: false,
      currentMarketInfo: {},
      version: null,
      versionCheckTimer: null,
      forceReloadTimer: null,
      headerHeight: 0,
    }
  },
  computed: {
    ...mapState({
      countrySelectorOpen: state => state.ui.countrySelectorOpen,
      miniCartOpen: state => state.ui.miniCartOpen,
      overlayIndex: state => state.ui.overlayIndex,
      markets: state => state['centra-market'].markets,
      searchOpen: state => state.ui.searchOpen,
      mobileSidebarOpen: state => state.ui.mobileSidebarOpen,
      desktopSubmenuOpen: state => state.ui.desktopSubmenuOpen,
      enableDiscountFilter: state => state.ui.enableDiscountFilter,
      hotspotProductsOpen: state => state.ui.hotspotProductsOpen,
      sizeSelectorOpen: state => state.ui.sizeSelectorOpen,
      sizeDepictSelectorOpen: state => state.ui.sizeDepictSelectorOpen,
      selectedProductID: state => state.ui.selectedProductID,
      bundleProduct: state => state.ui.bundleProduct,
      productRestockSidebarOpen: state => state.ui.productRestockSidebarOpen,
      currentRoute: state => state.routes.currentRoute,
      freeGiftPopupOpen: state => state.ui.freeGiftPopupOpen,
      gift: state => state.ui.gift,
      uspPopupOpen: state => state.ui.uspPopupOpen,
      pageVisits: state => state.ui.pageVisits,
      selectedDepictProduct: state => state.ui.selectedDepictProduct,
    }),
    ...mapGetters({
      vipCampaign: 'campaign/activeCampaign',
      countriesSorted: 'frontend/countriesSorted',
      availableLanguages: 'frontend/availableLanguages',
      currentCountryCode: 'frontend/currentCountryCode',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentSiteLangSlug: 'frontend/currentSiteLangSlug',
      cart: 'centra-cart/cart',
      getMenuById: 'storyblok/getMenuById',
      settings: 'storyblok/settings',
      getProductById: 'centra-product/getProductById',
      getMarketSettings: 'storyblok/getMarketSettings',
      getMarketByCountryCode: 'frontend/getMarketByCountryCode',
      isLoggedIn: 'account/isLoggedIn',
    }),
    mainMenu () {
      return this.getMenuById('main-menu')
    },
    footer () {
      return this.getMenuById('footer-menu')
    },
    cartHasGiftItem () {
      return this.cart.items?.find(item => item.totalPriceAsNumber === 0)
    },
    cartQty () {
      if (this.cart) {
        this.cartHasGiftItem
          ? this.$store.dispatch('ui/hideGwpMessage')
          : this.$store.dispatch('ui/showGwpMessage')
        return this.cart.items.reduce((acc, cur) => {
          acc = cur.quantity + acc
          return acc
        }, 0)
      } else {
        return 0
      }
    },
    globalFooter () {
      if (
        this.currentMarketInfo?.footerUsps &&
        this.currentMarketInfo.footerUsps[0]
      ) {
        return this.currentMarketInfo.footerUsps[0]
      }
      return null
    },
    isPdp () {
      return this.currentRoute?.component?.component === 'product'
    },
    trustLogos () {
      return this.$store.getters['storyblok/trustLogos']
    },
    hotspotSlidein () {
      if (this.hotspotProductsOpen?.popupType === 'grid') {
        return 'slide-in-right'
      }
      return 'slide-in-bottom'
    },
    isCart () {
      return this.$nuxt.$route.name === 'context-cart'
    },
    cssProps () {
      return {
        '--header-height': `${this.headerHeight}px`,
      }
    },
  },
  watch: {
    $route () {
      this.softConversionTrigger()
      this.$store.dispatch('ui/closeAll')
    },
    freeGiftPopupOpen () {
      if (!this.freeGiftPopupOpen) {
        this.$confetti.stop()
        this.$store.dispatch('ui/hideFreeGiftPopup')
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateHeaderHeight)
    window.removeEventListener('scroll', this.detectBottom)
    clearInterval(this.versionCheckTimer)
    clearTimeout(this.forceReloadTimer)
  },
  mounted () {
    this.loadCartOnMounted()
    window.addEventListener('scroll', this.detectBottom, {
      capture: true,
      passive: true,
    })
    this.setVH()
    window.addEventListener('resize', this.setVH)
    // check the build version number
    this.version = this.$config.buildVersion
    this.versionCheckTimer = setInterval(this.checkVersion, 1000 * 60 * 5)

    this.updateHeaderHeight()
    window.addEventListener('resize', this.updateHeaderHeight)

    this.softConversionTrigger()
    this.checkCountryForLanguageSelector()
  },
  created () {
    const settings = this.getMarketSettings(
      this.currentLanguageCode,
      this.getMarketByCountryCode(this.currentCountryCode)
    )
    if (settings?.data?.[0]) {
      this.currentMarketInfo = settings.data[0]
    }
  },
  methods: {
    /**
     * Load cart on mounted
     *
     * This method should be called from mounted.
     *
     * The checkout success page(s) unsets the cart when an order is placed because they use the
     * cart selection as order selection. You can see this by reloading the checkout success page once and get an error.
     *
     * This means that when navigating from checkout success without reloading the site one may end
     * up without a cart selection.
     *
     * All components that interact with the cart, add to cart/remove from cart, will still be enabled since they
     * don't check if the cart exists, and they don't reload/create the cart if it doesn't exist.
     *
     * To prevent multiple cart components from simultaneously create a new cart, for example multiple add to cart on a
     * PLP page, the cart reload is done here in the default layout.
     *
     * It is safe to load the cart here because the checkout pages doesn't use the default layout so when leaving a
     * checkout page the default layout is mounted.
     *
     * This is just a quick-fix for the real problem(using the same selection for both cart and placed order).
     */
    loadCartOnMounted () {
      this.$store.dispatch('centra-cart/fetchCartIfNotLoaded')
    },
    softConversionTrigger () {
      if (this.pageReferrer !== '') {
        this.$store.dispatch('ui/incrementPageVisits')
      }

      if (this.pageVisits === 4) {
        this.gtm_softConversion()
        this.$store.dispatch('ui/resetPageVisits')
      }
    },
    /**
     * Check the autogenerated build version agains current build version
     */
    checkVersion () {
      fetch('/build.json')
        .then(response => response.json())
        .then((data) => {
          if (data.version !== this.version) {
            clearInterval(this.versionCheckTimer)
            // Turns nuxt-links into defacto normal links
            // Pages refreshes on next navigation
            this.$router.beforeEach((to) => {
              window.location.href = to.fullPath
            })

            this.forceReloadTimer = setTimeout(this.forceReload, 1000 * 60 * 10)
          }
        })
    },
    forceReload () {
      location.reload()
    },
    detectBottom () {
      this.scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight
    },
    closeModal () {
      this.$store.dispatch('ui/closeAll')
    },
    // Creates a css variable for 100vh that works on ios safari
    setVH () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    getLdJsonDataScript (jsonData) {
      return {
        type: 'application/ld+json',
        json: jsonData,
      }
    },
    getRecaptchaScript () {
      return {
        src: `https://www.google.com/recaptcha/api.js?render=${this.$config.recaptchaSiteKey}`,
        async: true,
      }
    },
    updateHeaderHeight () {
      this.headerHeight = this.$refs.headerBlock.$el.clientHeight
    },
    getSearchActionData () {
      return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://www.strongerlabel.com',
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate:
                'https://www.strongerlabel.com/search?q={search_term}',
            },
            'query-input': 'required name=search_term',
          },
        ],
        inLanguage: 'sv-SE',
      }
    },
    getOrganizationData () {
      return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'STRONGER',
        sameAs: [
          'https://www.facebook.com/strongersweden/',
          'https://www.instagram.com/stronger/',
          'https://www.youtube.com/channel/UCOFSYlnYoiEbu0CzuxhLILw',
          'https://www.tiktok.com/@strongersweden',
          'https://www.pinterest.com/strongersweden/',
        ],
        url: 'https://www.strongerlabel.com',
        logo: {
          '@type': 'ImageObject',
          inLanguage: 'sv-SE',
          url: 'https://www.strongerlabel.com/icons/logo-static.svg',
        },
      }
    },

    checkCountryForLanguageSelector () {
      if (this.currentCountryCode === 'BE') {
        const language = localStorage.getItem('user_language_be')
        if (!language) {
          return this.$nuxt.$emit('show_language_selector_popup')
        }
      }
      if (this.currentCountryCode === 'CH') {
        const language = localStorage.getItem('user_language_ch')
        if (!language) {
          return this.$nuxt.$emit('show_language_selector_popup')
        }
      }
    },
  },
  head () {
    const script = []
    script.push(this.getLdJsonDataScript(this.getOrganizationData()))
    script.push(this.getLdJsonDataScript(this.getSearchActionData()))
    script.push(this.getRecaptchaScript())
    const robots = {}
    if (this.$config.noIndex) {
      robots.name = 'robots'
      robots.content = 'noindex,nofollow'
    }

    return {
      script,
      meta: [
        {
          ...robots,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
@import "@depict-ai/js-ui/dist/styles/plain-css-fallback-styling.css";
</style>

<style lang="scss" scoped>
.root {
  height: 100%;
  .sidebar,
  .size-selector {
    position: fixed;
    z-index: 100;
    &.left {
      top: 0;
      left: 0;
    }
    &.right {
      top: 0;
      right: 0;
    }
    &.bottom {
      bottom: 0;
      left: 0;
    }
  }
  &.mobile-header-sticky {
    ::v-deep {
      #main-content {
        position: relative;

        .sticky-toasts {
          // if mobile header is sticky, we need to position the toasts below
          top: var(--header-height);
        }
      }
      .cart {
        .mobile-header {
          top: 86px;
        }
      }
    }
    header {
      top: 0;
      position: sticky;
    }
  }
  .search-results-wrapper {
    position: absolute;
    // z-index set to lower than header (10) but higher than overlay (which is set to 8 in this use-case)
    z-index: 9;
    width: 100%;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .root {
    .search-results-wrapper {
      width: 100%;
      left: 0rem;
    }
    // reset eventual sticky-mobile styling
    &.mobile-header-sticky {
      header {
        position: relative;
      }
    }
    &.desktop-header-sticky {
      header {
        top: 0;
        position: sticky;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .root {
    .search-results-wrapper {
      width: calc(100% - 42rem);
      left: 21rem;
    }
  }
}
</style>
