// This file is taken from Depict
/* eslint-disable camelcase */

import { createEffect, createRoot, untrack, createSignal } from 'solid-js'

let set = false
let padding_animation

export const [getCategoryPageInDom, setCategoryPageInDom] = createSignal(false)
export const [getSearchPageInDom, setSearchPageInDom] = createSignal(false)
export const categoryPagePathnames = new Set()

export function strongerScrollRestorationFix () {
  actual_fix_stronger_scrollrestoration({
    is_search_page_: () => location.pathname.includes('/search'),
    is_category_page_: () => categoryPagePathnames.has(location.pathname),
    get_category_page_in_dom_: getCategoryPageInDom,
    get_search_page_in_dom_: getSearchPageInDom,
  })
}

/**
 * Copied from react-ui and adapted to work on nuxt, fixes scroll restoration on stronger
 */
function actual_fix_stronger_scrollrestoration ({
  is_search_page_,
  is_category_page_,
  get_category_page_in_dom_,
  get_search_page_in_dom_,
}) {
  /// WARNING WARNING WARNING
  // If you are debugging scroll restoration, make sure you're not in strict mode!!!!! Scroll restoration in nextjs won't work in strict mode because react will nuke all our elements from the DOM and render them again during going back
  // Next 13.x+ has strict mode by default, opt out by setting reactStrictMode: false in next.config.js
  const are_on_search_or_category_page = () => is_search_page_() || is_category_page_()
  if (set) {
    return // Just being safe
  }
  set = true

  // We used to initialise this variable with false which in some cases (scroll to top button in FloatingButtons) would cause the scroll to be blocked when it shouldn't (see https://depictaiworkspace.slack.com/archives/C058KQ3MU8Z/p1686141321127089?thread_ts=1686134246.109689&cid=C058KQ3MU8Z)
  // I don't know why we'd want to initialise it with false? The only reason I can see is in the hopes of scroll restoration working on initial page load, but it basically never does due to react rendering and executing our component too late
  let should_use_old_scroll = true
  const old_scrollTo = window.scrollTo

  window.scrollTo = (...args) => {
    if (
      should_use_old_scroll ||
      process.env.__NEXT_SCROLL_RESTORATION ||
      !are_on_search_or_category_page() ||
      history.scrollRestoration === 'manual' // I.e. Chimi has manual scroll restoration that we don't want to block https://depictaiworkspace.slack.com/archives/C058KQ3MU8Z/p1686658007164019?thread_ts=1686134246.109689&cid=C058KQ3MU8Z
    ) {
      return Reflect.apply(old_scrollTo, window, args)
    } else {
      should_use_old_scroll = true
    }
  }

  // When popping state, add padding in the same event as the "popstate" event (required) and then remove it once our stuff is in the DOM
  addEventListener(
    'popstate',
    () => {
      if (!are_on_search_or_category_page()) {
        removeLotsOfPadding() // Just making sure
        return
      }
      should_use_old_scroll = false

      const search_page_in_dom = untrack(get_search_page_in_dom_)
      const category_page_in_dom = untrack(get_category_page_in_dom_)
      const new_is_search = is_search_page_()
      const new_is_category = is_category_page_()
      let accessor_to_wait_for

      if ((search_page_in_dom && new_is_search) || (category_page_in_dom && new_is_category)) {
        // Already on the page we're going to
        removeLotsOfPadding()
        return
      }
      addLotsOfPadding()

      if (new_is_search && category_page_in_dom) {
        accessor_to_wait_for = get_search_page_in_dom_
      } else if (new_is_category && search_page_in_dom) {
        accessor_to_wait_for = get_category_page_in_dom_
      } else {
        accessor_to_wait_for = new_is_search ? get_search_page_in_dom_ : get_category_page_in_dom_
      }

      createRoot(dispose =>
        createEffect(() => {
          if (accessor_to_wait_for()) {
            removeLotsOfPadding()
            dispose()
          }
        })
      )
    }
  )
}

/*
 Here we want to be able to add padding that *definitely* gets applied and then be able to revert precisely to what was before.
 The best way to achieve this is using an animation that with the fill set to none. This is because animations have the absolute highest precedence in CSS, even higher than !important. Then, to revert to the old padding, we just play the animation which is 1ms long so, it immediately finishes and whatever was defined before takes precedence again.
 */
function addLotsOfPadding () {
  if (padding_animation) { return } // We're already showing a bunch of padding
  padding_animation = document.body.animate(
    {
      paddingBottom: (history.state?.search_scroll_restoration_data?.[0]?.min_results_loaded || 150) * 500 + 'px',
      offset: 0,
    },
    1
  )
  padding_animation.pause()
}

function removeLotsOfPadding () {
  if (padding_animation) {
    padding_animation.play()
    padding_animation = undefined
  }
}
