var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero",class:[
    {
      plate: _vm.diagonalPlate !== '',
      tint: _vm.tint,
      'm--hide-hero': _vm.hideMobile,
      'd--hide-hero': _vm.hideDesktop
    },
    'm--' + _vm.mobileSize,
    'd--' + _vm.desktopSize,
    'ms--' + _vm.mobileDisplaySize,
    'ds--' + _vm.desktopDisplaySize,
    _vm.styleModifier ? 'sm--' + _vm.styleModifier : ''
  ],style:([
    _vm.textColorCss,
    _vm.bottomPlateColorCss,
    _vm.backgroundColorCss
  ])},[_c('div',{staticClass:"ratio"},[(_vm.mobileVideo || _vm.desktopVideo)?_c('VideoBlock',{attrs:{"mobile-video":_vm.mobileVideo,"desktop-video":_vm.desktopVideo,"video-loop":true,"video-autoplay":true,"video-controls":false,"mobile-poster":_vm.mobileImage,"desktop-poster":_vm.desktopImage}}):_vm._e(),_vm._v(" "),(_vm.mobileImage.filename || _vm.desktopImage.filename)?_c('ResponsiveImage',{staticClass:"hero-image",class:[
        _vm.mobileVideo ? 'm--hide' : '',
        _vm.desktopVideo ? 'd--hide' : '',
      ],attrs:{"mobile-src":_vm.mobileImage.filename ? _vm.mobileImage.filename : '',"desktop-src":_vm.desktopImage.filename ? _vm.desktopImage.filename : '',"alt":_vm.mobileImage.alt ? _vm.mobileImage.alt : '',"mobile-display-size":_vm.mobileDisplaySize,"desktop-display-size":_vm.desktopDisplaySize,"lazyload":true}}):_vm._e(),_vm._v(" "),_vm._l((_vm.tags),function(tag,index){return [(tag.type === 'tag')?_c('Tag',{key:index,attrs:{"visible":tag.visible,"text":tag.text,"position":tag.position,"text-color":tag.textColor,"background-color":tag.backgroundColor,"background-color-hex":tag.backgroundColorHex}}):_vm._e(),_vm._v(" "),(tag.type === 'ribbon')?_c('Ribbon',{key:index,attrs:{"visible":tag.visible,"text":tag.text,"position":tag.position,"text-color":tag.textColor,"background-color":tag.backgroundColor,"background-color-hex":tag.backgroundColorHex}}):_vm._e()]}),_vm._v(" "),(_vm.eyebrow || _vm.headline || _vm.ctas)?_c('div',{staticClass:"hero-content",class:_vm.contentPosition},[(_vm.eyebrow)?_c('div',{staticClass:"eyebrow",class:_vm.eyebrowFont,domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.eyebrow.html))}}):_vm._e(),_vm._v(" "),(_vm.headline)?_c(_vm.headlineType,{tag:"component",staticClass:"headline",class:[
          _vm.headlineSize,
          (_vm.headline && _vm.desktopHeadline && _vm.desktopHeadline.html !== '') || _vm.hideMobileHeadlineOnDesktop ? 'hide' : '',
        ],domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.headline.html))}}):_vm._e(),_vm._v(" "),(_vm.desktopHeadline && _vm.desktopHeadline.html !== '')?_c(_vm.headlineType,{tag:"component",staticClass:"headline headline-desktop",class:_vm.headlineSize,domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.desktopHeadline.html))}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"hero-button"},_vm._l((_vm.ctas),function(button,index){return _c('Button',_vm._b({key:index,attrs:{"inherited-link":_vm.overwriteCtaLinks ? _vm.link : {}}},'Button',button,false))}),1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.hotspots),function(hotspot,index){return _c('HotspotController',_vm._b({key:'hotspot-' + index,attrs:{"hotspot-title":_vm.hotspotTitle}},'HotspotController',hotspot,false))}),_vm._v(" "),(_vm.$u(_vm.link) !== null)?_c('mp-link',{staticClass:"link",class:{ 'hover-effect': _vm.hoverEffect },attrs:{"to":_vm.$u(_vm.link)}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }