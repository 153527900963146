<template>
  <div
    v-if="visible === '1' && text"
    :style="colorVariables"
    :class="position"
    class="tag"
  >
    {{ text }}
  </div>
</template>

<script>
import ColorHexCode from '~/mixins/ColorHexCode.js'

export default {
  name: 'Tag',
  mixins: [ColorHexCode],
  props: {
    visible: {
      type: String,
      required: false,
      default: '1'
    },
    textColor: {
      type: String,
      required: false,
      default: ''
    },
    textColorHex: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    position: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    colorVariables () {
      return {
        '--text-color': this.color(this.textColor, this.textColorHex),
        '--background-color': this.color(this.backgroundColor, this.backgroundColorHex)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  padding: 0.3rem 0.6rem;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 110%;
  white-space: nowrap;
  &.top-left,
  &.bottom-left {
    position: absolute;
    left: 0;
  }
  &.top-right,
  &.bottom-right {
    position: absolute;
    right: 0;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .tag {
    font-size: 1.2rem;
    padding: 0.6rem 1.2rem;
  }
}
</style>
