<template>
  <header class="header">
    <slot name="topbar" />
    <div class="header-mobile">
      <div class="left">
        <ToggleMenu />
        <ToggleSearch />
      </div>
      <mp-link
        :to="$u('')"
        title="Stronger"
        class="logo"
      >
        <LogoIcon />
      </mp-link>
      <div class="right">
        <mp-link
          v-if="displayAccountIcon"
          class="loyalty-nav"
          :to="$u('hero-league')"
        >
          <LoyaltyIcon class="loyalty-logo" />
        </mp-link>
        <ToggleCart
          v-if="showCart"
          :cart-qty="cartQty"
          device="mobile"
        />
      </div>
      <slot name="mobile-search-input" />
    </div>
    <div class="header-desktop">
      <div class="left">
        <mp-link
          :to="$u('')"
          title="Stronger"
          class="logo"
        >
          <LogoIcon />
        </mp-link>
      </div>
      <div v-if="mainMenu" class="main-menu">
        <div
          v-for="(item, index) in mainMenu"
          :key="index"
          class="item"
          :style="linkColorCss(item)"
          :class="'show--' + item.visibility"
        >
          <div
            v-if="item.toggleDesktopSubmenu"
            class="open-submenu"
            @mouseover="showDesktopSubmenu"
          >
            <mp-link :to="$u(item.link)">
              <span @click="trackMenuClick(item)">{{ item.label }}</span>
            </mp-link>
          </div>
          <div
            v-else
            class="link"
            @mouseover="hideDesktopSubmenu"
          >
            <mp-link :to="$u(item.link)">
              <span @click="trackMenuClick(item)">{{ item.label }}</span>
            </mp-link>
          </div>
        </div>
        <div class="desktop-submenu">
          <slot name="desktop-submenu" />
        </div>
      </div>
      <div class="right">
        <mp-link
          v-if="displayAccountIcon"
          class="loyalty-nav"
          title="Open hero league"
          :to="$u('hero-league')"
        >
          <LoyaltyIcon class="loyalty-logo" />
        </mp-link>
        <ToggleSearch />
        <ToggleCart
          v-if="showCart"
          :cart-qty="cartQty"
          device="desktop"
        />
        <slot v-if="showCart" name="mini-cart" />
      </div>
      <slot name="desktop-search-input" />
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import ColorHexCode from '~/mixins/ColorHexCode.js'
import LoyaltyIcon from '~/static/icons/loyalty.svg'
import LogoIcon from '~/assets/icons/logo.svg'
import ToggleMenu from '~/components/header/ToggleMenu'
import ToggleSearch from '~/components/header/ToggleSearch'
import ToggleCart from '~/components/header/ToggleCart'
import GtmMixin from '~/mixins/stronger-gtm'

export default {
  name: 'Header',
  components: {
    LoyaltyIcon,
    ToggleMenu,
    ToggleSearch,
    ToggleCart,
    LogoIcon,
  },
  mixins: [ColorHexCode, GtmMixin],
  props: {
    mainMenu: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
    cartQty: {
      type: Number,
      default: 0,
    },
    /**
     * If cart and cart related components should be displayed
     *
     * This is used by checkout success which displays Header but doesn't have a valid cart.
     */
    showCart: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      displayAccountIcon: state => state.ui.displayAccountIcon,
    }),
  },
  methods: {
    showDesktopSubmenu () {
      this.$store.dispatch('ui/showDesktopSubmenu')
    },
    hideDesktopSubmenu () {
      this.$store.dispatch('ui/hideDesktopSubmenu')
    },
    linkColorCss (item) {
      return { '--link-color': this.color(item.linkColor, item.linkColorHex) }
    },
    trackMenuClick (item) {
      this.gtm_trackMenuClick({
        label: item.label,
        id: item._uid,
        view: 'desktop',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  background-color: $night-100;
  color: $white !important;
  width: 100%;
  z-index: 11;
  position: relative;
  overflow: visible;
  &::v-deep {
    .search-input {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .header-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-mobile;
    padding: 0.8rem;
    width: 100%;
    position: relative;
    .left,
    .right {
      width: calc((100% - 8rem) / 2);
      display: flex;
      align-items: center;
      height: 100%;
    }
    .logo {
      svg {
        height: 2rem;
        display: block;
      }
    }
    .right {
      justify-content: flex-end;
      position: relative;
    }
  }
  .loyalty-nav {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .loyalty-logo {
      height: 22px;
      fill: $white;
      stroke: $white;
      stroke-width: 10;
    }
  }
  .logo {
    transition: all 0.2s ease-out;
    display: block;
    &:active {
      transform: translateY(2px);
    }
  }
  .header-desktop {
    display: none;
  }
  .mini-cart {
    display: none;
  }
}

.logo {
  svg {
    fill: $white;
  }
}
// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  header {
    .header-mobile {
      display: none;
    }
    &::v-deep {
      .desktop-search-input {
        width: calc(100% - 26rem);
        left: 16rem;
      }
    }
    .header-desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2.4rem;
      height: $header-desktop;
      box-shadow: inset 0 -1px $night-50;
      position: relative;
      .left {
        width: 160px;
        > a,
        > span {
          svg {
            width: 12.4rem;
            display: block;
          }
        }
      }
      .main-menu {
        display: flex;
        width: calc(100% - 160px - 160px);
        max-width: 1000px;
        justify-content: center;
        height: 100%;
        .item {
          @include h--mini;
          letter-spacing: normal;
          padding: 0;
          margin: 0 0.8rem;
          display: none;
          position: relative;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:after {
            content: '';
            display: block;
            width: 0;
            height: 3px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 1px;
            background: $mint-100;
            transition: width 0.2s ease-out;
          }
          &:hover {
            &:after {
              width: 100%;
            }
          }
          &.show--all,
          &.show--desktop {
            display: flex !important;
          }
          &:last-child {
            padding-right: 0;
          }
          // &.color--red {
          //   color: $ui-red;
          // }
          > a,
          > span,
          > .open-submenu,
          > .link {
            text-align: center;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 0.8rem;
            color: var(--link-color);
          }
          > .open-submenu,
          > .link {
            a {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
            }
          }
          /**
           * This opens up the submenu on click where hover is not available
           * It also means any direct link is disabled (so that the submenu is opened instead)
           */
          .open-submenu > a {
            pointer-events: none;
            @media (hover: hover) {
              pointer-events: all;
            }
          }
        }
        .desktop-submenu {
          position: absolute;
          top: 100%;
          max-height: 75vh;
          overflow: auto;
        }
      }
      .right {
        display: flex;
        width: 160px;
        justify-content: flex-end;
        position: relative;
        height: 100%;
        align-items: center;
        .toggle-search {
          margin-right: 0.8rem;
        }
        .toggle-cart {
          margin-right: -0.8rem;
        }
        .mini-cart {
          display: block;
          position: absolute;
          top: 100%;
          right: 0;
        }
      }
      .loyalty-nav {
        width: auto;
        display: inline-flex;
        justify-content: flex-end;
        margin-right: 16px;
        flex-shrink: 0;
        > span {
          display: none;
        }
        @media (hover: hover) {
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

// Custom breakpoint
@media screen and (min-width: 1400px) {
  header {
    .header-desktop {
      .right,
      .left {
        width: 220px;
      }
    }
    .main-menu {
      width: calc(100% - 220px - 220px);
    }
  }
  header .header-desktop .loyalty-nav > span {
    display: inline-block;
    margin-right: 8px;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  header {
    &::v-deep {
      .desktop-search-input {
        width: calc(100% - 36.6rem);
        left: 22rem;
      }
    }
    .header-desktop {
      padding: 0 8rem;
      .main-menu {
        .item {
          margin: 0 1.2rem;
          > a,
          > span,
          > .open-submenu,
          > .link {
            padding: 0 1.2rem;
          }
        }
      }
    }
  }
}
</style>
