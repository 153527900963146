// use the plugin you want, this is just an example
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export function setupDayjs (dayjs) {
  dayjs.extend(relativeTime)
  dayjs.extend(updateLocale)
  dayjs.extend(duration)
  dayjs.extend(utc)
  dayjs.extend(timezone)

  dayjs.updateLocale('sv', {
    relativeTime: {
      future: 'om %s',
      past: '%s sen',
      s: 'ett par sekunder',
      m: 'en minut',
      mm: '%d minuter',
      h: 'en timme',
      hh: '%d timmar',
      d: 'en dag',
      dd: '%d dagar',
      M: 'en månad',
      MM: '%d månader',
      y: 'ett år',
      yy: '%d år'
    }
  })
  return dayjs
}

export default ({ app: { $dayjs } }) => {
  setupDayjs($dayjs)
}
