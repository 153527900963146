<template>
  <div class="footer">
    <slot name="footer-story" />
    <slot name="usp-collection" />
    <div class="footer-wrapper">
      <div class="logo-n-reviews">
        <div class="logo">
          <LogoIcon />
          <p>{{ $t('Footer_Escape_Ordinary') }}</p>
        </div>
        <slot name="review-summary" />
        <slot name="country-selector" />
      </div>
      <div class="menu-n-logos">
        <slot name="footer-menu" />
        <slot name="footer-logos" />
      </div>
      <div class="footer-info">
        <p>{{ $t('Footer_Info_Text') }}</p>
      </div>
    </div>
    <slot name="trustpilot" />
  </div>
</template>

<script>
import LogoIcon from '~/assets/icons/logo.svg'

export default {
  name: 'Footer',
  components: {
    LogoIcon,
  },
  props: {
    footer: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $night-75;
  padding-bottom: 7.2rem;
  color: white;
  .footer-wrapper {
    .logo-n-reviews {
      text-align: center;
      .logo {
        padding-top: 4rem;
        svg {
          width: 17rem;
          fill: $white;
        }
        p {
          color: $white;
          margin-top: 0.5rem !important;
          font-family: $font-alternate;
          font-size: 1.2rem;
          line-height: 120%;
          letter-spacing: 0.24em;
          text-transform: uppercase;
        }
      }
      .country-selector {
        color: white;
        display: flex;
        justify-content: center;
        margin-top: 3.2rem;
        margin-bottom: 1.6rem;
        ::v-deep .selected-country .chevron {
          path {
            fill: white;
          }
        }
      }
    }
    .footer-info {
      text-align: center;
      opacity: 0.3;
      p {
        @include p--small;
        color: white;
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .footer {
    .footer-wrapper {
      display: grid;
      grid-template-rows: auto auto;
      padding: 5.6rem;
      grid-gap: 2rem;
      .logo-n-reviews {
        .logo {
          padding-top: 0;
        }
        .country-selector {
          margin-bottom: 0rem;
        }
      }
      .menu-n-logos {
        margin-left: auto;
        margin-right: auto;
        margin-top: 3.2rem;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .footer-menu {
          width: 40%;
          padding: 0;
          &::v-deep {
            .mobile-menu {
              margin: 0 auto;
            }
          }
        }
        .footer-logos {
          width: 50%;
          padding: 0;
          flex-basis: 60%;
          max-width: 300px;
          display: block;
          overflow: hidden;
          &::v-deep {
            .logo {
              float: left;
              height: 2rem;
            }
          }
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .footer {
    padding-bottom: 0;
    .footer-wrapper {
      grid-gap: 1.6rem;
      .logo-n-reviews {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        .logo {
          p {
            text-align: left;
          }
        }
        .reviews {
          margin: 0;
          padding: 0;
          text-align: right;
          &::v-deep {
            .star-rating {
              justify-content: flex-end;
            }
          }
        }
        .country-selector {
          margin: 0;
          //width: 100%;
          justify-content: flex-start;
        }
      }
      .menu-n-logos {
        max-width: unset;
        margin-left: unset;
        margin-right: unset;
        display: block;
        margin-top: 0;
        .footer-menu {
          width: auto;
          margin: 4rem 0;
        }
        .footer-logos {
          width: unset;
          flex-basis: unset;
          max-width: unset;
          display: flex;
          margin: 4rem 0;
          justify-content: center;
          &::v-deep {
            .logo {
              float: unset;
              height: 2.4rem;
              margin: 0 1.6rem;
            }
          }
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .footer {
    .trustpilot-widget {
      padding-bottom: 40px;
    }
    .footer-wrapper {
      grid-template-columns: 1fr 4fr;
      grid-template-rows: unset;
      padding: 8rem 13rem;
      grid-column-gap: 20rem;
      grid-row-gap: 3.2rem;
      max-width: $laptop;
      margin-left: auto;
      margin-right: auto;
      .logo-n-reviews {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .logo {
          padding-top: 0;
          p {
            text-align: center;
          }
        }
        .reviews {
          margin: 0;
          padding: 0;
          text-align: center;
          &::v-deep {
            .star-rating {
              justify-content: center;
            }
          }
        }
        .country-selector {
          justify-content: center;
          margin: 0;
        }
      }
      .menu-n-logos {
        .footer-menu {
          margin: 0;
        }
        .footer-logos {
          margin-top: 6rem;
          margin-bottom: 0;
          justify-content: flex-start;
          &::v-deep {
            .logo {
              margin: 0 3.2rem 0 0;
            }
          }
        }
      }
      .footer-info {
        grid-column: 2;
        text-align: left;
      }
    }
  }
}
</style>
