// import { isEmpty } from 'underscore'

export const getMenuId = menuId => `shared-content/menus/${menuId}`
export const menuComponentName = 'MainMenu'
const getCollectionId = collection => `collection-${collection}`
export const getMarketSettingsId = (language, marketId) => ['settings', marketId, language?.toLowerCase()].join('-')
export const getTrustLogosId = (language, marketId) => ['trust-logos', marketId, language?.toLowerCase()].join('-')
export const getGwpId = (language, marketId) => ['gwp-message', marketId, language?.toLowerCase()].join('-')
export const getGlobalToastsId = (language, country) => ['toasts', country?.toLowerCase(), language?.toLowerCase()].join('-')
export const getAccountSettingsId = (language, country) => ['accountSettings', country?.toLowerCase(), language?.toLowerCase()].join('-')

const settingsSlug = 'shared-content/settings/general'

/**
 * Fetch storyblok things such as menus, styles, pages and similar here
 */
export default {
  state () {
    return {
      stories: [],
      elementTranslations: {},
      links: []
    }
  },

  mutations: {
    story: (state, payload) => {
      state.stories = [payload].concat(state.stories.filter(x => x.id !== payload.id))
    },
    elementTranslations (state, { locale, entries }) {
      // An object can be used if the response data is already indexed for us
      state.elementTranslations[locale] = entries
    },
    links (state, links) {
      // Turning this into an array so we can filter/find
      state.links = Object.values(links)
    }
  },

  actions: {
    /**
     * UI Elements in storyblok contains all translations for buttons, small
     * headlines and similar things that are not directly part of the content
     * management. We use SB for it because the datasource setup is very easily
     * manageable, has import/export and doesn't require deploys for translation
     * changes.
     */
    fetchElementTranslations ({ state, commit, rootGetters }, locale) {
      if (!state.elementTranslations[locale]) { // This is just so we can switch quickly with stuff in state
        return this.$storyapi.get('cdn/datasource_entries?datasource=ui-element-translations', {
          params: {
            dimension: locale,

            // 1000 items means a lot of buttons and strings and it's also
            // storyblok's max limit
            per_page: 1000
          }
        }).then((response) => {
          commit('elementTranslations', {
            locale,
            entries: response.data.datasource_entries
          })
        }).catch((error) => {
          console.error(error, 'store', 'storyblok', { locale, method: 'fetchElementTranslations' })
        })
      }
    },

    fetchStory ({ commit, rootState, getters }, { id, params }) {
      let lookupId = id
      const link = getters.getLinkForSlug(id)
      if (link) {
        lookupId = link.id
      }
      params = params || {}
      params.language = params.language || rootState.frontend.currentLanguageCode
      return this.$storyapi.get(`cdn/stories/${lookupId}`, { params }).then((response) => {
        commit('story', {
          id: lookupId,
          data: response.data.story
        })
        return response.data.story
      })
    },

    /**
     * Get a menu with all its children
     */
    fetchMenu ({ dispatch }, menuId) {
      return dispatch('fetchStory', {
        id: getMenuId(menuId)
      })
    },

    /**
     * Get Stronger base product information
     */
    fetchCollectionContent ({ commit, rootState, dispatch }, collection) {
      return this.$backendApi.get('content/product-collection-content/' + collection)
        .then((response) => {
          commit('story', {
            id: getCollectionId(collection),
            data: response.data[0]
          })
          return response.data[0]
        })
    },

    /**
     * This fetches the entire site (link) structure in one big blob
     * so we can do simple lookups on which ID to get from a storyblok
     * slug in order to do lookups on translations. It also lets us
     * build alternate URLs so that the site switches actually works
     * and can show the alternate version or redirect to the front page
     * if it doesn't exist
     */
    fetchLinks ({ commit }) {
      return this.$storyapi.get('cdn/links')
        .then((links) => {
          commit('links', links.data.links)
          return links.data.links
        })
    },
  },
  getters: {
    /**
     * Locale should be implicitly added from the magento state by a mixin
     * method so a language is transparent to the component.
     *
     * 1. Return translated value if it exists
     * 2. Return default if it exists
     * 3. Return `name' if nothing exists
     */
    getElementTranslation: (state, getters, rootState, rootGetters) => (name) => {
      const currentLanguageCode = rootGetters['frontend/currentLanguageCode']
      const translations = state.elementTranslations[currentLanguageCode] || []

      const entry = translations.find(x => x.name === name)
      if (entry) {
        return entry.dimension_value ? entry.dimension_value : entry.value
      }
      return name
    },

    getStoryById: state => (id) => {
      if (id && typeof id === 'object') {
        // An SB link object, this should not be in nuxt
        id = id.id
      }
      const found = state.stories.find(x => parseInt(x.id) === parseInt(id))
      if (found) {
        return found.data
      }
      return null // So we can v-if
    },
    getStoryBySlug: (state, getters) => slug => getters.getStoryById(getters.getLinkForSlug(slug)),
    settings: (state, getters) => {
      return getters.getStoryById(getters.getLinkForSlug(settingsSlug))?.content
    },
    getCollectionById: state => collection => state.stories.find(it => it.id === getCollectionId(collection))?.data,
    getMenuById: (state, getters) => menuId => getters.getStoryBySlug(getMenuId(menuId)),

    getLinkForSlug: state => slug => state.links.find((it) => {
      return it.slug === slug
    }),
    getLinkForUuid: state => uuid => state.links.find(it => it.uuid === uuid),

    getMarketSettings: state => (languageCode, marketId) => state.stories.find(it => it.id === getMarketSettingsId(languageCode, marketId)) || { id: null, data: [] },
    marketSettings: (state, getters, rootState, rootGetters) => getters.getMarketSettings(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentMarketId']) || { id: null, data: [] },
    trustLogos: (state, getters, rootState, rootGetters) => state.stories.find(it => it.id === getTrustLogosId(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentMarketId'])) || { id: null, data: [] },
    getGwpMessage: (state, getters, rootState, rootGetters) => state.stories.find(it => it.id === getGwpId(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentMarketId'])) || { id: null, data: [] },

    getAccountSettings: state => (languageCode, countryCode) => state.stories.find(it => it.id === getAccountSettingsId(languageCode, countryCode)) || { id: null, data: [] },
    accountSettings: (state, getters, rootState, rootGetters) => getters.getAccountSettings(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentCountryCode']) || { id: null, data: [] },

    getGlobalToasts: state => (languageCode, countryCode) => state.stories.find(it => it.id === getGlobalToastsId(languageCode, countryCode)) || { id: null, data: [] },
    globalToasts: (state, getters, rootState, rootGetters) => getters.getGlobalToasts(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentCountryCode']) || { id: null, data: [] },
    getPromoBlock: (state, getters, rootState, rootGetters) => (promoBlockId) => {
      const promoBlock = getters.settings.promoBlocks?.find(it => it.promoBlockId === promoBlockId) || null
      const countryCode = rootGetters['frontend/currentCountryCode']
      const fallback = '_all_countries'
      if (promoBlock) {
        // Only display the promoBlock if the current country or 'All Countries' is selected
        if (promoBlock.countries?.includes(countryCode) || promoBlock.countries?.includes(fallback)) {
          return promoBlock
        }
      }
      return null
    },
  }
}
