<template>
  <div class="accordion-row">
    <div
      :class="{open: open === id}"
      class="expandable"
    >
      <div
        :title="open === id ? $t('Global_Close_Contents') : $t('Global_Open_Contents')"
        class="toggler"
        @click="toggle"
      >
        <span class="title">
          {{ item.label }}
        </span>
        <div class="icon">
          <span class="open-svg">
            <OpenIcon />
          </span>
          <span class="close-svg hide">
            <CloseIcon />
          </span>
        </div>
      </div>
      <div
        ref="expand"
        :style="getHeight()"
        class="expand"
      >
        <div class="content">
          <span
            v-for="(link, index) in item.menuItems"
            :key="index"
            class="link"
          >
            <mp-link
              :to="$u(link.link)"
              :target="link.targetBlank ? '_blank' : '_self'"
              :rel="link.targetBlank ? 'noreferrer nofollow' : false"
            >
              {{ link.label }}
            </mp-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpenIcon from '~/assets/icons/plus.svg'
import CloseIcon from '~/assets/icons/minus.svg'
export default {
  name: 'FooterMenuRow',
  components: {
    OpenIcon,
    CloseIcon
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: undefined
    },
    id: {
      type: Number,
      required: true,
      default: undefined
    },
    open: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      initialized: false
    }
  },
  mounted () {
    this.setHeight()
  },
  methods: {
    setHeight () {
      /**
       * Before hiding each section we need to get the initial height and set it
       * as a data attribute to be used later. We do this so that we can animate
       * to a fixed height.
       */
      const element = this.$refs.expand
      if (!element.hasAttribute('data-height')) {
        element.style.display = 'block'
        element.dataset.height = element.offsetHeight + 'px'
        element.style.height = 0
        this.initialized = true
      }
    },
    getHeight () {
      /**
       * When the section is opened, we use the value from the data-height attribute
       * to set the height of the section.
       */
      if (this.initialized) {
        if (this.open === this.id) {
          return { height: this.$refs.expand.dataset.height }
        } else {
          return { height: '0px' }
        }
      } else {
        return false
      }
    },
    toggle (event) {
      /**
       * Instead of simply toggling this component, the value is emitted to the parent
       * and then passed back down. By doing this all siblings will be closed when this
       * accordion is opened.
       */
      if (this.open === this.id) {
        this.$emit('clickToggle', '')
      } else {
        this.$emit('clickToggle', this.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-row {
  .expandable {
    .toggler {
      position: relative;
      padding: .9rem 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      width: 100%;
      .title {
        @include h--mini;
        pointer-events: none;
        margin: 0;
      }
      .icon {
        width: 1.2rem;
        height: 1.2rem;
        position: relative;
        pointer-events: none;
        .close-svg,
        .open-svg {
          background: transparent;
          width: 100%;
          height: 100%;
          position: relative;
          display: none;
          svg {
            width: 100%;
            height: 100%;
            display: block;
            fill: white;
          }
        }
        .open-svg {
          display: block;
        }
      }
    }
    .expand {
      overflow: hidden;
      display: none;
      transition: all 0.6s cubic-bezier(0.5, 0, 0, 1);
      opacity: 0;
      .content {
        padding: 0 1.6rem 1.6rem 1.6rem;
        display: flex;
        flex-direction: column;
        .link {
          @include p--small;
          a {
            display: block;
            padding: .3rem 0;
          }
        }

      }
    }
    &.open {
      .icon {
        .open-svg {
          display: none;
        }
        .close-svg {
          display: block;
        }
      }
      .expand {
        opacity: 1;
      }
    }
  }
}
</style>
